import { AxiosResponse } from "axios";
import { Repository } from "@react3l/react3l/core";
import { kebabCase, url } from "@react3l/react3l/helpers";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import nameof from "ts-nameof.macro";

import { API_END_USER_PREFIX } from "config/api-consts";
import { EndUser, EndUserFilter, Sex, SexFilter } from "models/EndUser";
import { Status, StatusFilter } from "models/Status";
import { Activity } from "models/Activity";
import { DateFilter } from "@react3l/advanced-filters";
import { NotificationFormModel } from "models/NotificationForm";

export class EndUserRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = url(BASE_API_URL, API_END_USER_PREFIX);
  }

  public count = (endUserFilter?: EndUserFilter): Observable<number> => {
    return this.httpObservable
      .post<number>(kebabCase(nameof(this.count)), endUserFilter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public list = (endUserFilter?: EndUserFilter): Observable<EndUser[]> => {
    return this.httpObservable
      .post<EndUser[]>(kebabCase(nameof(this.list)), endUserFilter)
      .pipe(map((response: AxiosResponse<EndUser[]>) => response.data));
  };

  public get = (id: number | string): Observable<EndUser> => {
    return this.httpObservable
      .post<EndUser>(kebabCase(nameof(this.get)), { id })
      .pipe(map((response: AxiosResponse<EndUser>) => response.data));
  };

  public adminChangePassword = (
    id: number,
    newPassword: string
  ): Observable<EndUser> => {
    return this.httpObservable
      .post<EndUser>(kebabCase(nameof(this.adminChangePassword)), {
        id,
        newPassword,
      })
      .pipe(map((response: AxiosResponse<EndUser>) => response.data));
  };

  public getActivityLog = (
    endUserId: number | string,
    createdAt?: DateFilter
  ): Observable<Activity[]> => {
    return this.httpObservable
      .post<Activity[]>(kebabCase(nameof(this.getActivityLog)), {
        endUserId,
        createdAt,
      })
      .pipe(map((response: AxiosResponse<Activity[]>) => response.data));
  };

  public create = (EndUser: EndUser): Observable<EndUser> => {
    return this.httpObservable
      .post<EndUser>(kebabCase(nameof(this.create)), EndUser)
      .pipe(map((response: AxiosResponse<EndUser>) => response.data));
  };

  public update = (EndUser: EndUser): Observable<EndUser> => {
    return this.httpObservable
      .post<EndUser>(kebabCase(nameof(this.update)), EndUser)
      .pipe(map((response: AxiosResponse<EndUser>) => response.data));
  };

  public delete = (EndUser: EndUser): Observable<EndUser> => {
    return this.httpObservable
      .post<EndUser>(kebabCase(nameof(this.delete)), EndUser)
      .pipe(map((response: AxiosResponse<EndUser>) => response.data));
  };

  public bulkDelete = (idList: number[] | string[]): Observable<void> => {
    return this.httpObservable
      .post(kebabCase(nameof(this.bulkDelete)), idList)
      .pipe(map((response: AxiosResponse<void>) => response.data));
  };

  public save = (EndUser: EndUser): Observable<EndUser> => {
    return EndUser.id ? this.update(EndUser) : this.create(EndUser);
  };

  public import = (
    file: File,
    name: string = nameof(file)
  ): Observable<void> => {
    const formData: FormData = new FormData();
    formData.append(name, file as Blob);
    return this.httpObservable
      .post<void>(kebabCase(nameof(this.import)), formData)
      .pipe(map((response: AxiosResponse<void>) => response.data));
  };

  public export = (filter: any): Observable<AxiosResponse<any>> => {
    return this.httpObservable.post("export", filter, {
      responseType: "arraybuffer",
    });
  };

  public exportTemplate = (): Observable<AxiosResponse<any>> => {
    return this.httpObservable.post(
      "export-template",
      {},
      {
        responseType: "arraybuffer",
      }
    );
  };

  public lock = (idList: any[]): Observable<EndUser[]> => {
    return this.httpObservable
      .post(kebabCase(nameof(this.lock)), idList)
      .pipe(map((response: AxiosResponse<EndUser[]>) => response.data));
  };

  public unlock = (idList: any[]): Observable<EndUser[]> => {
    return this.httpObservable
      .post(kebabCase(nameof(this.unlock)), idList)
      .pipe(map((response: AxiosResponse<EndUser[]>) => response.data));
  };

  public filterListSex = (): Observable<Sex[]> => {
    return this.httpObservable
      .post<Sex[]>(kebabCase(nameof(this.filterListSex)), new SexFilter())
      .pipe(map((response: AxiosResponse<Sex[]>) => response.data));
  };

  public singleListSex = (): Observable<Sex[]> => {
    return this.httpObservable
      .post<Sex[]>(kebabCase(nameof(this.singleListSex)), new SexFilter())
      .pipe(map((response: AxiosResponse<Sex[]>) => response.data));
  };

  public filterListStatus = (): Observable<Status[]> => {
    return this.httpObservable
      .post<Status[]>(
        kebabCase(nameof(this.filterListStatus)),
        new StatusFilter()
      )
      .pipe(map((response: AxiosResponse<Status[]>) => response.data));
  };
  public sendNotification = (notification: NotificationFormModel): Observable<boolean> => {
    return this.httpObservable
      .post<boolean>(kebabCase(nameof(this.sendNotification)), notification)
      .pipe(map((response: AxiosResponse<boolean>) => response.data));
  }
  public EndUserSatistic = (): Observable<[]> => {
    return this.httpObservable
      .get(kebabCase(nameof(this.EndUserSatistic)))
      .pipe(map((response: AxiosResponse<[]>) => response.data));
  };
}

export const endUserRepository = new EndUserRepository();
