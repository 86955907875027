import { AxiosResponse } from "axios";
import { Repository } from "@react3l/react3l/core";
import { kebabCase, url } from "@react3l/react3l/helpers";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import nameof from "ts-nameof.macro";

import { API_CERTIFICATION_PREFIX } from "config/api-consts";
import { Certification, CertificationFilter } from "../models/Certification";

export class CertificationRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = url(BASE_API_URL, API_CERTIFICATION_PREFIX);
  }

  public count = (filter?: CertificationFilter): Observable<number> => {
    return this.httpObservable
      .post<number>(kebabCase(nameof(this.count)), filter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public list = (
    filter?: CertificationFilter
  ): Observable<Certification[]> => {
    return this.httpObservable
      .post<Certification[]>(kebabCase(nameof(this.list)), filter)
      .pipe(map((response: AxiosResponse<Certification[]>) => response.data));
  };

  public get = (id: number | string): Observable<Certification> => {
    return this.httpObservable
      .post<Certification>(kebabCase(nameof(this.get)), { id })
      .pipe(map((response: AxiosResponse<Certification>) => response.data));
  };

  public create = (item: Certification): Observable<Certification> => {
    return this.httpObservable
      .post<Certification>(kebabCase(nameof(this.create)), item)
      .pipe(map((response: AxiosResponse<Certification>) => response.data));
  };

  public update = (item: Certification): Observable<Certification> => {
    return this.httpObservable
      .post<Certification>(kebabCase(nameof(this.update)), item)
      .pipe(map((response: AxiosResponse<Certification>) => response.data));
  };

  public delete = (item: Certification): Observable<Certification> => {
    return this.httpObservable
      .post<Certification>(kebabCase(nameof(this.delete)), item)
      .pipe(map((response: AxiosResponse<Certification>) => response.data));
  };

  public save = (
    item: Certification,
    isDetail: boolean
  ): Observable<Certification> => {
    return isDetail ? this.update(item) : this.create(item);
  };

  public bulkDelete = (idList: number[] | string[]): Observable<void> => {
    return this.httpObservable
      .post(kebabCase(nameof(this.bulkDelete)), idList)
      .pipe(map((response: AxiosResponse<void>) => response.data));
  };

  public import = (
    file: File,
    name: string = nameof(file)
  ): Observable<void> => {
    const formData: FormData = new FormData();
    formData.append(name, file as Blob);
    return this.httpObservable
      .post<void>(kebabCase(nameof(this.import)), formData)
      .pipe(map((response: AxiosResponse<void>) => response.data));
  };

  public export = (filter: any): Observable<AxiosResponse<any>> => {
    return this.httpObservable.post("export", filter, {
      responseType: "arraybuffer",
    });
  };

  public exportTemplate = (): Observable<AxiosResponse<any>> => {
    return this.httpObservable.post(
      "export-template",
      {},
      {
        responseType: "arraybuffer",
      }
    );
  };
  public upload = (file: File): Observable<any> => {
    const formData: FormData = new FormData();
    formData.append("file", file as Blob);
    return this.httpObservable
      .post<any>(kebabCase(nameof(this.upload)), formData)
      .pipe(map((response: AxiosResponse<any>) => response.data));
  };
}

export const certificationRepository = new CertificationRepository();

