import { Model } from "@react3l/react3l/core";
import { EndUser } from "models/EndUser";
import { Duration } from "models/Duration";

export class Supporter extends Model {
  public endUserId?: number;
  public endUser?: EndUser;
  public endUserRoleId?: number;
  public dormitoryId?: number;
  public durationId?: number;
  public duration?: Duration;
}
