/* begin general import */
import { StringFilter } from "@react3l/advanced-filters";
import { Col, Row, Card, Tabs, Menu, Dropdown } from "antd";
import { ColumnProps } from "antd/lib/table";
import { TicketMasterTable } from "./TicketMasterTable";
/* end general import */
/* begin filter import */
import AdvanceStringFilter from "components/Utility/AdvanceFilter/AdvanceStringFilter/AdvanceStringFilter";
import { Ticket, TicketFilter } from "models/Ticket";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
/* end filter import */
/* begin individual import */
import { ticketRepository } from "repositories/ticket-repository";
import { getAntOrderType } from "services/table-service";
import nameof from "ts-nameof.macro";
import InputSearch from "components/Utility/InputSearch/InputSearch";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import Pagination from "components/Utility/Pagination/Pagination";
import {
  useTicketMaster,
  TicketMasterModal,
  useTicketMasterModal,
} from "./TicketMasterHook";
import { TICKET_ROUTE } from "config/route-consts";
import { TicketStatus } from "models/TicketStatus";
import { EndUser } from "models/EndUser";
import "./TicketMaster.scss";
import authenticationService from "services/authentication-service";
import { API_TICKET_PREFIX } from "config/api-consts";
/* end individual import */

const { TabPane } = Tabs;

function TicketMaster() {
  const [translate] = useTranslation();
  const { validAction } = authenticationService.useAction(
    "ticket",
    API_TICKET_PREFIX
  );

  const master = useTicketMaster(
    TICKET_ROUTE,
    ticketRepository.list,
    ticketRepository.count,
    ticketRepository.delete
  );

  const {
    newTicketModel,
    handleChangeSimpleField,
    handleChangeObjectField,
    isOpenModal,
    handleCloseModal,
    handleOpenModal,
    handleSaveModel,
  } = useTicketMasterModal(ticketRepository.create, master.handleSearch);

  const _handleChangeFilter = React.useCallback(
    (value) => {
      const newFilter = { ...master.filter };
      newFilter["search"] = value;
      master.handleUpdateNewFilter(newFilter);
    },
    [master]
  );

  const tabExtra = React.useMemo(() => {
    return (
      <Pagination
        skip={master.filter.skip}
        take={master.filter.take}
        total={master.total}
        onChange={master.handlePagination}
        style={{ margin: "10px" }}
      />
    );
  }, [master]);

  const handleTabChange = React.useCallback(
    (activeKey: string) => {
      const filter = { ...master.filter };
      if (activeKey === "0") delete filter.ticketStatusId;
      else filter["ticketStatusId"] = { equal: parseInt(activeKey) };
      master.handleUpdateNewFilter(filter);
    },
    [master]
  );

  const menuAction = React.useCallback(
    (id: number, ticket: Ticket) => (
      <Menu>
        {validAction("update") && ticket.ticketStatus.id !== 3 ? (
          <Menu.Item key="2">
            <div
              className="ant-action-menu"
              onClick={master.handleGoDetail(id)}
            >
              {translate("general.actions.edit")}
            </div>
          </Menu.Item>
        ) : (
            <Menu.Item key="2">
              <div
                className="ant-action-menu"
                onClick={master.handleGoDetail(id)}
              >
                {translate("general.actions.view")}
              </div>
            </Menu.Item>
          )}
      </Menu>
    ),
    [master, translate, validAction]
  );

  const columns: ColumnProps<Ticket>[] = useMemo(
    () => [
      {
        title: (
          <div className="text-center gradient-text">
            {translate("tickets.code")}
          </div>
        ),
        key: nameof(master.list[0].code),
        dataIndex: nameof(master.list[0].code),
        sorter: true,
        sortOrder: getAntOrderType<Ticket, TicketFilter>(
          master.filter,
          nameof(master.list[0].code)
        ),
        width: 250,
        render(code: string) {
          return (
            <div className="ant-cell-master__container">
              <div>{code}</div>
            </div>
          );
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("tickets.endUser")}
          </div>
        ),
        key: nameof(master.list[0].endUser),
        dataIndex: nameof(master.list[0].endUser),
        sorter: true,
        sortOrder: getAntOrderType<Ticket, TicketFilter>(
          master.filter,
          nameof(master.list[0].endUser)
        ),
        width: 300,
        render(endUser: EndUser) {
          return <div>{endUser.displayName}</div>;
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("tickets.title")}
          </div>
        ),
        key: nameof(master.list[0].title),
        dataIndex: nameof(master.list[0].title),
        sorter: true,
        sortOrder: getAntOrderType<Ticket, TicketFilter>(
          master.filter,
          nameof(master.list[0].title)
        ),
        render(title: String) {
          return <div>{title}</div>;
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("tickets.ticketStatus")}
          </div>
        ),
        key: nameof(master.list[0].ticketStatus),
        dataIndex: nameof(master.list[0].ticketStatus),
        sorter: true,
        sortOrder: getAntOrderType<Ticket, TicketFilter>(
          master.filter,
          nameof(master.list[0].ticketStatus)
        ),
        width: 200,
        render(ticketStatus: TicketStatus) {
          return (
            <div
              className={`ticket__status ticket__status--${ticketStatus.code.toLowerCase()}`}
            >
              {ticketStatus?.name}
            </div>
          );
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("general.actions.action")}
          </div>
        ),
        key: "action",
        dataIndex: nameof(master.list[0].id),
        fixed: "right",
        width: 100,
        align: "center",
        render(id: number, ticket: Ticket) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, ticket)}
                trigger={["click"]}
                placement="bottomCenter"
                arrow
              >
                <span className="action__dots">...</span>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [translate, master, menuAction]
  );

  const filterChildren = React.useMemo(
    () => (
      <div className="search__container mt-4">
        <Row>
          <Col lg={4} className="pr-4">
            <label className="label">{translate("tickets.filter.code")}</label>
            <AdvanceStringFilter
              isMaterial={true}
              value={master.filter[nameof(master.list[0].code)]["contain"]}
              onEnter={master.handleChangeFilter(
                nameof(master.list[0].code),
                "contain" as any,
                StringFilter
              )}
              placeHolder={translate("tickets.placeholder.code")}
            />
          </Col>

          <Col lg={4} className="pr-4">
            <label className="label">{translate("tickets.filter.title")}</label>
            <AdvanceStringFilter
              isMaterial={true}
              value={master.filter[nameof(master.list[0].title)]["contain"]}
              onChange={master.handleChangeFilter(
                nameof(master.list[0].title),
                "contain" as any,
                StringFilter
              )}
              placeHolder={translate("tickets.placeholder.title")}
            />
          </Col>
        </Row>
      </div>
    ),
    [master, translate]
  );

  return (
    <>
      <div className="page page__master ticket__container">
        <div className="page__header d-flex align-items-center justify-content-between">
          <div className="page__title">{translate("tickets.master.title")}</div>
        </div>
        <div className="page__search">
          <Card bordered={false}>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-grow-1">
                <div className="pr-4 w70">
                  <InputSearch
                    value={master.filter["search"]}
                    onChange={_handleChangeFilter}
                    placeHolder="Tìm kiếm"
                  />
                </div>

                <button
                  className={classNames(
                    "btn component__btn-toggle mr-3 grow-animate-1 btn-customize",
                    master.toggle === true ? "component__btn-filter-active" : ""
                  )}
                  onClick={master.handleToggleSearch}
                >
                  <i className="tio-tune_horizontal "></i>
                  <span className="component_btn-text">
                    {translate("general.button.advance")}
                  </span>
                </button>
              </div>
              {validAction("create") && (
                <div className="d-flex justify-content-around ml-4 ">
                  <button
                    className="btn component__btn-toggle grow-animate-1 btn-customize"
                    onClick={handleOpenModal}
                  >
                    <i className="tio-add"></i>
                    <span className="component_btn-text">
                      {translate("general.actions.create")}
                    </span>
                  </button>
                </div>
              )}
            </div>

            <CSSTransition
              in={master.toggle}
              timeout={100}
              classNames={"show"}
              unmountOnExit
            >
              {filterChildren}
            </CSSTransition>
          </Card>
        </div>
        <div className="tab-container">
          <Tabs
            activeKey={
              master.filter["ticketStatusId"]?.equal
                ? master.filter["ticketStatusId"].equal.toString()
                : "0"
            }
            tabBarExtraContent={tabExtra}
            onTabClick={handleTabChange}
          >
            <TabPane tab={"Tất cả"} key={"0"}>
              <TicketMasterTable
                {...master}
                isShowTitle={false}
                translate={translate}
                columns={columns}
              >
                {translate("purchaseRequests.table.title")}
              </TicketMasterTable>
            </TabPane>
            <TabPane tab={"Chờ xử lý"} key={"1"}>
              <TicketMasterTable
                {...master}
                isShowTitle={false}
                translate={translate}
                columns={columns}
              >
                {translate("purchaseRequests.table.title")}
              </TicketMasterTable>
            </TabPane>
            <TabPane tab={"Đang xử lý"} key={"2"}>
              <TicketMasterTable
                {...master}
                isShowTitle={false}
                translate={translate}
                columns={columns}
              >
                {translate("purchaseRequests.table.title")}
              </TicketMasterTable>
            </TabPane>
            <TabPane tab={"Hoàn thành"} key={"3"}>
              <TicketMasterTable
                {...master}
                isShowTitle={false}
                translate={translate}
                columns={columns}
              >
                {translate("purchaseRequests.table.title")}
              </TicketMasterTable>
            </TabPane>
          </Tabs>
        </div>
      </div>
      <TicketMasterModal
        visible={isOpenModal}
        model={newTicketModel}
        handleChangeObjectField={handleChangeObjectField}
        handleChangeSimpleField={handleChangeSimpleField}
        handleSave={handleSaveModel}
        handleCancel={handleCloseModal}
        visibleFooter={false}
      />
    </>
  );
}

export default TicketMaster;
