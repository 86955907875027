import React from "react";
import { Switch } from "react-router-dom";
import { DORMITORY_MASTER_ROUTE, DORMITORY_DETAIL_ROUTE } from "config/route-consts";
import { ProtectedRoute, useCheckAuthorized } from "config/protected-route";

import "./DormitoryView.scss";
import DormitoryMaster from "./DormitoryMaster/DormitoryMaster";
import DormitoryDetail from "./DormitoryDetail/DormitoryDetail";

function DormitoryView() {
  const { auth } = useCheckAuthorized();
  return (
    <Switch>
      <ProtectedRoute
        path={DORMITORY_MASTER_ROUTE}
        key={DORMITORY_MASTER_ROUTE}
        component={DormitoryMaster}
        auth={auth(DORMITORY_MASTER_ROUTE)}
      />
      <ProtectedRoute
        path={DORMITORY_DETAIL_ROUTE}
        key={DORMITORY_DETAIL_ROUTE}
        component={DormitoryDetail}
        auth={auth(DORMITORY_DETAIL_ROUTE)}
      />
    </Switch>
  );
}

export { DormitoryDetail };
export default DormitoryView;
