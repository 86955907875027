import { ModalProps } from "antd";
import FormItem, { ValidateStatus } from "components/Utility/FormItem/FormItem";
import InputText from "components/Utility/Input/InputText/InputText";
import Modal from "components/Utility/Modal/Modal";
import React from "react";
import { formService } from "services/form-service";
import nameof from "ts-nameof.macro";
import { EndUser } from "models/EndUser";
import { Subscription } from "rxjs";
import { queryStringService } from "services/query-string-service";
import { endUserRepository } from "repositories/end-user-repository";
import appMessageService from "services/app-message-service";

export function useForgotPasswordModal() {
  const [visibleForgotPasswordModal, setVisibleForgotPasswordModal] =
    React.useState(false);

  const openForgotPasswordModal = React.useCallback(() => {
    setVisibleForgotPasswordModal(true);
  }, []);

  const cancelForgotPasswordModal = React.useCallback(() => {
    setVisibleForgotPasswordModal(false);
  }, []);

  const saveForgotPasswordModal = React.useCallback(() => {
    setVisibleForgotPasswordModal(false);
  }, []);

  return {
    visibleForgotPasswordModal,
    openForgotPasswordModal,
    cancelForgotPasswordModal,
    saveForgotPasswordModal,
  };
}

interface ForgotPasswordModalProps extends ModalProps {
  model?: EndUser;
  visibleForgotPasswordModal?: boolean;
  cancelForgotPasswordModal?: (e: any) => void;
  saveForgotPasswordModal?: () => void;
}

export function ForgotPasswordModal(props: ForgotPasswordModalProps) {
  const {
    model,
    visibleForgotPasswordModal,
    cancelForgotPasswordModal,
    saveForgotPasswordModal,
  } = props;

  const { notifyUpdateItemSuccess, notifyUpdateItemError } =
    appMessageService.useCRUDMessage();

  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [newPasswordError, setNewPasswordError] = React.useState("");

  const { id } = queryStringService.useGetQueryString();

  const handleSavePassword = React.useCallback(() => {
    const subscription: Subscription = new Subscription();
    if (newPassword !== confirmPassword) {
      setNewPasswordError("Mật khẩu không trùng khớp");
    } else {
      subscription.add(
        endUserRepository.adminChangePassword(id, newPassword).subscribe(
          (_res: any) => {
            notifyUpdateItemSuccess();
            setNewPassword("");
            setConfirmPassword("");
            setNewPasswordError("");
            saveForgotPasswordModal();
          },
          (_err: any) => {
            notifyUpdateItemError();
          }
        )
      );
    }
  }, [
    id,
    newPassword,
    confirmPassword,
    setNewPasswordError,
    saveForgotPasswordModal,
    notifyUpdateItemSuccess,
    notifyUpdateItemError,
  ]);

  return (
    <>
      <Modal
        title={null}
        onCancel={cancelForgotPasswordModal}
        visible={visibleForgotPasswordModal}
        width={585}
        visibleFooter={false}
        closable={false}
      >
        <div className="forgot-password-modal__contaier d-flex flex-column">
          <div className="forgot-password-modal__header">Đổi mật khẩu</div>
          <div className="forgot-password-content w-100 mt-4">
            <FormItem
              label={"Nhập mật khẩu mới"}
              validateStatus={formService.getValidationStatus<EndUser>(
                model.errors,
                nameof(model.password)
              )}
              message={newPasswordError}
            >
              <InputText
                isMaterial={true}
                type="password"
                value={newPassword}
                placeHolder={"Nhập mật khẩu mới"}
                className={"tio-lock_outlined"}
                onChange={setNewPassword}
              />
            </FormItem>
          </div>
          <div className="forgot-password-content w-100 mt-2">
            <FormItem
              label={"Nhập lại mật khẩu"}
              validateStatus={
                confirmPassword === "" || newPassword === confirmPassword
                  ? null
                  : ValidateStatus.error
              }
              message={newPasswordError}
            >
              <InputText
                isMaterial={true}
                type="password"
                value={confirmPassword}
                placeHolder={"Nhập lại mật khẩu mới"}
                className={"tio-lock_outlined"}
                onChange={setConfirmPassword}
              />
            </FormItem>
          </div>
          <div className="forgot-password-footer d-flex mt-3">
            <button className="btn-save mr-2" onClick={handleSavePassword}>
              <span>
                <i className="tio-checkmark_circle_outlined"></i> Lưu
              </span>
            </button>
            <button className="btn-cancel" onClick={cancelForgotPasswordModal}>
              <span>
                <i className="tio-clear"></i> Đóng
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
