import { AxiosResponse } from 'axios';
import { Repository } from "@react3l/react3l/core";
import { kebabCase, url } from "@react3l/react3l/helpers";
import { httpConfig } from 'config/http';
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import nameof from "ts-nameof.macro";

import { API_ACTIVITY_LOG_PREFIX } from "config/api-consts";
import { DeviceActivityLog, DeviceActivityLogFilter } from "models/DeviceActivityLog";
import { GroupActivityLog, GroupActivityLogFilter } from 'models/GroupActivityLog';
import { SceneActivityLog, SceneActivityLogFilter } from 'models/SceneActivityLog';
import { HclEventLog, HclEventLogFilter } from 'models/HclEventLog';
import { RuleActivationEventLog, RuleActivationEventLogFilter } from 'models/RuleActivationEventLog';

export class ActivityLogRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = url(BASE_API_URL, API_ACTIVITY_LOG_PREFIX);
  }

  public countDeviceLog = (deviceActivityLogFilter?: DeviceActivityLogFilter): Observable<number> => {
    return this.httpObservable
      .post<number>(kebabCase(nameof(this.countDeviceLog)), deviceActivityLogFilter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public listDeviceLog = (deviceActivityLogFilter?: DeviceActivityLogFilter): Observable<DeviceActivityLog[]> => {
    return this.httpObservable
      .post<DeviceActivityLog[]>(kebabCase(nameof(this.listDeviceLog)), deviceActivityLogFilter)
      .pipe(map((response: AxiosResponse<DeviceActivityLog[]>) => response.data));
  };

  public countGroupLog = (groupActivityLogFilter?: GroupActivityLogFilter): Observable<number> => {
    return this.httpObservable.post<number>(kebabCase(nameof(this.countGroupLog)), groupActivityLogFilter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public listGroupLog = (groupActivityLogFilter?: GroupActivityLogFilter): Observable<GroupActivityLog[]> => {
    return this.httpObservable.post<GroupActivityLog[]>(kebabCase(nameof(this.listGroupLog)), groupActivityLogFilter)
      .pipe(map((response: AxiosResponse<GroupActivityLog[]>) => response.data));
  };

  public countSceneLog = (sceneActivityLogFilter?: SceneActivityLogFilter): Observable<number> => {
    return this.httpObservable.post<number>(kebabCase(nameof(this.countSceneLog)), sceneActivityLogFilter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public listSceneLog = (sceneActivityLogFilter?: SceneActivityLogFilter): Observable<SceneActivityLog[]> => {
    return this.httpObservable.post<SceneActivityLog[]>(kebabCase(nameof(this.listSceneLog)), sceneActivityLogFilter)
      .pipe(map((response: AxiosResponse<SceneActivityLog[]>) => response.data));
  };

  public countHclLog = (hclEventLogFilter?: HclEventLogFilter): Observable<number> => {
    return this.httpObservable.post<number>(kebabCase(nameof(this.countHclLog)), hclEventLogFilter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public listHclLog = (hclEventLogFilter?: HclEventLogFilter): Observable<HclEventLog[]> => {
    return this.httpObservable.post<HclEventLog[]>(kebabCase(nameof(this.listHclLog)), hclEventLogFilter)
      .pipe(map((response: AxiosResponse<HclEventLog[]>) => response.data));
  };

  public countRuleLog = (ruleActivationEventLogFilter?: RuleActivationEventLogFilter): Observable<number> => {
    return this.httpObservable.post<number>(kebabCase(nameof(this.countRuleLog)), ruleActivationEventLogFilter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public listRuleLog = (ruleActivationEventLogFilter?: RuleActivationEventLogFilter): Observable<RuleActivationEventLog[]> => {
    return this.httpObservable.post<RuleActivationEventLog[]>(kebabCase(nameof(this.listRuleLog)), ruleActivationEventLogFilter)
      .pipe(map((response: AxiosResponse<RuleActivationEventLog[]>) => response.data));
  };
}

export const activityLogRepository = new ActivityLogRepository();
