import { HomeController } from "models/HomeController";
import React, { useCallback, useState } from "react";
import { commonService } from "@react3l/react3l/services";
import { ModalProps, Button, Input, Typography, Tooltip } from "antd";
import Modal from "components/Utility/Modal/Modal";
import { homeControllerRepository } from "repositories/home-controller-repository";
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { HomeControllerCommand } from "models/HomeControllerCommand";
import moment from "moment";
import './HomeControllerCommandModal.scss';
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export function useHomeControllerCommandModal() {
  const [visibleHomeControllerCommandModal, setVisibleHomeControllerCommandModal] = React.useState(false);


  const openHomeControllerCommandModal = React.useCallback(() => {
    setVisibleHomeControllerCommandModal(true);
  }, []);

  const cancelHomeControllerCommandModal = React.useCallback(() => {
    setVisibleHomeControllerCommandModal(false);
  }, []);

  return {
    visibleHomeControllerCommandModal,
    openHomeControllerCommandModal,
    cancelHomeControllerCommandModal,
  };
}

interface HomeControllerCommandModalProps extends ModalProps {
  model?: HomeController,
  visibleHomeControllerCommandModal?: boolean;
  cancelHomeControllerCommandModal?: (e: any) => void;
}

export function HomeControllerCommandModal(props: HomeControllerCommandModalProps) {
  const { model, visibleHomeControllerCommandModal, cancelHomeControllerCommandModal } =
    props;

  const [subscription] = commonService.useSubscription();
  const [entity, setEntity] = useState<string>("");
  const [data, setData] = useState<string>("");

  const setLogTemplate = useCallback(() => {
    setEntity("Command");
    setData(`{
  "CMD": "UPLOAD_LOG",
  "DATA": {
    "TIME": ${moment().toISOString()}
  }
}`);
  }, []);

  const onChangeData = React.useCallback((value) => {
    setData(value);
  }, []);

  const [translate] = useTranslation();

  const handleSend = useCallback(() => {
    const hcCommand: HomeControllerCommand = {
      dormitoryId: model.dormitoryId,
      entity: entity,
      data: data,
    };
    subscription.add(
      homeControllerRepository.remoteCommand(hcCommand)
        .subscribe(() => {
        }));
  }, [data, entity, model.dormitoryId, subscription]);

  const handleClose = useCallback(() => {
    setEntity("");
    setData("");
    cancelHomeControllerCommandModal(null);
  }, [cancelHomeControllerCommandModal]);

  return (
    <>
      <Modal
        title={null}
        onCancel={cancelHomeControllerCommandModal}
        visible={visibleHomeControllerCommandModal}
        width={840}
        visibleFooter={false}
        closable={false}
      >
        <div className="home-controller-command-modal__container d-flex flex-column">
          <div className="home-controller-command-modal__header w-100 d-flex justify-content-between align-items-center">
            <span>{translate("homeController.command.title")}</span>
            <div
              className="d-flex justify-content-center align-items-center"
              onClick={handleClose}
            >
              <i className="tio-clear"></i>
            </div>
          </div>
          <div className="home-controller-command-modal__body w-100">
            <Text>{translate("homeController.command.builtin")}</Text>
            <div className="d-flex">
              <Tooltip placement="bottom" title={translate("homeController.command.uploadLog.tooltip")}>

                <Button type="primary" shape="round" onClick={() => setLogTemplate()}>{translate("homeController.command.uploadLog.name")}</Button>
              </Tooltip>
            </div>
            <Text>{translate("homeController.command.custom")}</Text>
            <Input
              type="text"
              placeholder="Entity"
              value={entity}
              onChange={e => setEntity(e.target.value)}
            />
            <CodeMirror
              value={data}
              height="300px"
              extensions={[json()]}
              onChange={onChangeData}
            />
            <div className="d-flex">
              <Button color="primary" className="mt-2" onClick={() => handleSend()}>Send</Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
