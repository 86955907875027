import React from "react";
import { ModelFilter } from "@react3l/react3l/core";
import { NotificationFormModel } from "models/NotificationForm";
import { homeControllerRepository } from "repositories/home-controller-repository";
import appMessageService, { messageType } from "./app-message-service";
import { SendNotificationType } from "config/consts";
import { endUserRepository } from "repositories/end-user-repository";
import { dormitoryRepository } from "repositories/dormitory-repository";

class SendNotificationServive{
    useNotificationForm<TFilter extends ModelFilter>(sendNotificationType: SendNotificationType){
        const [form, setForm] = React.useState<NotificationFormModel>(new NotificationFormModel());
        const setTitle = (value: string) => {
            setForm((pre: NotificationFormModel) =>({...pre, Title: value}));
        };
        const setContent = (value: string) => {
            setForm((pre: NotificationFormModel) => ({...pre, Content: value}));
        };
        const setIds = (value: KeyType[]) => {
            setForm((pre: NotificationFormModel) => ({...pre, Ids: value}));
        };
        const isEmpty = (form: NotificationFormModel): boolean => {
            if(!form || !form.Title || !form.Content || !form.Ids || form.Ids.length < 1)
            return true;
            return false;
        };
        const handleSend = React.useCallback(async () => {
            if(isEmpty(form)){
               appMessageService.messageFactory({
                type: messageType.WARNING,
                title: 'Nhập đủ thông tin!'
               });
               return; 
            }
            switch(sendNotificationType){
                case SendNotificationType.EndUser: 
                    await endUserRepository.sendNotification(form)
                    .subscribe(
                    (response: boolean) =>{
                        if (response){
                            appMessageService.messageFactory({
                                type: messageType.SUCCESS,
                                title: 'Gửi thông báo thành công'
                            });
                            setForm({...form, Title:'', Content:''});
                        }
                        
                    }
                    );
                break;
                case SendNotificationType.Dormitory: 
                    await dormitoryRepository.sendNotification(form)
                    .subscribe(
                        (response: boolean) =>{
                            if (response){
                                appMessageService.messageFactory({
                                    type: messageType.SUCCESS,
                                    title: 'Gửi thông báo thành công'
                                });
                                setForm({...form, Title:'', Content:''});
                            }
                            
                        });
                break;
                default: 
                    await homeControllerRepository.sendNotification(form)
                    .subscribe(
                    (response: boolean) =>{
                        if (response){
                            appMessageService.messageFactory({
                                type: messageType.SUCCESS,
                                title: 'Gửi thông báo thành công'
                            });
                            setForm({...form, Title:'', Content:''});
                        }
                        
                    }
                    );
                break;
            }
            }, [form, sendNotificationType]);
        React.useEffect(() => {}, []);
        return {
            form,
            setTitle,
            setContent,
            setIds,
            handleSend
        };
    }
}

export const sendNotificationServive: SendNotificationServive = new SendNotificationServive();
