/* begin general import */
import React from "react";
import nameof from "ts-nameof.macro";
import { Card, Col, Row } from "antd";
import FormItem from "components/Utility/FormItem/FormItem";
import { formService } from "services/form-service";
import AppFooter from "components/AppFooter/AppFooter";
import classNames from "classnames";
/* end general import */

/* begin individual import */
import logo from "./../../../assets/images/user-logo.png";
import icon from "./../../../assets/images/Oval.png";
import emptyRole from "./../../../assets/images/empty-role.png";
import InputText from "components/Utility/Input/InputText/InputText";
import { AppUser } from "models/AppUser";
import { appUserRepository } from "repositories/app-user-repository";
import { useAppUserDetailHook } from "views/AppUserView/AppUserDetail/AppUserDetailHook";
import {
  useLockAccountModal,
  LockAccountModal,
} from "views/AppUserView/AppUserDetail/LockAccountModal";
import {
  useForgotPasswordModal,
  ForgotPasswordModal,
} from "views/AppUserView/AppUserDetail/ForgotPasswordModal";
import {
  useActivityModal,
  ActivityModal,
  ActivityList,
  getActivitiesDict,
} from "views/AppUserView/AppUserDetail/ActivityModal";
import AppUserDetailRoleModal from "./AppUserDetailRoleModal/AppUserDetailRoleModal";
import { useAppUserDetailRoleModal } from "./AppUserDetailRoleModal/AppUserDetailRoleModalHook";
import "./AppUserDetail.scss";
import authenticationService from "services/authentication-service";
import { API_APP_USER_PREFIX } from "config/api-consts";
/* end individual import */

function AppUserDetail() {
  const { validAction } = authenticationService.useAction(
    "appUser",
    API_APP_USER_PREFIX
  );

  const {
    model,
    isOpenRoleModal,
    handleOpenRoleModal,
    handleCloseRoleModal,
    handleSaveModel,
    handleChangeSimpleField,
    handleUpdateNewModel,
    handleGoBase,
    loading,
  } = useAppUserDetailRoleModal(appUserRepository.get, appUserRepository.save);

  const {
    isEditable,
    handleEdit,
    handleFinish,
    handleActiveAccount,
    handleDeactiveAccount,
  } = useAppUserDetailHook(model, handleUpdateNewModel);

  const handleSaveDetail = React.useCallback(() => {
    handleFinish();
    handleSaveModel();
  }, [handleFinish, handleSaveModel]);

  const handleCancelDetail = React.useCallback(() => {
    handleFinish();
  }, [handleFinish]);

  const {
    visibleLockAccountModal,
    openLockAccountModal,
    cancelLockAccountModal,
    activeAccountModal,
    deactiveAccountmodal,
  } = useLockAccountModal(handleDeactiveAccount, handleActiveAccount);

  const {
    visibleForgotPasswordModal,
    openForgotPasswordModal,
    cancelForgotPasswordModal,
    saveForgotPasswordModal,
  } = useForgotPasswordModal();

  const {
    loading: activityLoading,
    list,
    visibleActivityModal,
    openActivityModal,
    cancelActivityModal,
    handleApplyFilter,
  } = useActivityModal(appUserRepository.getActivityLog);

  return (
    <>
      <div className="page page__detail w-100 app-user__container">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={6}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row user-info__wrapper" span={24}>
                <Card>
                  <div className="d-flex w-100 user-info__container flex-column">
                    <div className="user-info__header d-flex flex-column justify-content-center ">
                      <div className="user-info__image">
                        <img src={logo} alt={"IMG"} />
                        <div className="user-info__status">
                          <img src={icon} alt={"IMG"} />
                        </div>
                      </div>
                      <div className="d-flex flex-column mt-3 username-input">
                        {!isEditable ? (
                          <>
                            <div className="user-info__label-field user-info__label-field--big">
                              {model.username}
                            </div>
                          </>
                        ) : (
                            <>
                              <div className="w-100 mt-1">
                                <FormItem
                                  validateStatus={formService.getValidationStatus<AppUser>(
                                    model.errors,
                                    nameof(model.username)
                                  )}
                                  message={model.errors?.username}
                                >
                                  <InputText
                                    isMaterial={true}
                                    value={model.username}
                                    placeHolder={"Nhập tên"}
                                    onChange={handleChangeSimpleField(
                                      nameof(model.username)
                                    )}
                                  />
                                </FormItem>
                              </div>
                            </>
                          )}
                      </div>
                      {validAction("update") && (
                        <i
                          className="edit-icon tio-new_message"
                          onClick={handleEdit}
                        ></i>
                      )}
                    </div>
                    <div className="user-info__content d-flex flex-column flex-grow-1">
                      <div className="d-flex flex-column mt-1">
                        <div className="user-info__label-field user-info__label-field--light">
                          Họ và tên
                        </div>
                        {!isEditable ? (
                          <div className="user-info__label-content user-info__label-content--bold mt-1">
                            {model.displayName}
                          </div>
                        ) : (
                            <div className="w-100 mt-1">
                              <FormItem
                                validateStatus={formService.getValidationStatus<AppUser>(
                                  model.errors,
                                  nameof(model.displayName)
                                )}
                                message={model.errors?.displayName}
                              >
                                <InputText
                                  isMaterial={true}
                                  value={model.displayName}
                                  placeHolder={"Nhập họ và tên"}
                                  onChange={handleChangeSimpleField(
                                    nameof(model.displayName)
                                  )}
                                />
                              </FormItem>
                            </div>
                          )}
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <div className="user-info__label-field user-info__label-field--light">
                          Email
                        </div>
                        {!isEditable ? (
                          <div className="user-info__label-content user-info__label-content--bold mt-1">
                            {model.email}
                          </div>
                        ) : (
                            <div className="w-100 mt-1">
                              <FormItem
                                validateStatus={formService.getValidationStatus<AppUser>(
                                  model.errors,
                                  nameof(model.email)
                                )}
                                message={model.errors?.email}
                              >
                                <InputText
                                  isMaterial={true}
                                  value={model.email}
                                  placeHolder={"Nhập email"}
                                  onChange={handleChangeSimpleField(
                                    nameof(model.email)
                                  )}
                                />
                              </FormItem>
                            </div>
                          )}
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <div className="user-info__label-field user-info__label-field--light">
                          Vị trí
                        </div>
                        {!isEditable ? (
                          <div className="user-info__label-content user-info__label-content--bold mt-1">
                            {model.position}
                          </div>
                        ) : (
                            <div className="w-100 mt-1">
                              <FormItem
                                validateStatus={formService.getValidationStatus<AppUser>(
                                  model.errors,
                                  nameof(model.position)
                                )}
                                message={model.errors?.position}
                              >
                                <InputText
                                  isMaterial={true}
                                  value={model.position}
                                  placeHolder={"Nhập vị trí"}
                                  onChange={handleChangeSimpleField(
                                    nameof(model.position)
                                  )}
                                />
                              </FormItem>
                            </div>
                          )}
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <div className="user-info__label-field user-info__label-field--light">
                          Tình trạng
                        </div>
                        {model.status && (
                          <div
                            className={`app-user__status ${model.status.code.toLowerCase()} mt-1`}
                          >
                            {model.status.name}
                          </div>
                        )}
                      </div>
                    </div>
                    {!isEditable ? (
                      <div className="user-info__footer d-flex flex-column mt-3">
                        {validAction("update") && (
                          <div
                            className="user-info__label-content user-info__label-content--bold"
                            onClick={openForgotPasswordModal}
                          >
                            <span>
                              <i className="tio-password_open"></i> Đổi mật khẩu
                            </span>
                          </div>
                        )}
                        {validAction("lock") && (
                          <div
                            className="user-info__label-content user-info__label-content--bold mt-2"
                            onClick={openLockAccountModal}
                          >
                            <span>
                              <i className="tio-password_open"></i>{" "}
                              {model.statusId === 1
                                ? "Khóa tài khoản"
                                : "Mở tài khoản"}
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                        <div className="user-info__footer d-flex mt-3 justify-content-center align-items-center">
                          <button
                            className="btn-save mr-2"
                            onClick={handleSaveDetail}
                          >
                            Lưu thay đổi
                        </button>
                          <button
                            className="btn-cancel"
                            onClick={handleCancelDetail}
                          >
                            Hủy
                        </button>
                        </div>
                      )}
                  </div>
                </Card>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-4">
              <Col className="gutter-row" span={24}>
                <Card title={<span className="card-title">Vai trò</span>}>
                  <div className="d-flex ticket__container align-items-center">
                    {model.appUserRoleMappings &&
                      model.appUserRoleMappings.length > 0 ? (
                        <div className="app-user__role">
                          {model.appUserRoleMappings.map((role) => (
                            <span
                              key={role.role.id}
                              className={classNames(
                                `app-user--${role.role.code.toLowerCase()}`
                              )}
                            >
                              {role.role.name}
                            </span>
                          ))}
                          {validAction("update") && (
                            <span
                              key={0}
                              className="app-user--add"
                              onClick={() => handleOpenRoleModal()}
                            >
                              <i className="tio-add"></i>
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="empty-role">
                          <img
                            src={emptyRole}
                            alt="IMG"
                            onClick={() => {
                              if (validAction("update")) handleOpenRoleModal();
                            }}
                          />
                        </div>
                      )}
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={18}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={24}>
                <Card
                  title={<span className="card-title">Hành động gần đây</span>}
                >
                  <ActivityList
                    list={getActivitiesDict(model.appUserActivityLogs, 2, 3)}
                  />
                  <div className="activation__footer w-100 d-flex justify-content-center align-items-center">
                    <span onClick={openActivityModal}>
                      <i className="tio-history"></i> Xem toàn bộ
                    </span>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <LockAccountModal
        statusId={model.statusId}
        visibleLockAccountModal={visibleLockAccountModal}
        cancelLockAccountModal={cancelLockAccountModal}
        activeAccountModal={activeAccountModal}
        deactiveAccountmodal={deactiveAccountmodal}
      />
      <ForgotPasswordModal
        model={model}
        visibleForgotPasswordModal={visibleForgotPasswordModal}
        cancelForgotPasswordModal={cancelForgotPasswordModal}
        saveForgotPasswordModal={saveForgotPasswordModal}
      />
      <ActivityModal
        model={list}
        loading={activityLoading}
        visibleActivityModal={visibleActivityModal}
        cancelActivityModal={cancelActivityModal}
        onFilter={handleApplyFilter}
      />
      <AppUserDetailRoleModal
        model={model}
        visible={isOpenRoleModal}
        getRoles={appUserRepository.singleListRole}
        handleSave={handleSaveModel}
        handleCancel={handleCloseRoleModal}
        loading={loading}
        visibleFooter={false}
      />
      <AppFooter onCancel={handleGoBase}></AppFooter>
    </>
  );
}

export default AppUserDetail;
