import { ModalProps } from "antd";
import FormItem from "components/Utility/FormItem/FormItem";
import Modal from "components/Utility/Modal/Modal";
import { EndUserFilter } from "models/EndUser";
import React from "react";
import { formService } from "services/form-service";
import nameof from "ts-nameof.macro";
import { Dormitory } from "models/Dormitory";
import { dormitoryRepository } from "repositories/dormitory-repository";
import { Supporter } from "models/Supporter";
import Select from "components/Utility/Select/Select";
import { Subscription } from "rxjs";
import { DurationFilter } from "models/Duration";
import { queryStringService } from "services/query-string-service";

export function useSupporterModal() {
  const [visibleSupporterModal, setVisibleSupporterModal] =
    React.useState(false);

  const openSupporterModal = React.useCallback(() => {
    setVisibleSupporterModal(true);
  }, []);

  const cancelSupporterModal = React.useCallback(() => {
    setVisibleSupporterModal(false);
  }, []);

  const saveSupporterModal = React.useCallback(() => {
    setVisibleSupporterModal(false);
  }, []);

  return {
    visibleSupporterModal,
    openSupporterModal,
    cancelSupporterModal,
    saveSupporterModal,
  };
}

interface SupporterModalProps extends ModalProps {
  dormitory?: Dormitory;
  setDormitory?: React.Dispatch<React.SetStateAction<Dormitory>>;
  visibleSupporterModal?: boolean;
  cancelSupporterModal?: (e: any) => void;
  saveSupporterModal?: () => void;
}

export function SupporterModal(props: SupporterModalProps) {
  const {
    setDormitory,
    visibleSupporterModal,
    cancelSupporterModal,
    saveSupporterModal,
  } = props;

  const { id } = queryStringService.useGetQueryString();

  const [model, , handleChangeObjectField] =
    formService.useDetailForm<Supporter>(Supporter, undefined, undefined);

  const handleSave = React.useCallback(() => {
    const subscription: Subscription = new Subscription();
    const newSp: Supporter = {
      ...model,
      dormitoryId: id,
    };
    subscription.add(
      dormitoryRepository.addSupporter(newSp).subscribe((_res: any) => {
        setDormitory(dormitory => {
          const newDorm = { ...dormitory };
          newDorm.members.push(newSp);
          return newDorm;
        });
      })
    );
    saveSupporterModal();
  }, [id, setDormitory, model, saveSupporterModal]);

  return (
    <>
      <Modal
        title={null}
        onCancel={cancelSupporterModal}
        visible={visibleSupporterModal}
        width={360}
        visibleFooter={false}
        closable={false}
      >
        <div className="supporter-modal__container d-flex flex-column">
          <div className="supporter-modal__header">Thêm support vào nhà</div>
          <div className="supporter-content w-100 mt-4">
            <div className="time">
              <FormItem
                label={"Thời gian"}
                validateStatus={formService.getValidationStatus<Supporter>(
                  model.errors,
                  nameof(model.duration)
                )}
                message={model.errors?.duration}
              >
                <Select
                  isMaterial={true}
                  classFilter={DurationFilter}
                  placeHolder="Thời gian hỗ trợ"
                  getList={dormitoryRepository.singleListDuration}
                  searchProperty={nameof(model.duration.name)}
                  render={(model) => model?.name}
                  onChange={handleChangeObjectField(nameof(model.duration))}
                  model={model.duration}
                />
              </FormItem>
            </div>
            <div className="end-user">
              <FormItem
                label={"Người hỗ trợ"}
                validateStatus={formService.getValidationStatus<Supporter>(
                  model.errors,
                  nameof(model.endUser)
                )}
                message={model.errors?.endUser}
              >
                <Select
                  isMaterial={true}
                  classFilter={EndUserFilter}
                  placeHolder="Người hỗ trợ"
                  getList={dormitoryRepository.singleListEndUser}
                  searchProperty={nameof(model.endUser.displayName)}
                  render={(model) => model?.displayName}
                  onChange={handleChangeObjectField(nameof(model.endUser))}
                  model={model.endUser}
                />
              </FormItem>
            </div>
          </div>
          <div className="supporter-footer d-flex mt-3">
            <button className="btn-save mr-2" onClick={handleSave}>
              <span>
                <i className="tio-checkmark_circle_outlined"></i> Lưu
              </span>
            </button>
            <button className="btn-cancel" onClick={cancelSupporterModal}>
              <span>
                <i className="tio-clear"></i> Đóng
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
