import React from "react";
import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { SendOutlined } from "@ant-design/icons";
import { Observable } from "rxjs";
import { TicketConversationFile } from "models/TicketConversationFile";
import { TicketConversationFileMapping } from "models/TicketConversationFileMapping";
import "./TicketConversation.scss";

interface TicketConversationInputProps {
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: (mappings: TicketConversationFileMapping[]) => void;
  uploadFile: (files: Blob[]) => Observable<TicketConversationFile[]>;
}

export default function TicketConversationInput(
  props: TicketConversationInputProps
) {
  const { input, setInput, onSubmit, uploadFile } = props;

  const [attachedFiles, setAttachedFiles] = React.useState<Blob[]>([]);

  const inputRef: React.RefObject<HTMLInputElement> =
    React.useRef<HTMLInputElement>();

  const filesRef: React.RefObject<HTMLInputElement> =
    React.useRef<HTMLInputElement>();

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      uploadFile(attachedFiles).subscribe((res) => {
        const fileMap: TicketConversationFileMapping[] = [];
        if (res.length > 0) {
          res.forEach((f) => fileMap.push({ fileId: f.id }));
        }
        onSubmit(fileMap);
        filesRef.current.innerHTML = "";
      });
    },
    [attachedFiles, uploadFile, onSubmit]
  );

  const handleAttachFile = React.useCallback((files: FileList) => {
    filesRef.current.innerHTML = "";
    const fileArray: Blob[] = [];
    for (let i = 0; i < files.length; i++) {
      fileArray.push(files[i]);
      filesRef.current.innerHTML += `<image src=${URL.createObjectURL(
        files[i]
      )} />`;
    }
    setAttachedFiles(fileArray);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <TextArea
        rows={2}
        bordered={false}
        placeholder="Phản hồi..."
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <div className="d-flex justify-content-between align-items-end">
        <input
          type="file"
          multiple
          ref={inputRef}
          style={{ display: "none" }}
          onChange={(e) => handleAttachFile(e.target.files)}
        />
        <i
          className="tio-attachment_diagonal"
          onClick={() => {
            inputRef.current.click();
          }}
        ></i>
        <Button type="primary" icon={<SendOutlined />} htmlType="submit">
          Gửi
        </Button>
      </div>
      <div className="ticket-conversation-input__files">
        <div ref={filesRef}></div>
      </div>
    </form>
  );
}
