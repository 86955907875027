/* begin general import */
import React, { useMemo } from "react";
import { Col, Row, Menu, Dropdown, Card, Modal, Tooltip, Button } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import masterService from "services/pages/master-service";
import { getAntOrderType } from "services/table-service";
import nameof from "ts-nameof.macro";
import { CSSTransition } from "react-transition-group";
import InputSearch from "components/Utility/InputSearch/InputSearch";
import Pagination from "components/Utility/Pagination/Pagination";
import classNames from "classnames";
/* end general import */

/* begin filter import */
import AdvanceStringFilter from "components/Utility/AdvanceFilter/AdvanceStringFilter/AdvanceStringFilter";
import { StringFilter, IdFilter } from "@react3l/advanced-filters";
/* end filter import */

/* begin individual import */
import { endUserRepository } from "repositories/end-user-repository";
import { EndUser, EndUserFilter, Sex, SexFilter } from "models/EndUser";
import { END_USER_ROUTE } from "config/route-consts";
import AdvanceIdFilter from "components/Utility/AdvanceFilter/AdvanceIdFilter/AdvanceIdFilter";
import { Status, StatusFilter } from "models/Status";
import authenticationService from "services/authentication-service";
import { API_END_USER_PREFIX } from "config/api-consts";
import './EndUserMaster.scss';
import { Moment } from "moment";
import { formatDateTime } from "helpers/date-time";
import { sendNotificationServive } from "services/send-notification-service";
import { SendNotificationType } from "config/consts";
import NotificationForm from "components/Molecules/NotificationForm/NotificationForm";
import { DownOutlined } from "@ant-design/icons";
import { homeControllerRepository } from "repositories/home-controller-repository";

/* end individual import */

function EndUserMaster() {
  const [translate] = useTranslation();
  const { validAction } = authenticationService.useAction(
    "endUser",
    API_END_USER_PREFIX
  );
  const [showForm, setShowForm] = React.useState(false);

  const {
    list,
    total,
    loadingList,
    filter,
    toggle,
    handleUpdateNewFilter,
    handleChangeFilter,
    handleToggleSearch,
    handleTableChange,
    handlePagination,
    handleGoDetail,
    handleResetFilter,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
    handleServerDelete,
    handleServerBulkDelete,
    handleExportTemplateList,
    importButtonRef,
    handleImportList,
    canBulkDelete,
  } = masterService.useMaster<EndUser, EndUserFilter>(
    EndUserFilter,
    END_USER_ROUTE,
    endUserRepository.list,
    endUserRepository.count,
    endUserRepository.delete,
    endUserRepository.bulkDelete
  );
  const {
    form,
    setTitle,
    setContent,
    setIds,
    handleSend
  } = sendNotificationServive.useNotificationForm(SendNotificationType.EndUser);
  const _handleChangeFilter = React.useCallback(
    (value) => {
      const newFilter = { ...filter };
      newFilter["search"] = value;
      handleUpdateNewFilter(newFilter);
    },
    [filter, handleUpdateNewFilter]
  );
  const handleShowModal = React.useCallback(() => {
    setShowForm(true);
  }, []);
  const handleConfirm = React.useCallback(() => {
    handleSend();
    setShowForm(false);
  }, [handleSend]);
  const handleCancelModal = React.useCallback(() => {
    setShowForm(false);
  }, []);
  const handleBulkLock = React.useCallback(() => {
    Modal.confirm({
      title: "Bạn có chắc muốn thực hiện",
      content: "Thao tác không thể khôi phục",
      okType: "danger",
      onOk() {
        endUserRepository.lock(selectedRowKeys).subscribe(
          (res) => {
            if (res) {
              handleResetFilter();
              setSelectedRowKeys([]);
            }
          },
          (_err) => { },
          () => setSelectedRowKeys([])
        );
      },
    });
  }, [setSelectedRowKeys, handleResetFilter, selectedRowKeys]);
  const menuFilter = React.useMemo(
    () => (
      <Menu>
        {/* <Menu.Item key="1">
          <Tooltip title={translate("general.button.downloadTemplate")}>
            <div
              className="ant-action-menu"
              onClick={handleExportTemplateList(
                endUserRepository.exportTemplate
              )}
            >
              <i className="tio-download_to" /> {translate("general.button.downloadTemplate")}
            </div>
          </Tooltip>
        </Menu.Item> */}
        <Menu.Item key="2">
          <Tooltip title={translate("general.actions.lock")}>
            <>
              <div
                className="ant-action-menu"
                onClick={handleBulkLock}
              >
                <i className="tio-lock_outlined mr-2"></i>
                {translate("general.actions.lock")}
              </div>
            </>
          </Tooltip>
        </Menu.Item>
      </Menu>
    ),
    [translate, handleBulkLock]
  );
  const menuAction = React.useCallback(
    (id: number, endUser: EndUser) => (
      <Menu>
        <Menu.Item key="1">
          <div className="ant-action-menu" onClick={handleGoDetail(id)}>
            {translate("general.actions.view")}
          </div>
        </Menu.Item>
        {validAction("delete") && (
          <Menu.Item key="2">
            <div
              className="ant-action-menu"
              onClick={() => handleServerDelete(endUser)}
            >
              {translate("general.actions.delete")}
            </div>
          </Menu.Item>
        )}
      </Menu>
    ),
    [handleGoDetail, translate, validAction, handleServerDelete]
  );

  const columns: ColumnProps<EndUser>[] = useMemo(
    () => [
      {
        title: (
          <div className="text-center gradient-text">
            {translate("endUsers.username")}
          </div>
        ),
        key: nameof(list[0].username),
        dataIndex: nameof(list[0].username),
        sorter: true,
        sortOrder: getAntOrderType<EndUser, EndUserFilter>(
          filter,
          nameof(list[0].username)
        ),
        width: 200,
        render(name: string, endUser: EndUser) {
          return (
            <div className="ant-cell-master__container">
              <div
                className={classNames("cell-master__first-row row--ellipsis")}
              >
                {name}
              </div>
              <div
                className={classNames(
                  "cell-master__second-row row--link row--ellipsis"
                )}
              >
                {endUser.email}
              </div>
            </div>
          );
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("endUsers.sex")}
          </div>
        ),
        key: nameof(list[0].sex),
        dataIndex: nameof(list[0].sex),
        sorter: true,
        sortOrder: getAntOrderType<EndUser, EndUserFilter>(
          filter,
          nameof(list[0].sex)
        ),
        width: 200,
        render(sex: Sex) {
          return (
            <div className="ant-cell-master__container">
              <div className={classNames("cell-master__first-row")}>
                {sex?.name}
              </div>
            </div>
          );
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("endUsers.email")}
          </div>
        ),
        key: nameof(list[0].email),
        dataIndex: nameof(list[0].email),
        sorter: true,
        sortOrder: getAntOrderType<EndUser, EndUserFilter>(
          filter,
          nameof(list[0].email)
        ),
        width: 200,
        render(email: string) {
          return (
            <div className="ant-cell-master__container">
              <div className={classNames("cell-master__first-row")}>
                {email}
              </div>
            </div>
          );
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("endUsers.phoneNumber")}
          </div>
        ),
        key: nameof(list[0].phoneNumber),
        dataIndex: nameof(list[0].phoneNumber),
        sorter: true,
        sortOrder: getAntOrderType<EndUser, EndUserFilter>(
          filter,
          nameof(list[0].phoneNumber)
        ),
        width: 200,
        render(phoneNumber: string) {
          return (
            <div className="ant-cell-master__container">
              <div className={classNames("cell-master__first-row")}>
                {phoneNumber}
              </div>
            </div>
          );
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("endUsers.status")}
          </div>
        ),
        key: nameof(list[0].status),
        dataIndex: nameof(list[0].status),
        sorter: true,
        sortOrder: getAntOrderType<EndUser, EndUserFilter>(
          filter,
          nameof(list[0].status)
        ),
        width: 200,
        render(status: Status) {
          return (
            <div className="ant-cell-master__container">
              {status?.id === 1 ? (
                <div className="active-state mt-1">{status.name}</div>
              ) : (
                <div className="reject-state mt-1">{status.name}</div>
              )}
            </div>
          );
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("endUsers.createdAt")}
          </div>
        ),
        key: nameof(list[0].createdAt),
        dataIndex: nameof(list[0].createdAt),
        sorter: true,
        sortOrder: getAntOrderType<EndUser, EndUserFilter>(
          filter,
          nameof(list[0].createdAt)
        ),
        width: 200,
        render(createdAt: Moment) {
          return (
            <div className="ant-cell-master__container">
              <div className="cell-master__first-row">
                {formatDateTime(createdAt)}
              </div>
            </div>
          );
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("general.actions.action")}
          </div>
        ),
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 100,
        align: "center",
        render(id: number, endUser: EndUser) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, endUser)}
                trigger={["click"]}
                placement="bottomCenter"
                arrow
              >
                <span className="action__dots">...</span>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [translate, list, filter, menuAction]
  );

  const filterChildren = useMemo(
    () => (
      <Row className="mt-4">
        <Col lg={4} className="pr-4">
          <label className="label">{translate("endUsers.username")}</label>
          <AdvanceStringFilter
            isMaterial={true}
            value={filter[nameof(list[0].username)]["contain"]}
            onEnter={handleChangeFilter(
              nameof(list[0].username),
              "contain" as any,
              StringFilter
            )}
            placeHolder={translate("endUsers.placeholder.username")}
          />
        </Col>

        <Col lg={4} className="pr-4">
          <label className="label">{translate("endUsers.displayName")}</label>
          <AdvanceStringFilter
            isMaterial={true}
            value={filter[nameof(list[0].displayName)]["contain"]}
            onEnter={handleChangeFilter(
              nameof(list[0].displayName),
              "contain" as any,
              StringFilter
            )}
            placeHolder={translate("endUsers.placeholder.displayName")}
          />
        </Col>

        <Col lg={4} className="pr-4">
          <label className="label">{translate("endUsers.email")}</label>
          <AdvanceStringFilter
            isMaterial={true}
            value={filter[nameof(list[0].email)]["contain"]}
            onEnter={handleChangeFilter(
              nameof(list[0].email),
              "contain" as any,
              StringFilter
            )}
            placeHolder={translate("endUsers.placeholder.email")}
          />
        </Col>

        <Col lg={4} className="pr-4">
          <label className="label">{translate("endUsers.phoneNumber")}</label>
          <AdvanceStringFilter
            isMaterial={true}
            value={filter[nameof(list[0].phoneNumber)]["contain"]}
            onEnter={handleChangeFilter(
              nameof(list[0].phoneNumber),
              "contain" as any,
              StringFilter
            )}
            placeHolder={translate("endUsers.placeholder.phoneNumber")}
          />
        </Col>

        <Col lg={4} className="pr-4">
          <label className="label">{translate("endUsers.sex")}</label>
          <AdvanceIdFilter
            onChange={handleChangeFilter(
              nameof(list[0].sexId),
              "equal" as any,
              IdFilter
            )}
            value={filter[nameof(list[0].sexId)]["equal"]}
            placeHolder={translate("endUsers.placeholder.sex")}
            isMaterial={true}
            classFilter={SexFilter}
            isEnumList={true}
            getList={endUserRepository.filterListSex}
          />
        </Col>

        <Col lg={4} className="pr-4">
          <label className="label">{translate("endUsers.status")}</label>
          <AdvanceIdFilter
            onChange={handleChangeFilter(
              nameof(list[0].statusId),
              "equal" as any,
              IdFilter
            )}
            value={filter[nameof(list[0].statusId)]["equal"]}
            placeHolder={translate("endUsers.placeholder.status")}
            isMaterial={true}
            classFilter={StatusFilter}
            isEnumList={true}
            getList={endUserRepository.filterListStatus}
          />
        </Col>
      </Row>
    ),
    [filter, handleChangeFilter, list, translate]
  );
  const [dropdown, setDropdown] = React.useState<boolean>(false);

  const handleDropdown = React.useCallback(() => {
    setDropdown(!dropdown);
  }, [dropdown]);

  return (
    <>
      <div className="page page__master end-user__container">
        <div className="page__header d-flex align-items-center justify-content-between">
          <div className="page__title">
            {translate("endUsers.master.title")}
          </div>
        </div>
        <div className="page__search">
          <Card bordered={false}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex w-50">
                <div className="w70">
                  <InputSearch
                    value={filter["search"]}
                    onChange={_handleChangeFilter}
                    placeHolder="Tìm kiếm"
                  />
                </div>
                <button
                  className={classNames(
                    "btn component__btn-toggle ml-2",
                    toggle === true ? "component__btn-toggle-active" : ""
                  )}
                  onClick={handleToggleSearch}
                >
                  <i className="tio-tune_horizontal mr-2"></i>
                  <span className="component_btn-text">
                    {translate("general.button.advance")}
                  </span>
                </button>
              </div>
              {validAction("delete") && (
                <div className="d-flex">
                  <button
                    className="btn component__btn-primary component__btn-primary"
                    onClick={handleShowModal}
                  >
                    <i className="tio-send_outlined mr-2"></i>
                    <span className="component_btn-text">Gửi thông báo</span>
                  </button>
                </div>
              )}
              {validAction("lock") && (
                <div className="d-flex justify-content-around ml-4">
                  <div className="table__action">
                    <Dropdown overlay={menuFilter} trigger={["click"]}>
                      <Button onClick={handleDropdown}>
                        <span className="component_btn-text">
                          {translate("general.actions.action")}
                        </span>
                        <DownOutlined className={dropdown ? "dropdown" : null} />
                      </Button>
                    </Dropdown>
                  </div>
                </div>
              )}
            </div>
            <CSSTransition
              in={toggle}
              timeout={100}
              classNames={"show"}
              unmountOnExit
            >
              {filterChildren}
            </CSSTransition>
          </Card>
        </div>
        <div className="page__master-table custom-scrollbar pb-4">
          <Card bordered={false}>
            <Table
              rowKey={nameof(list[0].id)}
              columns={columns}
              pagination={false}
              dataSource={list}
              loading={loadingList}
              onChange={handleTableChange}
              rowSelection={{
                onChange(selectedRowKeys: any) {
                  rowSelection.onChange(selectedRowKeys);
                  setIds(selectedRowKeys);
                },
                type: 'checkbox'
              }} scroll={{ x: "max-content" }}
              title={() => (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="action__result">{total} Kết quả</span>
                    <div className="flex-shrink-1 d-flex align-items-center">
                      <Tooltip
                        title={translate("general.button.bulkDelete")}
                        key="bulkDelete"
                      >
                        <button
                          className="btn border-less component__btn-delete grow-animate-2"
                          style={{ border: "none", backgroundColor: "unset" }}
                          onClick={handleServerBulkDelete}
                          disabled={!canBulkDelete}
                        >
                          <i className="tio-delete" />
                        </button>
                      </Tooltip>
                      <Pagination
                        skip={filter.skip}
                        take={filter.take}
                        total={total}
                        onChange={handlePagination}
                        style={{ margin: "10px" }}
                      />
                    </div>
                  </div>
                </>
              )}
            />
          </Card>
        </div>
        <NotificationForm
          isOpen={showForm}
          Title={form.Title}
          Content={form.Content}
          Confirm={handleConfirm}
          Cancel={handleCancelModal}
          onChangeTitle={setTitle}
          onChangeContent={setContent}
        />
      </div>
    </>
  );
}

export default EndUserMaster;
