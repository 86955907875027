import { ModalProps } from "antd";
import classNames from "classnames";
import Modal from "components/Utility/Modal/Modal";
import React from "react";
import lockImage from "./../../../assets/images/lock-image.png";

export function useLockAccoutModal(
  handleDeactiveAccount: () => void,
  handleActiveAccount: () => void
) {
  const [visibleLockAccountModal, setVisibleLockAccountModal] = React.useState(
    false
  );

  const openLockAccountModal = React.useCallback(() => {
    setVisibleLockAccountModal(true);
  }, []);

  const cancelLockAccountModal = React.useCallback(() => {
    setVisibleLockAccountModal(false);
  }, []);

  const activeAccountModal = React.useCallback(() => {
    setVisibleLockAccountModal(false);
    handleActiveAccount();
  }, [handleActiveAccount]);

  const deactiveAccountmodal = React.useCallback(() => {
    setVisibleLockAccountModal(false);
    handleDeactiveAccount();
  }, [handleDeactiveAccount]);

  return {
    visibleLockAccountModal,
    openLockAccountModal,
    cancelLockAccountModal,
    activeAccountModal,
    deactiveAccountmodal
  };
}

interface LockAccountModalProps extends ModalProps {
  statusId?: number;
  visibleLockAccountModal?: boolean;
  cancelLockAccountModal?: (e: any) => void;
  activeAccountModal: () => void,
  deactiveAccountmodal: () => void
}

export function LockAccountModal(props: LockAccountModalProps) {
  const {
    statusId,
    visibleLockAccountModal,
    cancelLockAccountModal,
    activeAccountModal,
    deactiveAccountmodal,
  } = props;

  return (
    <>
      <Modal
        title={null}
        onCancel={cancelLockAccountModal}
        visible={visibleLockAccountModal}
        width={585}
        visibleFooter={false}
        closable={false}
      >
        <div className="lock-account-modal__contaier d-flex flex-column">
          <div className="lock-account-modal__header">Khóa tài khoản</div>
          <div className="lock-account-content w-100 d-flex justify-content-center align-items-center mt-4">
            <img src={lockImage} alt="IMG" />
          </div>
          <div className="lock-account-content w-100 d-flex flex-column mt-3">
            <div
              className={classNames("content__title", {
                "content__title--unlock": statusId !== 1,
              })}
            >
              {statusId === 1
                ? "Bạn đang khoá tài khoản của người dùng!!"
                : "Bạn đang mở khoá tài khoản của người dùng!!"}
            </div>
            <div className="content__description mt-2">
              {statusId === 1
                ? "Người dùng sẽ tạm thời không thể truy cập vào tài khoản trên tất cả các thiết bị cho tới khi được mở khoá. Bạn có chắc chắn muốn làm việc này?"
                : "Người dùng sẽ có thể truy cập lại vào tài khoản của mình sau khi bạn hoàn thành hành động. Bạn có chắc chắn muốn mở khoá cho người dùng?"}
            </div>
          </div>
          <div className="lock-account-footer d-flex mt-3">
            <button
              className={classNames("btn-save mr-2", {
                "btn-save--unlock": statusId !== 1,
              })}
              onClick={statusId === 1 ? deactiveAccountmodal : activeAccountModal}
            >
              <span>
                <i className="tio-lock_outlined"></i>{" "}
                {statusId === 1 ? "Khóa" : "Mở khóa"}
              </span>
            </button>
            <button className="btn-cancel" onClick={cancelLockAccountModal}>
              <span>
                <i className="tio-clear"></i> Đóng
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
