import React from "react";
import { Switch } from "react-router-dom";
import { RESOURCE_MASTER_ROUTE } from "config/route-consts";
import { ProtectedRoute, useCheckAuthorized } from "config/protected-route";
import "./ResourceView.scss";
import ResourceMaster from "./ResourceMaster/ResourceMaster";
function ResourceView() {
  const { auth } = useCheckAuthorized();
  return (
    <Switch>
      <ProtectedRoute
        path={RESOURCE_MASTER_ROUTE}
        key={RESOURCE_MASTER_ROUTE}
        component={ResourceMaster}
        auth={auth(RESOURCE_MASTER_ROUTE)}
      />
    </Switch>
  );
}
export { ResourceMaster };
export default ResourceView;

