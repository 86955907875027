import React, { ReactNode, useEffect, useState } from "react";
import { Col, Card } from "antd";
import { Observable } from "rxjs";
import { commonService } from "@react3l/react3l/services";
import { Info } from "models/Info";

interface OverviewInfoProps {
  filterId: number;
  title: string;
  getData: (arg: any) => Observable<any>;
  icon: string | ReactNode;
  onClickOverview?: () => Promise<void>;
  color: string;
  isHighlighted?: boolean;
}

export default function OverviewInfo(props: OverviewInfoProps) {
  const { filterId, title, getData, icon, color, onClickOverview } = props;

  const [info, setInfo] = useState<Info>(null);

  const [subscription] = commonService.useSubscription();

  useEffect(() => {
    if (filterId) {
      subscription.add(
        getData({ time: { equal: filterId } }).subscribe((data: Info) =>
          setInfo(data)
        )
      );
    }
  }, [filterId, getData, subscription]);

  return (
    <>
      <Col span={8} onClick={onClickOverview}>
        <Card
          bordered={false}
          bodyStyle={{
            backgroundColor: "#fff",
            transition: "background-color 0.3s ease",
          }}
          className="dashboard-master__overview"
        >
          <div className="icon" style={{ backgroundColor: color }}>
            <i className={icon as string} style={{ color: "white" }}></i>
          </div>
          <div className="stat">
            <h2 className="m-0">{info?.quantity}</h2>
            <p className="m-0">{title}</p>
          </div>
          {/* <div
            style={{ color: info?.rate >= 0 ? "#3dd598" : "#dc3545" }}
            className="diff ml-4"
          >
            {Math.round((info?.rate + Number.EPSILON) * 100)}%
          </div> */}
        </Card>
      </Col>
    </>
  );
}
