/* begin general import */
import React from "react";
import nameof from "ts-nameof.macro";
import { ASSETS_IMAGE } from "config/consts";
import { Checkbox, Row, Col } from "antd";
import Modal, { ModalProps } from "components/Utility/Modal/Modal";
import { AppUser } from "models/AppUser";
import { useTranslation } from "react-i18next";
import { Observable } from "rxjs";
import { AppUserRole } from "models/AppUserRole";
import { commonService } from "@react3l/react3l/services";
import { finalize } from "rxjs/operators";
import FormItem from "components/Utility/FormItem/FormItem";
import { formService } from "services/form-service";
import InputText from "components/Utility/Input/InputText/InputText";

/* end general import */

interface AppUserCreateModalProps extends ModalProps {
  model: AppUser;
  onChangeSimpleField: (fieldName: string) => (fieldValue: any) => void;
  onChangeObjectField?: (
    fieldName: string
  ) => (fieldIdValue: number, fieldValue?: any) => void;
  onChangeTreeObjectField?: (
    fieldName: string,
    callback?: (id: number) => void
  ) => (list: any[]) => void;
  loading?: boolean;
  getRoles: () => Observable<AppUserRole[]>;
}

function AppUserCreateModal(props: AppUserCreateModalProps) {
  const [translate] = useTranslation();
  const [subscription] = commonService.useSubscription();

  const [loadingRoles, setLoadingRoles] = React.useState<boolean>(false);
  const [roleValues, setRoleValues] = React.useState([]);
  const [roleOptions, setRoleOptions] = React.useState([]);

  const checkOptions = React.useRef([]);

  const {
    model,
    loading,
    getRoles,
    handleSave,
    handleCancel,
    onChangeSimpleField,
  } = props;

  React.useEffect(() => {
    setLoadingRoles(true);
    subscription.add(getRoles);
    getRoles()
      .pipe(finalize(() => setLoadingRoles(false)))
      .subscribe((items: AppUserRole[]) => {
        // handle options init
        setRoleOptions(items);
        // handle checkbox init data
        checkOptions.current = [];
        items.forEach((item) => checkOptions.current.push(item.name));
      });
  }, [getRoles, subscription]);

  const handleRoleChange = React.useCallback((checkedValues) => {
    // change display value
    setRoleValues(checkedValues);
    // change model value
  }, []);

  const handleRoleSave = React.useCallback(() => {
    // Map values to model
    model.appUserRoleMappings = [];
    roleValues.forEach((val) => {
      const role = roleOptions.find((r) => r.name === val);
      model.appUserRoleMappings.push({
        errors: null,
        appUserId: model.id,
        roleId: role.id,
        role: role,
      });
    });
    // Save
    handleSave();
    // Reset role values
    setRoleValues([]);
  }, [handleSave, model, roleOptions, roleValues]);

  return (
    <Modal {...props} width={360}>
      {loading && loadingRoles ? (
        <div className="loading-block">
          <img src={ASSETS_IMAGE + "/spinner.svg"} alt="Loading..." />
        </div>
      ) : (
          <div className="page page__detail">
            <div className="page__modal-header w-100">
              <Row className="d-flex">
                <Col lg={24}>
                  <div className="page__title mr-1 mb-4">
                    {translate("appUsers.detail.title")}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={24} className="pr-3 mb-2">
                  <FormItem
                    label={translate("appUsers.username")}
                    validateStatus={formService.getValidationStatus<AppUser>(
                      model.errors,
                      nameof(model.username)
                    )}
                    message={model.errors?.username}
                  >
                    <InputText
                      isMaterial={true}
                      value={model.username}
                      placeHolder={translate("appUsers.placeholder.username")}
                      onChange={onChangeSimpleField(nameof(model.username))}
                      icon="tio-account_circle"
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col lg={24} className="pr-3 mb-2">
                  <FormItem
                    label={translate("appUsers.email")}
                    validateStatus={formService.getValidationStatus<AppUser>(
                      model.errors,
                      nameof(model.email)
                    )}
                    message={model.errors?.email}
                  >
                    <InputText
                      isMaterial={true}
                      value={model.email}
                      placeHolder={translate("appUsers.placeholder.email")}
                      onChange={onChangeSimpleField(nameof(model.email))}
                      icon="tio-email_outlined"
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col lg={24}>
                  <label className="label mb-2">
                    {translate("appUsers.role")}
                  </label>
                  <Checkbox.Group
                    options={checkOptions.current}
                    value={roleValues}
                    onChange={handleRoleChange}
                  />
                </Col>
              </Row>
              <Row>
                <button
                  className="btn btn-sm component__btn-primary mr-2"
                  onClick={handleRoleSave}
                >
                  <span>
                    <i className="tio-save" /> {translate("general.actions.save")}
                  </span>
                </button>
                <button
                  className="btn btn-sm component__btn-cancel"
                  onClick={handleCancel}
                >
                  <i className="tio-clear" />{" "}
                  {translate("general.actions.cancel")}
                </button>
              </Row>
            </div>
          </div>
        )}
    </Modal>
  );
}

export default AppUserCreateModal;
