import React from "react";
import { Switch } from "react-router-dom";
import { DASHBOARD_MASTER_ROUTE } from "config/route-consts";
import { ProtectedRoute, useCheckAuthorized } from "config/protected-route";

import "./DashboardView.scss";
import DashboardMaster from "./DashboardMaster/DashboardMaster";

function DashboardView() {
  const { auth } = useCheckAuthorized();
  return (
    <Switch>
      <ProtectedRoute
        path={DASHBOARD_MASTER_ROUTE}
        key={DASHBOARD_MASTER_ROUTE}
        component={DashboardMaster}
        auth={auth(DASHBOARD_MASTER_ROUTE)}
      />
    </Switch>
  );
}

export { DashboardMaster };
export default DashboardView;
