/* begin general import */
import React from 'react';
import { Model } from '@react3l/react3l/core/model';
import Modal from 'components/Utility/Modal/Modal';
import { TFunction } from 'i18next';
import nameof from 'ts-nameof.macro';
/* end general import */

/* begin individual import */
import Select from 'components/Utility/Select/Select';
import { ResourceTranslationLanguageFilter, ResourceTranslationLanguage } from 'models/ResourceTranslationLanguage';
import { resourceRepository } from 'repositories/resource-repository';
import { commonService } from '@react3l/react3l/services';
import { Button, Row, Col } from 'antd';
/* end individual import */

interface ResourceImportProps {
  reloadList: () => void,
  isOpenImport?: boolean;
  handleCloseImport?: () => void;
  translate?: TFunction;
};

export const useResourceImport = () => {
  const [isOpenImport, setIsOpenImport] = React.useState<boolean>(false);

  const handleOpenImport = React.useCallback(() => {
    setIsOpenImport(true);
  }, []);

  const handleCloseImport = React.useCallback(() => {
    setIsOpenImport(false);
  }, []);

  return {
    isOpenImport,
    handleOpenImport,
    handleCloseImport
  };
};

export function ResourceImportModal(props: ResourceImportProps
) {

  const { reloadList, isOpenImport, handleCloseImport, translate } = props;

  const [language, setLanguage] = React.useState<ResourceTranslationLanguage>(null);
  const [file, setFile] = React.useState<File>(null);

  const [subscription] = commonService.useSubscription();

  const handleChangeLanguage = React.useCallback((id: number, language?: Model) => {
    setLanguage(language);
  }, []);

  const handleSelectFile = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      setFile(file);
    }
  }, []);

  const handleResetModal = React.useCallback(() => {
    setFile(null);
    setLanguage(null);
  }, []);

  const handleImport = React.useCallback(() => {
    if (file != null && language != null)
      subscription.add(
        resourceRepository.import(file, language.id).subscribe(
          _ => {
            reloadList();
            handleResetModal();
            handleCloseImport();
          }
        )
      );
  }, [subscription, file, language, reloadList, handleCloseImport, handleResetModal]);

  return <>
    <Modal title={"Import"}
      visible={isOpenImport}
      handleCancel={handleCloseImport}
      width={1200}
      visibleFooter={false}>
      <div className="preview__containter">
        <Col className="px-3">
          <Row className="my-2">
            <input
              type="file"
              id="master-import"
              onChange={handleSelectFile}
            />
          </Row>
          <Row className="my-2">
            <Select
              isMaterial={true}
              classFilter={ResourceTranslationLanguageFilter}
              placeHolder="Ngôn ngữ"
              getList={resourceRepository.singleListLanguage}
              searchProperty={nameof(language.name)}
              render={(language) => language?.name}
              onChange={handleChangeLanguage}
              model={language}
            />
          </Row>
          <Row className="my-2">
            <Button onClick={handleImport}>
              <span className="component_btn-text">
                {translate("resources.action.import")}
              </span>
            </Button>
          </Row>
        </Col>
      </div>
    </Modal>
  </>;
}
