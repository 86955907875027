import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import vi from '../i18n/vi.json';
import en from '../i18n/en.json';
import axios, { AxiosResponse } from 'axios';

/**
 * Handle app translation
 */
export class AppTranslation {
  private languages: Object = {
    "vi": {
      source: vi,
      id: 1
    },
    "en": {
      source: en,
      id: 2
    },
  };

  /**
   * Initialize translation service
   */
  public async initialize(): Promise<void> {
    await i18next
      .use(initReactI18next)
      .init({
        lng: "vi",
        fallbackLng: "vi",
        ns: '',
        defaultNS: '',
      });

    axios
      .post('/rpc/iot-cloud/resource/export', {
        languageId: 1,
      })
      .then(async (response: AxiosResponse<any>) => {
        let resource = { ...vi, ...response.data };
        i18next.addResources("vi", '', resource);
        i18next.changeLanguage("vi");
      });
  }

  public async changeLanguage(code: string): Promise<void> {
    if (code in this.languages) {
      let lang = this.languages[code];
      let localResource = lang.source;
      axios
        .post('/rpc/iot-cloud/resource/export', {
          languageId: lang.id,
        })
        .then(async (response: AxiosResponse<any>) => {
          let resource = { ...localResource, ...response.data };
          i18next.addResources(code, '', resource);
          i18next.changeLanguage(code);
        });
    }
  }
}

export const appTranslation: AppTranslation = new AppTranslation();
