/* begin general import */
import React from "react";
import nameof from "ts-nameof.macro";
import { Card, Col, Row, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import Modal, { ModalProps } from "components/Utility/Modal/Modal";
import FormItem, { ValidateStatus } from "components/Utility/FormItem/FormItem";
import { FormDetailAction, FORM_DETAIL_CHANGE_SIMPLE_FIELD_ACTION, formService } from "services/form-service";
import { ASSETS_SVG } from "config/consts";
/* end general import */

/* begin individual import */
import InputText from "components/Utility/Input/InputText/InputText";
import { Resource } from 'models/Resource';
import { ResourceTranslationLanguage } from "models/ResourceTranslationLanguage";
/* end individual import */

const { TabPane } = Tabs;

interface ResourceDetailModalProps extends ModalProps {
  model: Resource;
  languages: ResourceTranslationLanguage[],
  onChangeSimpleField: (fieldName: string) => (fieldValue: any) => void;
  onChangeObjectField?: (
    fieldName: string,
  ) => (fieldIdValue: number, fieldValue?: any) => void;
  onChangeTreeObjectField?: (
    fieldName: string,
    callback?: (id: number) => void,
  ) => (list: any[]) => void;
  dispatchModel?: React.Dispatch<FormDetailAction<Resource>>;
  loading?: boolean;
}

function ResourceDetailModal(props: ResourceDetailModalProps) {
  const [translate] = useTranslation();

  const {
    model,
    languages,
    onChangeSimpleField,
    dispatchModel,
    loading,
  } = props;

  const handleChangeTranslation = React.useCallback((langId: number, value: string) => {
    if (model.resourceTranslationMappings == null)
      model.resourceTranslationMappings = [];
    let newTranslation = [...model.resourceTranslationMappings];
    if (!newTranslation.find(item => {
      if (item.resourceTranslationLanguageId === langId) {
        item.resourceTranslationLanguageId = langId;
        item.value = value;
        return item;
      }
      return null;
    })) {
      newTranslation.push({ resourceTranslationLanguageId: langId, resourceId: model.id, value: value });
    }
    dispatchModel({
      type: FORM_DETAIL_CHANGE_SIMPLE_FIELD_ACTION,
      fieldName: nameof(model.resourceTranslationMappings),
      fieldValue: newTranslation,
    });
  }, [model.id, model.resourceTranslationMappings, dispatchModel]);

  return (
    <Modal
      {...props}
      width={1200}>
      {loading ?
        (
          <div className='loading-block'>
            <img src={ASSETS_SVG + '/spinner.svg'} alt='Loading...' />
          </div>
        ) :
        (
          <div className='page page__detail'>
            <div className='page__modal-header w-100'>
              <Row className='d-flex'>
                <Col lg={24}>
                  {model?.id ? (
                    <div className='page__title mr-1'>
                      {translate("resources.detail.title")}
                    </div>
                  ) : (
                      translate("general.actions.create")
                    )}
                </Col>
              </Row>
            </div>
            <div className='w-100 page__detail-tabs'>
              <Row className='d-flex'>
                <Col lg={24}>
                  <Card>
                    <Tabs defaultActiveKey='1'>
                      <TabPane tab={translate("general.detail.generalInfomation")}
                        key='1'>
                        <Row>
                          <Col lg={12} className='pr-3'>
                            <FormItem label={translate("resources.name")}
                              validateStatus={formService.getValidationStatus<Resource>(model.errors, nameof(model.name))}
                              message={model.errors?.name}>
                              <InputText isMaterial={true}
                                value={model.name}
                                placeHolder={translate("resources.placeholder.name")}
                                className={"tio-account_square_outlined"}
                                onChange={onChangeSimpleField(nameof(model.name))} />
                            </FormItem>
                          </Col>
                          {
                            languages.map(
                              (lang) => (
                                <React.Fragment key={lang.id}>
                                  <Col lg={12} className='pr-3'>
                                    <FormItem label={translate(`resources.${lang.code.toLowerCase()}`)}
                                      validateStatus={ValidateStatus.validating}
                                      message={model.errors?.name}>
                                      <InputText isMaterial={true}
                                        value={model?.resourceTranslationMappings?.find((item: any) => item.resourceTranslationLanguageId === lang.id)?.value}
                                        placeHolder={translate(`resources.placeholder.${lang.code.toLowerCase()}`)}
                                        onChange={(value: any) => handleChangeTranslation(lang.id, value)} />
                                    </FormItem>
                                  </Col>
                                </React.Fragment>
                              )
                            )}
                        </Row>
                      </TabPane>
                    </Tabs>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
    </Modal>
  );
}

export default ResourceDetailModal;
