import React from "react";
import { Switch } from "react-router-dom";
import { TICKET_MASTER_ROUTE, TICKET_DETAIL_ROUTE } from "config/route-consts";
import { ProtectedRoute, useCheckAuthorized } from "config/protected-route";

import "./TicketView.scss";
import TicketDetail from "./TicketDetail/TicketDetail";
import TicketMaster from "./TicketMaster/TicketMaster";

function TicketView() {
  const { auth } = useCheckAuthorized();
  return (
    <Switch>
      <ProtectedRoute
        path={TICKET_MASTER_ROUTE}
        key={TICKET_MASTER_ROUTE}
        component={TicketMaster}
        auth={auth(TICKET_MASTER_ROUTE)}
      />
      <ProtectedRoute
        path={TICKET_DETAIL_ROUTE}
        key={TICKET_DETAIL_ROUTE}
        component={TicketDetail}
        auth={auth(TICKET_DETAIL_ROUTE)}
      />
    </Switch>
  );
}

export { TicketMaster, TicketDetail };
export default TicketView;
