import React from "react";
import { Observable } from "rxjs";
import { AppUser } from "models/AppUser";
import appMessageService from "services/app-message-service";
import { commonService } from "@react3l/react3l/services";
import { finalize } from "rxjs/operators";
import { queryStringService } from "services/query-string-service";
import { formService } from "services/form-service";
import { routerService } from "services/route-service";
import { APP_USER_ROUTE } from "config/route-consts";

export function useAppUserDetailRoleModal(
  getDetail: (id: number) => Observable<AppUser>,
  saveModel: (newModel: AppUser) => Observable<AppUser>
) {
  // get id from url
  const { id }: any = queryStringService.useGetQueryString();

  // message service
  const {
    notifyUpdateItemSuccess,
    notifyUpdateItemError,
  } = appMessageService.useCRUDMessage();
  // subscription service for clearing subscription
  const [subscription] = commonService.useSubscription();

  const [isOpenRoleModal, setIsOpenRoleModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [, , , handleGoBase] = routerService.useMasterNavigation(
    APP_USER_ROUTE
  );

  const [
    model,
    handleChangeSimpleField,
    handleChangeObjectField,
    handleUpdateNewModel, // alternate for setModel
    handleChangeTreeObjectField,
    dispatch,
  ] = formService.useDetailForm<AppUser>(AppUser, parseInt(id), getDetail);

  React.useEffect(() => {
    setLoading(true);
    subscription.add(
      getDetail(id)
        .pipe(finalize(() => setLoading(false)))
        .subscribe((item: AppUser) => {
          handleUpdateNewModel(item);
        })
    );
  }, [getDetail, handleUpdateNewModel, id, subscription]);

  const handleOpenRoleModal = React.useCallback(() => {
    setIsOpenRoleModal(true);
  }, []);

  const handleCloseRoleModal = React.useCallback(() => {
    setIsOpenRoleModal(false);
  }, []);

  const handleSaveModel = React.useCallback(() => {
    setLoading(true);
    subscription.add(saveModel);
    saveModel(model)
      .pipe(finalize(() => setLoading(false)))
      .subscribe(
        (item: AppUser) => {
          handleUpdateNewModel(item);
          setIsOpenRoleModal(false);
          notifyUpdateItemSuccess();
        },
        (_error: any) => {
          notifyUpdateItemError();
        }
      );
  }, [
    handleUpdateNewModel,
    model,
    notifyUpdateItemError,
    notifyUpdateItemSuccess,
    saveModel,
    subscription,
  ]);

  return {
    model,
    isOpenRoleModal,
    loading,
    handleOpenRoleModal,
    handleCloseRoleModal,
    handleUpdateNewModel,
    handleSaveModel,
    handleChangeSimpleField,
    handleChangeObjectField,
    handleChangeTreeObjectField,
    handleGoBase,
    dispatch,
  };
}
