import React from "react";
import { Switch } from "react-router-dom";
import { ROOM_DETAIL_ROUTE } from "config/route-consts";
import { ProtectedRoute, useCheckAuthorized } from "config/protected-route";

import "./RoomView.scss";
import RoomDetail from "./RoomDetail/RoomDetail";

function RoomView() {
  const { auth } = useCheckAuthorized();
  return (
    <Switch>
      <ProtectedRoute
        path={ROOM_DETAIL_ROUTE}
        key={ROOM_DETAIL_ROUTE}
        component={RoomDetail}
        auth={auth(ROOM_DETAIL_ROUTE)}
      />
    </Switch>
  );
}

export { RoomDetail };
export default RoomView;
