import {
  DASHBOARD_ROUTE,
  FIRMWARE_ROUTE,
  CERTIFICATION_ROUTE,
  ROOM_ROUTE,
  RESOURCE_ROUTE,
} from "config/route-consts";
import DashboardView from "views/DashboardView/DashboardView";

import { APP_USER_ROUTE } from "config/route-consts";
import AppUserView from "views/AppUserView/AppUserView";

import { HOME_CONTROLLER_ROUTE } from "config/route-consts";
import HomeControllerView from "views/HomeControllerView/HomeControllerView";

// import { DEVICE_TYPE_ROUTE } from "config/route-consts";
// import DeviceTypeView from "views/DeviceTypeView/DeviceTypeView";

import { END_USER_ROUTE } from "config/route-consts";
import EndUserView from "views/EndUserView/EndUserView";

import { DORMITORY_ROUTE } from "config/route-consts";
import DormitoryView from "views/DormitoryView/DormitoryView";

import { TICKET_ROUTE } from "config/route-consts";
import TicketView from "views/TicketView/TicketView";
import FirmwareView from "views/FirmwareView/FirmwareView";
import ResourceView from "views/ResourceView/ResourceView";

import CertificationView from "views/CertificationView/CertificationView";
import RoomView from "views/RoomView/RoomView";

export interface Route {
  path: string;
  component: () => JSX.Element;
}

export const routes: Route[] = [
  {
    path: DASHBOARD_ROUTE,
    component: DashboardView,
  },

  {
    path: APP_USER_ROUTE,
    component: AppUserView,
  },

  {
    path: END_USER_ROUTE,
    component: EndUserView,
  },

  {
    path: TICKET_ROUTE,
    component: TicketView,
  },

  {
    path: DORMITORY_ROUTE,
    component: DormitoryView,
  },

  {
    path: ROOM_ROUTE,
    component: RoomView,
  },

  {
    path: HOME_CONTROLLER_ROUTE,
    component: HomeControllerView,
  },

  {
    path: FIRMWARE_ROUTE,
    component: FirmwareView,
  },

  {
    path: CERTIFICATION_ROUTE,
    component: CertificationView,
  },

  {
    path: RESOURCE_ROUTE,
    component: ResourceView,
  },

];
