import { Button, Col, Row, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import classNames from "classnames";
import React from "react";
import { SupporterModal, useSupporterModal } from "./SupporterModal";
import useDormitoryDetail from "./DormitoryDetailHook";
import { HomeController } from "models/HomeController";
import { Room } from "models/Room";
import { Status } from "models/Status";
import HereMap from "components/Utility/HereMap/HereMap";
import { dormitoryRepository } from "repositories/dormitory-repository";
import authenticationService from "services/authentication-service";
import { API_DORMITORY_PREFIX } from "config/api-consts";
import { useHistory } from "react-router";
import { HOME_CONTROLLER_DETAIL_ROUTE, ROOM_DETAIL_ROUTE } from "config/route-consts";
import AppFooter from "components/AppFooter/AppFooter";
import { ActivityLogModal, useActivityLogModal } from './ActivityLogModal';

function DormitoryDetail() {
  const history = useHistory();

  const { model, setModel, handleGoEndUser } = useDormitoryDetail(
    dormitoryRepository.get
  );

  const { validAction } = authenticationService.useAction(
    "dormitory",
    API_DORMITORY_PREFIX
  );

  const {
    visibleSupporterModal,
    openSupporterModal,
    cancelSupporterModal,
    saveSupporterModal,
  } = useSupporterModal();

  const {
    openActivityLogModal,
    visibleActivityLogModal,
    cancelActivityLogModal,
  } = useActivityLogModal();

  const handleGoHomeController = React.useCallback(
    (id: string | number) => (_event: any) => {
      if (id) {
        history.push(`${HOME_CONTROLLER_DETAIL_ROUTE}?id=${id}`);
      }
    },
    [history]
  );

  const handleGoRoom = React.useCallback(
    (id: string | number) => (_event: any) => {
      if (id) {
        history.push(`${ROOM_DETAIL_ROUTE}?id=${id}`);
      }
    },
    [history]
  );

  const handleGoBack = React.useCallback(() => history.goBack(), [history]);

  const homeControllerColums: ColumnProps<HomeController>[] = [
    {
      title: <div className="gradient-text text-upper">HC/MAC</div>,
      key: "name",
      dataIndex: "name",
      width: 200,
      render(name: string, hc: HomeController) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames(
                "cell-master__first-row",
                "first-row--link",
                {
                  "first-row--ellipsis": true,
                }
              )}
              onClick={handleGoHomeController(hc.id)}
            >
              {name}
            </div>
            <div className="cell-master__second-row">{hc.macAddress}</div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">IP của HC</div>,
      key: "ipAddress",
      dataIndex: "ipAddress",
      width: 200,
      render(ipAddress: string) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames("cell-master__first-row", {
                "first-row--ellipsis": true,
              })}
            >
              {ipAddress}
            </div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">Version FW</div>,
      key: "version",
      dataIndex: "version",
      width: 200,
      render(version: string) {
        return (
          <div className="ant-cell-master__container">
            <div className="cell-master__first-row">{version}</div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">Trạng thái</div>,
      key: "homeControllerActiveStatus",
      dataIndex: "homeControllerActiveStatus",
      width: 200,
      render(hcStat: Status) {
        return (
          <>
            {hcStat && (
              <div
                className={`home-controller__status home-controller__status--${hcStat.id} mt-1`}
              >
                {hcStat?.name}
              </div>
            )}
          </>
        );
      },
    },
  ];

  const roomColums: ColumnProps<Room>[] = [
    {
      title: <div className="gradient-text text-upper">Id</div>,
      key: "name",
      dataIndex: "name",
      width: 200,
      render(id: string, r: Room) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames(
                "cell-master__first-row",
                "first-row--link",
                {
                  "first-row--ellipsis": true,
                }
              )}
              onClick={handleGoRoom(r.id)}
            >
              {id}
            </div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">Tên</div>,
      key: "name",
      dataIndex: "name",
      width: 200,
      render(name: string, r: Room) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames(
                "cell-master__first-row",
                "first-row--link",
                {
                  "first-row--ellipsis": true,
                }
              )}
              onClick={handleGoHomeController(r.id)}
            >
              {name}
            </div>
          </div>
        );
      },
    },
  ];
  const childrenAction = React.useMemo(
    () => (
      <>
        <button
          className="btn component__btn-primary ticket-detail__action--do mr-4"
          onClick={handleGoBack}
        >
          Quay lại
        </button>
      </>
    ),
    [handleGoBack]
  );

  return (
    <div className="page page__detail w-100 home__container">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={6}>
          <div className="left-side__container w-100 d-flex flex-column">
            <div className="left-side__location w-100 d-flex flex-column">
              <div className="location__header">{model.name}</div>
              <div className="location__content d-flex flex-column">
                <div className="content__location mb-3">
                  <div className="location__info">{model.address}</div>
                </div>
                <div className="content__map">
                  <HereMap lat={model.latitude} lng={model.longitude} />
                </div>
              </div>
            </div>
            {validAction("addSupporter") && (
              <div className="left-side__action" onClick={openSupporterModal}>
                <span>
                  <i className="tio-user_add mr-1"></i>Thêm tài khoản hỗ trợ
                </span>
              </div>
            )}
          </div>
          <div className="left-side__container w-100 d-flex flex-column mt-2">
            <Button onClick={openActivityLogModal}>
              Lịch sử hoạt động
            </Button>
          </div>
        </Col>
        <Col className="gutter-row" span={18}>
          <div className="right-side__container w-100">
            <div className="right-side__user-list w-100 d-flex">
              {model.members &&
                model.members.length > 0 &&
                model.members.map((mem) => (
                  <div
                    className="user__item d-flex flex-column justify-content-center align-items-center"
                    key={mem.endUserId}
                  >
                    <div className="user__avatar d-flex">
                      <img
                        src="https://media.discordapp.net/attachments/663753852184428596/847406738138595348/7ab2cd69-80fe-4106-ba8d-218d78b131d4.png"
                        alt="IMG"
                      />
                    </div>
                    <div className="user__name">{mem.endUser?.displayName}</div>
                    <div className="user_role">{mem.endUserRole?.name}</div>
                    <div className="user_action">
                      <span onClick={handleGoEndUser(mem.endUserId)}>Xem</span>
                    </div>
                  </div>
                ))}
            </div>
            <div className="right-side__home-controller">
              <div className="home-controller__title">Home Controller</div>
              <div className="home-controller__table">
                <Table
                  rowKey={"id"}
                  columns={homeControllerColums}
                  pagination={false}
                  dataSource={model.homeControllers}
                  rowSelection={null}
                  scroll={{ x: "max-content" }}
                ></Table>
              </div>
            </div>
            <div className="right-side__home-controller">
              <div className="home-controller__title">Room</div>
              <div className="home-controller__table">
                <Table
                  rowKey={"id"}
                  columns={roomColums}
                  pagination={false}
                  dataSource={model.rooms}
                  rowSelection={null}
                  scroll={{ x: "max-content" }}
                ></Table>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <AppFooter childrenAction={childrenAction}></AppFooter>
      <SupporterModal
        setDormitory={setModel}
        visibleSupporterModal={visibleSupporterModal}
        cancelSupporterModal={cancelSupporterModal}
        saveSupporterModal={saveSupporterModal}
      />
      <ActivityLogModal
        visibleActivityLogModal={visibleActivityLogModal}
        cancelActivityLogModal={cancelActivityLogModal}
      />
    </div>
  );
}

export default DormitoryDetail;
