/* begin general import */
import React from "react";
import { ASSETS_IMAGE } from "config/consts";
import { Checkbox, Row, Col } from "antd";
import Modal, { ModalProps } from "components/Utility/Modal/Modal";
import { AppUser } from "models/AppUser";
import { useTranslation } from "react-i18next";
import { Observable } from "rxjs";
import { AppUserRole } from "models/AppUserRole";
import { commonService } from "@react3l/react3l/services";
import { finalize } from "rxjs/operators";
import "../../AppUserRoleModal.scss";

/* end general import */

interface AppUserDetailRoleModalProps extends ModalProps {
  model: AppUser;
  loading: boolean;
  getRoles: () => Observable<AppUserRole[]>;
}

function AppUserDetailRoleModal(props: AppUserDetailRoleModalProps) {
  const [translate] = useTranslation();
  const [subscription] = commonService.useSubscription();

  const [loadingRoles, setLoadingRoles] = React.useState<boolean>(false);
  const [roleValues, setRoleValues] = React.useState([]);
  const [roleOptions, setRoleOptions] = React.useState([]);

  const checkOptions = React.useRef([]);

  const { model, loading, getRoles, handleSave, handleCancel } = props;

  React.useEffect(() => {
    setLoadingRoles(true);
    subscription.add(getRoles);
    getRoles()
      .pipe(finalize(() => setLoadingRoles(false)))
      .subscribe((items: AppUserRole[]) => {
        // handle options init
        setRoleOptions(items);
        // handle checkbox init data
        checkOptions.current = [];
        items.forEach((item) => checkOptions.current.push(item.name));
      });
  }, [getRoles, subscription]);

  React.useEffect(() => {
    if (model.appUserRoleMappings && model.appUserRoleMappings.length !== 0) {
      const _r = [];
      model.appUserRoleMappings.forEach((role) => _r.push(role.role.name));
      setRoleValues(_r);
    }
  }, [model.appUserRoleMappings]);

  const handleRoleChange = React.useCallback((checkedValues) => {
    // change display value
    setRoleValues(checkedValues);
    // change model value
  }, []);

  const handleRoleSave = React.useCallback(() => {
    // Map values to model
    model.appUserRoleMappings.length = 0;
    roleValues.forEach((val) => {
      const role = roleOptions.find((r) => r.name === val);
      model.appUserRoleMappings.push({
        errors: null,
        appUserId: model.id,
        roleId: role.id,
        role: role,
      });
    });
    // Save
    handleSave();
  }, [handleSave, model, roleOptions, roleValues]);

  return (
    <Modal {...props} width={249}>
      {loading && loadingRoles ? (
        <div className="loading-block">
          <img src={ASSETS_IMAGE + "/spinner.svg"} alt="Loading..." />
        </div>
      ) : (
        <div className="page page__detail">
          <div className="page__modal-header w-100">
            <Row className="d-flex">
              <Col lg={24}>
                <div className="page__title mr-1">
                  {translate("appUsers.role")}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={24}>
                <div className="app-user__info">
                  <p>{model.displayName}</p>
                  <p>{model.email}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={24}>
                <Checkbox.Group
                  options={checkOptions.current}
                  value={roleValues}
                  onChange={handleRoleChange}
                />
              </Col>
            </Row>
            <Row>
              <button
                className="btn btn-sm component__btn-primary mr-2"
                onClick={handleRoleSave}
              >
                <span>
                  <i className="tio-save" /> {translate("general.actions.save")}
                </span>
              </button>
              <button
                className="btn btn-sm component__btn-cancel"
                onClick={handleCancel}
              >
                <i className="tio-clear" />{" "}
                {translate("general.actions.cancel")}
              </button>
            </Row>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default AppUserDetailRoleModal;
