import { Model } from "@react3l/react3l/core";
import { Moment } from "moment";
import { ResourceTranslationMapping } from "models/ResourceTranslationMapping";

export class Resource extends Model {
  public id?: number;
  public name?: string;
  public createdAt?: Moment;
  public updatedAt?: Moment;
  public resourceTranslationMappings?: ResourceTranslationMapping[];
}
