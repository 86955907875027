import React, { Dispatch } from "react";
import detailService from "services/pages/detail-service";
import { ticketRepository } from "repositories/ticket-repository";
import { TICKET_ROUTE } from "config/route-consts";
import { Ticket } from "models/Ticket";
import { commonService } from "@react3l/react3l/services";
import { AuthContext } from "app/app-context";
import { AppUser } from "models/AppUser";

export function useTicketDetail() {
  const { model, handleUpdateNewModel } = detailService.useDetail(
    Ticket,
    ticketRepository.get,
    undefined,
    TICKET_ROUTE
  );

  const [appUserInfo] = React.useContext<[AppUser, Dispatch<AppUser>]>(AuthContext);

  const [subscription] = commonService.useSubscription();

  const handleSetStatus = React.useCallback(() => {
    let newStatus = {};
    if (model.ticketStatus.id === 1)
      newStatus = {
        ticketStatusId: 2,
        ticketStatus: {
          id: 2,
          code: "DO",
          name: "Đang xử lý",
        },
      };
    else if (model.ticketStatus.id === 2)
      newStatus = {
        ticketStatusId: 3,
        ticketStatus: {
          id: 3,
          code: "DONE",
          name: "Hoàn thành",
        },
      };
    subscription.add(
      ticketRepository
        .update({ ...model, ...newStatus })
        .subscribe((item) => handleUpdateNewModel(item))
    );
  }, [handleUpdateNewModel, model, subscription]);

  const handleSelfAssign = React.useCallback(() => {
    const assigneeInfo = {
      appUserId: appUserInfo.id,
      appUser: appUserInfo,
    };
    subscription.add(
      ticketRepository
        .update({ ...model, ...assigneeInfo })
        .subscribe((item) => handleUpdateNewModel(item))
    );
  }, [handleUpdateNewModel, model, subscription, appUserInfo]);

  return {
    model,
    handleSetStatus,
    handleSelfAssign,
  };
}
