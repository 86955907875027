/* begin general import */
import React, { useEffect, useState } from "react";
import nameof from "ts-nameof.macro";
import { Card, Col, Row, Table } from "antd";
import AppFooter from "components/AppFooter/AppFooter";
/* end general import */

/* begin individual import */
import { EndUser } from "models/EndUser";
import { endUserRepository } from "repositories/end-user-repository";
import icon from "./../../../assets/images/Oval.png";
import emptyTicket from "./../../../assets/images/empty-ticket.png";
import userInactive from "./../../../assets/images/user/user-inactive.png";
import { LockAccountModal, useLockAccoutModal } from "./LockAccountModal";
import {
  ForgotPasswordModal,
  useForgotPasswordModal,
} from "./ForgotPasswordModal";
import { useEndUserDetailHook, OpenTicketList } from "./EndUserDetailHook";
import {
  ActivityModal,
  useActivityModal,
  ActivityList,
  getActivitiesDict,
} from "./ActivityModal";
import detailService from "services/pages/detail-service";
import { END_USER_ROUTE } from "config/route-consts";
import { formatDate } from "helpers/date-time";
import "./EndUserDetail.scss";
import authenticationService from "services/authentication-service";
import { API_END_USER_PREFIX } from "config/api-consts";
import FormItem from "components/Utility/FormItem/FormItem";
import { formService } from "services/form-service";
import InputText from "components/Utility/Input/InputText/InputText";
import Select from "components/Utility/Select/Select";
import { SexFilter } from "models/Sex";
import DatePicker from "components/Utility/Calendar/DatePicker/DatePicker";
/* end individual import */

function EndUserPreview() {
  const { validAction } = authenticationService.useAction(
    "endUser",
    API_END_USER_PREFIX
  );
  const { model, handleGoBase, handleUpdateNewModel, handleChangeSimpleField, handleChangeObjectField } = detailService.useDetail(
    EndUser,
    endUserRepository.get,
    endUserRepository.save,
    END_USER_ROUTE,
  );
  const {
    isEditable,
    handleEdit,
    handleFinish,
    homeControllerColumns,
    dormitoryColumns,
    handleDeactiveAccount,
    handleActiveAccount,
    handleSaveModel
  } = useEndUserDetailHook(model, handleUpdateNewModel, endUserRepository.save);

  const handleSaveDetail = React.useCallback(() => {
    handleFinish();
    handleSaveModel();
  }, [handleFinish, handleSaveModel]);

  const handleCancelDetail = React.useCallback(() => {
    handleFinish();
  }, [handleFinish]);
  const {
    visibleLockAccountModal,
    openLockAccountModal,
    cancelLockAccountModal,
    activeAccountModal,
    deactiveAccountmodal,
  } = useLockAccoutModal(handleDeactiveAccount, handleActiveAccount);

  const {
    visibleForgotPasswordModal,
    openForgotPasswordModal,
    cancelForgotPasswordModal,
    saveForgotPasswordModal,
  } = useForgotPasswordModal();

  const {
    loading,
    list,
    visibleActivityModal,
    openActivityModal,
    cancelActivityModal,
    handleApplyFilter,
  } = useActivityModal(endUserRepository.getActivityLog);

  return (
    <>
      <div className="page page__detail w-100 end-user__container">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={6}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row user-info__wrapper" span={24}>
                <Card>
                  <div className="d-flex w-100 user-info__container flex-column">
                    <div className="user-info__header d-flex flex-column justify-content-center">
                        <div className="user-info__image">
                          <img
                            src={model?.avatar ?? userInactive}
                            alt={""}
                          />
                          <div className="user-info__status">
                            <img src={icon} alt={"IMG"} />
                          </div>
                        </div>
                      <div className="d-flex flex-column mt-2">
                        {!isEditable ? (
                          <>
                            <div className="user-info__label-field user-info__label-field--big">
                              {model.displayName}
                            </div>
                            <div className="user-info__label-content user-info__label-content--light mt-1">
                              {model.username}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="w-100 mt-1">
                              <FormItem
                                validateStatus={formService.getValidationStatus<EndUser>(
                                  model.errors,
                                  nameof(model.displayName)
                                )}
                                message={model.errors?.displayName}
                              >
                                <InputText
                                  isMaterial={true}
                                  value={model.displayName}
                                  placeHolder={"Nhập tên hiển thị"}
                                  onChange={handleChangeSimpleField(
                                    nameof(model.displayName)
                                  )}
                                />
                              </FormItem>
                            </div>
                            <div className="w-100 mt-1">
                              <FormItem
                                validateStatus={formService.getValidationStatus<EndUser>(
                                  model.errors,
                                  nameof(model.username)
                                )}
                                message={model.errors?.username}
                              >
                                <InputText
                                  isMaterial={true}
                                  value={model.username}
                                  placeHolder={"Nhập tên người dùng"}
                                  onChange={handleChangeSimpleField(
                                    nameof(model.username)
                                  )}
                                />
                              </FormItem>
                            </div>
                          </>
                        )}
                      </div>
                      {/* {validAction("update") && (
                        <i
                          className="edit-icon tio-new_message"
                          onClick={handleEdit}
                        ></i>
                      )} */}
                    </div>
                    <div className="user-info__content d-flex flex-column flex-grow-1">
                      <div className="d-flex flex-column">
                        <div className="user-info__label-field user-info__label-field--light">
                          Giới tính
                        </div>
                        {!isEditable ? (
                          <div className="user-info__label-content user-info__label-content--bold mt-1">
                            {model.sex?.name}
                          </div>
                        ) : (
                          <>
                            <FormItem
                              validateStatus={formService.getValidationStatus<EndUser>(
                                model.errors,
                                nameof(model.sex)
                              )}
                              message={model.errors?.sex}
                            >
                              <Select
                                isMaterial={true}
                                model={model.sex}
                                classFilter={SexFilter}
                                getList={endUserRepository.singleListSex}
                                placeHolder={"Nhập giới tính"}
                                onChange={handleChangeObjectField(
                                  nameof(model.sex)
                                )}
                              />
                            </FormItem>
                          </>
                        )}
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <div className="user-info__label-field user-info__label-field--light">
                          Email
                        </div>
                        {!isEditable ? (
                          <div className="user-info__label-content user-info__label-content--bold mt-1">
                            {model.email}
                          </div>
                        ) : (
                          <div className="w-100 mt-1">
                            <FormItem
                              validateStatus={formService.getValidationStatus<EndUser>(
                                model.errors,
                                nameof(model.email)
                              )}
                              message={model.errors?.email}
                            >
                              <InputText
                                isMaterial={true}
                                value={model.email}
                                placeHolder={"Nhập email"}
                                onChange={handleChangeSimpleField(
                                  nameof(model.email)
                                )}
                              />
                            </FormItem>
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <div className="user-info__label-field user-info__label-field--light">
                          Số điện thoại
                        </div>
                        {!isEditable ? (
                          <div className="user-info__label-content user-info__label-content--bold mt-1">
                            {model.phoneNumber}
                          </div>
                        ) : (
                          <div className="w-100 mt-1">
                            <FormItem
                              validateStatus={formService.getValidationStatus<EndUser>(
                                model.errors,
                                nameof(model.phoneNumber)
                              )}
                              message={model.errors?.phoneNumber}
                            >
                              <InputText
                                isMaterial={true}
                                value={model.phoneNumber}
                                placeHolder={"Nhập số điện thoại"}
                                onChange={handleChangeSimpleField(
                                  nameof(model.phoneNumber)
                                )}
                              />
                            </FormItem>
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <div className="user-info__label-field user-info__label-field--light">
                          Ngày sinh
                        </div>
                        {!isEditable ? (
                          <div className="user-info__label-content user-info__label-content--bold mt-1">
                            {model.birthday && formatDate(model.birthday)}
                          </div>
                        ) : (
                          <div className="w-100 mt-1">
                            <FormItem
                              validateStatus={formService.getValidationStatus<EndUser>(
                                model.errors,
                                nameof(model.phoneNumber)
                              )}
                              message={model.errors?.phoneNumber}
                            >
                              <DatePicker
                                isMaterial={true}
                                value={model.birthday}
                                placeholder={"Lọc theo ngày"}
                                onChange={handleChangeSimpleField(nameof(model.birthday))}
                              />
                            </FormItem>
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <div className="user-info__label-field user-info__label-field--light">
                          Tình trạng
                        </div>
                        {model.status && (
                          <div
                            className={`end-user__status end-user__status--${model.status.code.toLowerCase()} mt-1`}
                          >
                            {model.status.name}
                          </div>
                        )}
                      </div>
                    </div>
                    {!isEditable ? (
                      <div className="user-info__footer d-flex flex-column mt-3">
                        {validAction("adminChangePassword") && (
                          <div
                            className="user-info__label-content user-info__label-content--bold"
                            onClick={openForgotPasswordModal}
                          >
                            <span>
                              <i className="tio-password_open"></i> Đổi mật khẩu
                            </span>
                          </div>
                        )}
                        {validAction("lock") && (
                          <div
                            className="user-info__label-content user-info__label-content--bold mt-2"
                            onClick={openLockAccountModal}
                          >
                            <span>
                              <i className="tio-password_open"></i>{" "}
                              {model.statusId === 1
                                ? "Khóa tài khoản"
                                : "Mở tài khoản"}
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="user-info__footer d-flex mt-3 justify-content-center align-items-center">
                        <button
                          className="btn-save mr-2"
                          onClick={handleSaveDetail}
                        >
                          Lưu thay đổi
                        </button>
                        <button
                          className="btn-cancel"
                          onClick={handleCancelDetail}
                        >
                          Hủy
                        </button>
                      </div>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-4">
              <Col className="gutter-row" span={24}>
                <Card title={<span className="card-title">Open Ticket</span>}>
                  <div className="d-flex flex-column ticket__container justify-content-center align-items-center">
                    {(!model.tickets || model.tickets.length === 0) && (
                      <div className="empty-ticket">
                        <img src={emptyTicket} alt="IMG" />
                      </div>
                    )}
                    {model.tickets && model.tickets.length > 0 && (
                      <OpenTicketList tickets={model.tickets} />
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={18}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={24}>
                <div className="table__container w-100">
                  <div className="table__header w-100">
                    <span className="card-title">Danh sách nhà</span>
                    <span className="card-title">Số lượng :{model?.dormitories?.length}</span>
                  </div>
                  <div className="table__content">
                    <Table
                      rowKey={nameof(model.homes[0].id)}
                      columns={dormitoryColumns}
                      pagination={false}
                      dataSource={model.dormitories}
                      rowSelection={null}
                      scroll={{ y: 400, x: "max-content" }}
                    ></Table>
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-4">
              <Col className="gutter-row" span={24}>
                <div className="table__container w-100">
                  <div className="table__header w-100">
                    <span className="card-title">
                      Danh sách Home Controller
                    </span>
                    <span className="card-title">Số lượng :{model?.homeControllers?.length} </span>
                  </div>
                  <div className="table__content">
                    <Table
                      rowKey={nameof(model.homeControllers[0].id)}
                      columns={homeControllerColumns}
                      pagination={false}
                      dataSource={model.homeControllers}
                      rowSelection={null}
                      scroll={{ y: 400, x: "max-content" }}
                    ></Table>
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row mt-4" span={24}>
                <Card
                  title={<span className="card-title">Hành động gần đây</span>}
                >
                  <ActivityList
                    list={getActivitiesDict(model.activityLogs, 2, 3)}
                  />
                  <div className="activation__footer w-100 d-flex justify-content-center align-items-center">
                    <span onClick={openActivityModal}>
                      <i className="tio-history"></i> Xem toàn bộ
                    </span>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <LockAccountModal
        statusId={model.statusId}
        visibleLockAccountModal={visibleLockAccountModal}
        cancelLockAccountModal={cancelLockAccountModal}
        activeAccountModal={activeAccountModal}
        deactiveAccountmodal={deactiveAccountmodal}
      />
      <ForgotPasswordModal
        model={model}
        visibleForgotPasswordModal={visibleForgotPasswordModal}
        cancelForgotPasswordModal={cancelForgotPasswordModal}
        saveForgotPasswordModal={saveForgotPasswordModal}
      />
      <ActivityModal
        model={list}
        loading={loading}
        visibleActivationModal={visibleActivityModal}
        cancelActivationModal={cancelActivityModal}
        onFilter={handleApplyFilter}
      />
      <AppFooter onCancel={handleGoBase}></AppFooter>
    </>
  );
}

export default EndUserPreview;
