import translation from "./activity-log.json";

export enum ActivityEnum {
  // End User
  CREATE_DORMITORY = "activityLog.createDormitory",
  UPDATE_DORMITORY = "activityLog.updateDormitory",
  ADD_DEVICES = "activityLog.addDormitory",
  DELETE_DORMITORY = "activityLog.deleteDormitory",
  CREATE_ROOM = "activityLog.createRoom",
  UPDATE_ROOM = "activityLog.updateRoom",
  DELETE_ROOM = "activityLog.deleteRoom",
  ADD_DEVICE = "activityLog.addDevice",
  DELETE_DEVICE = "activityLog.deleteDevice",
  CREATE_ROUTINE = "activityLog.createRoutine",
  UPDATE_ROUTINE = "activityLog.updateRoutine",
  UPDATE_ROUTINE_STATE = "activityLog.updateRoutineState",
  DELETE_ROUTINE = "activityLog.deleteRoutine",
  CREATE_SCENE_SWITCH = "activityLog.createSceneSwitch",
  UPDATE_SCENE_SWITCH = "activityLog.updateSceneSwitch",
  CONTROL_ALL_DEVICES_ON_OFF = "activityLog.controlAllDevicesState",
  CONTROL_GROUP_ON_OFF = "activityLog.controlGroupDevicesState",
  CONTROL_GROUP_LUMINANCE = "activityLog.controlGroupDimming",
  CONTROL_GROUP_CCT = "activityLog.controlGroupCct",
  CONTROL_GROUP_COLOR = "activityLog.controlGroupColor",
  CONTROL_DEVICE_ON_OFF = "activityLog.controlDeviceState",
  CONTROL_DEVICE_LUMINANCE = "activityLog.controlDeviceLuminance",
  CONTROL_DEVICE_CCT = "activityLog.controlDeviceCct",
  CONTROL_DEVICE_COLOR = "activityLog.controlDeviceColor",
  CREATE_SCENE = "activityLog.createScene",
  UPDATE_SCENE = "activityLog.updateScene",
  DELETE_SCENE = "activityLog.deleteScene",
  SELECT_SCENE = "activityLog.selectScene",
  UPDATE_DEVICE = "activityLog.updateDevice",
  ADD_DEVICE_ROOM = "activityLog.addDeviceRoom",
  REMOVE_DEVICE_ROOM = "activityLog.removeDeviceRoom",
  UPDATE_DEVICE_NAME = "activityLog.updateDeviceName",
  UPDATE_PROFILE = "activityLog.updateProfile",
  UPDATE_VERSION_HC = "activityLog.updateVersionHc",
  UPDATE_VERSION_APP = "activityLog.updateVersionApp",
  CREATE_ROUTINE_DEVICE = "activityLog.createRoutineDevice",
  UPDATE_ROUTINE_DEVICE = "activityLog.updateRoutineDevice",
  UPDATE_ROUTINE_DEVICE_STATE = "activityLog.updateRoutineDeviceState",
  DELETE_ROUTINE_DEVICE = "activityLog.deleteRoutineDevice",
  CREATE_SMART_DEVICE_SCENE_SETUP = "activityLog.createSmartDeviceSceneSetup",
  UPDATE_SMART_DEVICE_SCENE_SETUP = "activityLog.updateSmartDeviceSceneSetup",
  DELETE_SMART_DEVICE_SCENE_SETUP = "activityLog.deleteSmartDeviceSceneSetup",


  // App User
  CREATE_TICKET_CONVERSAION = "activityLog.createTicketConversation",
  LOGIN = "activityLog.login",
  UPDATE_ROLE = "activityLog.updateRole",
  CHANGE_PASSWORD = "activityLog.changePassword",
  ADD_SUPPORTER = "activityLog.addSupporter",
  LOCK_END_USER = "activityLog.lockEndUser",
  UNLOCK_END_USER = "activityLog.unlockEndUser",
  LOCK_APP_USER = "activityLog.lockAppUser",
  UNLOCK_APP_USER = "activityLog.unlockAppUser",
  DELETE_APP_USER = "activityLog.deleteAppUser",
  CREATE_APP_USER = "activityLog.createAppUser",
  UPDATE_APP_USER = "activityLog.updateAppUser",
}

export interface ActivityParams {
  username?: string;

  deviceId?: string;

  deviceName?: string;

  dormitoryId?: string;

  dormitoryName?: string;

  roomId?: string;

  roomName?: string;

  onOff?: string;

  color?: string;

  luminance?: number;

  cct?: number;

  devicesCount?: number;

  defaultSceneCount?: number;

  routineName?: string;

  routineType?: string;

  sceneName?: string;

  routineState?: string;

  buttonSwitch?: number;

  category?: number;

  ticketCode?: number;

  endUser?: string;

  appUser?: string;

  roles?: string[];

  duration?: string;

  deviceOldName?: string;

  versionHC?: string;
}

export interface ActivityLog {
  message: string;

  params?: ActivityParams;
}

function translate(key: string): string {
  return translation[key];
}

export function getActivityLog(
  state: string,
  params: ActivityParams
): ActivityLog {
  switch (state) {
    // End User
    case ActivityEnum.CREATE_DORMITORY:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
        },
      };

    case ActivityEnum.UPDATE_DORMITORY:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
        },
      };

    case ActivityEnum.DELETE_DORMITORY:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
        },
      };

    case ActivityEnum.ADD_DEVICES:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          devicesCount: params.devicesCount,
        },
      };

    case ActivityEnum.CREATE_ROOM:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          roomName: params.roomName,
          devicesCount: params.devicesCount,
          defaultSceneCount: 12,
        },
      };

    case ActivityEnum.UPDATE_ROOM:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          roomName: params.roomName,
        },
      };

    case ActivityEnum.DELETE_ROOM:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          roomName: params.roomName,
        },
      };

    case ActivityEnum.ADD_DEVICE:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.UPDATE_DEVICE_NAME:
      return {
        message: translate('activityLog.updateDeviceName'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          deviceName: params.deviceName,
          deviceOldName: params.deviceOldName,
        },
      };

    case ActivityEnum.ADD_DEVICE_ROOM:
      return {
        message: translate('activityLog.addDeviceRoom'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          deviceName: params.deviceName,
          roomName: params.roomName,
        },
      };

    case ActivityEnum.REMOVE_DEVICE_ROOM:
      return {
        message: translate('activityLog.removeDeviceRoom'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          deviceName: params.deviceName,
          roomName: params.roomName,
        },
      };



    case ActivityEnum.DELETE_DEVICE:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.CREATE_ROUTINE:
      return {
        message: translate(state),
        params: {
          username: params.username,
          routineName: params.routineName,
          routineType: params.routineType,
        },
      };

    case ActivityEnum.UPDATE_ROUTINE:
      return {
        message: translate(state),
        params: {
          username: params.username,
          routineName: params.routineName,
          routineType: params.routineType,
        },
      };

    case ActivityEnum.UPDATE_ROUTINE_STATE:
      return {
        message: translate(state),
        params: {
          username: params.username,
          routineState: params.routineState,
          routineType: params.routineType,
          routineName: params.routineName,
        },
      };

    case ActivityEnum.DELETE_ROUTINE:
      return {
        message: translate(state),
        params: {
          username: params.username,
          routineName: params.routineName,
          routineType: params.routineType,
        },
      };

    case ActivityEnum.CREATE_SCENE_SWITCH:
      return {
        message: translate(state),
        params: {
          username: params.username,
          deviceName: params.deviceName,
          dormitoryName: params.dormitoryName,
          buttonSwitch: params.buttonSwitch,
        },
      };

    case ActivityEnum.UPDATE_SCENE_SWITCH:
      return {
        message: translate(state),
        params: {
          username: params.username,
          deviceName: params.deviceName,
          dormitoryName: params.dormitoryName,
          buttonSwitch: params.buttonSwitch,
        },
      };

    case ActivityEnum.CONTROL_ALL_DEVICES_ON_OFF:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          onOff: params.onOff,
        },
      };

    case ActivityEnum.CONTROL_GROUP_ON_OFF:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          onOff: params.onOff,
          roomName: params.roomName,
          category: params.category,
        },
      };

    case ActivityEnum.CONTROL_GROUP_LUMINANCE:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          roomName: params.roomName,
          luminance: params.luminance,
          category: params.category,
        },
      };

    case ActivityEnum.CONTROL_GROUP_CCT:
      return {
        message: translate(state),
        params: {
          username: params.username,
          cct: params.cct,
          dormitoryName: params.dormitoryName,
          roomName: params.roomName,
          category: params.category,
        },
      };

    case ActivityEnum.CONTROL_GROUP_COLOR:
      return {
        message: translate(state),
        params: {
          username: params.username,
          color: params.color,
          dormitoryName: params.dormitoryName,
          roomName: params.roomName,
          category: params.category,
        },
      };

    case ActivityEnum.CONTROL_DEVICE_ON_OFF:
      return {
        message: translate(state),
        params: {
          username: params.username,
          onOff: params.onOff,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.CONTROL_DEVICE_LUMINANCE:
      return {
        message: translate(state),
        params: {
          username: params.username,
          luminance: params.luminance,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.CONTROL_DEVICE_CCT:
      return {
        message: translate(state),
        params: {
          username: params.username,
          cct: params.cct,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.CONTROL_DEVICE_COLOR:
      return {
        message: translate(state),
        params: {
          username: params.username,
          color: params.color,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.CREATE_SCENE:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          roomName: params.roomName,
          sceneName: params.sceneName,
        },
      };

    case ActivityEnum.UPDATE_SCENE:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          roomName: params.roomName,
          sceneName: params.sceneName,
        },
      };

    case ActivityEnum.DELETE_SCENE:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          roomName: params.roomName,
          sceneName: params.sceneName,
        },
      };

    case ActivityEnum.SELECT_SCENE:
      return {
        message: translate(state),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          roomName: params.roomName,
          sceneName: params.sceneName,
        },
      };

    case ActivityEnum.UPDATE_DEVICE:
      return {
        message: translate('activityLog.updateDevice'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.UPDATE_PROFILE:
      return {
        message: translate('activityLog.updateProfile'),
        params: {
          username: params.username,
        },
      };

    case ActivityEnum.UPDATE_VERSION_HC:
      return {
        message: translate('activityLog.updateVersionHc'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          deviceName: params.deviceName,
          versionHC: params.versionHC,
        },
      };

    case ActivityEnum.UPDATE_VERSION_APP:
      return {
        message: translate('activityLog.updateVersionApp'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
        },
      };

    case ActivityEnum.CREATE_ROUTINE_DEVICE:
      return {
        message: translate('activityLog.addRoutineDevice'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          routineName: params.routineName,
          routineType: params.routineType,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.UPDATE_ROUTINE_DEVICE:
      return {
        message: translate('activityLog.updateRoutineDevice'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          routineName: params.routineName,
          routineType: params.routineType,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.UPDATE_ROUTINE_DEVICE_STATE:
      return {
        message: translate('activityLog.updateRoutineDeviceState'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          routineState: params.routineState,
          routineType: params.routineType,
          routineName: params.routineName,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.DELETE_ROUTINE_DEVICE:
      return {
        message: translate('activityLog.deleteRoutineDevice'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          routineName: params.routineName,
          routineType: params.routineType,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.CREATE_SMART_DEVICE_SCENE_SETUP:
      return {
        message: translate('activityLog.addSmartDeviceSceneSetup'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.UPDATE_SMART_DEVICE_SCENE_SETUP:
      return {
        message: translate('activityLog.updateSmartDeviceSceneSetup'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          deviceName: params.deviceName,
        },
      };

    case ActivityEnum.DELETE_SMART_DEVICE_SCENE_SETUP:
      return {
        message: translate('activityLog.deleteSmartDeviceSceneSetup'),
        params: {
          username: params.username,
          dormitoryName: params.dormitoryName,
          deviceName: params.deviceName,
        },
      };


    // App User
    case ActivityEnum.CREATE_TICKET_CONVERSAION:
      return {
        message: translate(state),
        params: {
          username: params.username,
          ticketCode: params.ticketCode,
        },
      };

    case ActivityEnum.LOGIN:
      return {
        message: translate(state),
        params: {
          username: params.username,
        },
      };

    case ActivityEnum.UPDATE_ROLE:
      return {
        message: translate("activityLog.updateRole"),
        params: {
          username: params.username,
          appUser: params.appUser,
          roles: params.roles,
        },
      };

    case ActivityEnum.ADD_SUPPORTER:
      return {
        message: translate(state),
        params: {
          username: params.username,
          endUser: params.endUser,
          dormitoryName: params.dormitoryName,
          duration: params.duration,
        },
      };

    case ActivityEnum.LOCK_END_USER:
      return {
        message: translate(state),
        params: {
          username: params.username,
          endUser: params.endUser,
        },
      };

    case ActivityEnum.UNLOCK_END_USER:
      return {
        message: translate(state),
        params: {
          username: params.username,
          endUser: params.endUser,
        },
      };

    case ActivityEnum.CHANGE_PASSWORD:
      return {
        message: translate(state),
        params: {
          username: params.username,
        },
      };

    case ActivityEnum.LOCK_APP_USER:
      return {
        message: translate(state),
        params: {
          username: params.username,
          appUser: params.appUser,
        },
      };

    case ActivityEnum.UNLOCK_APP_USER:
      return {
        message: translate(state),
        params: {
          username: params.username,
          appUser: params.appUser,
        },
      };

    case ActivityEnum.DELETE_APP_USER:
      return {
        message: translate(state),
        params: {
          username: params.username,
          appUser: params.appUser,
        },
      };

    case ActivityEnum.CREATE_APP_USER:
      return {
        message: translate(state),
        params: {
          username: params.username,
          appUser: params.appUser,
        },
      };

    case ActivityEnum.UPDATE_APP_USER:
      return {
        message: translate(state),
        params: {
          username: params.username,
          appUser: params.appUser,
        },
      };

    default:
      return {
        message: translate(state),
        params: {
          username: params.username,
        },
      };
  }
}

export function parseTemplate(template: string, map: Object) {
  const fallback = "";
  return template.replace(/{{.+?}}/g, (match) => {
    const key = match.substr(2, match.length - 4).trim();
    if (!Array.isArray(map[key])) return map[key] || fallback;
    return map[key].join(", ");
  });
}
