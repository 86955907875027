import { StringFilter  } from '@react3l/advanced-filters';
import { IdFilter  } from '@react3l/advanced-filters';
import { ModelFilter } from '@react3l/react3l/core';

export class SexFilter extends ModelFilter  {
  public id?: IdFilter = new IdFilter();
  public code?: StringFilter = new StringFilter();
  public name?: StringFilter = new StringFilter();
}

export class EndUserFilter extends ModelFilter  {
  public id?: IdFilter = new IdFilter();
  public username?: StringFilter = new StringFilter();
  public displayName?: StringFilter = new StringFilter();
  public email?: StringFilter = new StringFilter();
  public phoneNumber?: StringFilter = new StringFilter();
  public sexId?: IdFilter = new IdFilter();
  public statusId?: IdFilter = new IdFilter();
}
