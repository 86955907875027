export const API_APP_USER_PREFIX: string = "rpc/iot-cloud/app-user";
export const API_CATEGORY_PREFIX: string = "rpc/iot-cloud/category";
export const API_CATEGORY_DEVICE_ATTRIBUTE_MAPPING_PREFIX: string =
  "rpc/iot-cloud/category-device-attribute-mapping";
export const API_CERTIFICATION_PREFIX: string = 'rpc/iot-cloud/certification';
export const API_COMPARISON_OPERATOR_PREFIX: string =
  "rpc/iot-cloud/comparison-operator";
export const API_DASHBOARD_PREFIX: string =
  "rpc/iot-cloud/dashboards/information";
export const API_DATA_TYPE_PREFIX: string = "rpc/iot-cloud/data-type";
export const API_DATA_VALUE_PREFIX: string = "rpc/iot-cloud/data-value";
export const API_DEVICE_ATTRIBUTE_COMPARISON_OPERATOR_MAPPING_PREFIX: string =
  "rpc/iot-cloud/device-attribute-comparison-operator-mapping";
export const API_DEVICE_ATTRIBUTE_PREFIX: string =
  "rpc/iot-cloud/device-attribute";
export const API_DEVICE_TYPE_PREFIX: string = "rpc/iot-cloud/device-type";
export const API_DORMITORY_PREFIX: string = "rpc/iot-cloud/dormitory";
export const API_ROOM_PREFIX: string = "rpc/iot-cloud/room";
export const API_END_USER_PREFIX: string = "rpc/iot-cloud/end-user";
export const API_END_USER_PROFILE_PREFIX: string =
  "rpc/iot-cloud/end-user-profile";
export const API_END_USER_ROLE_PREFIX: string = "rpc/iot-cloud/end-user-role";
export const API_END_USER_SESSION_PREFIX: string =
  "rpc/iot-cloud/end-user-session";
export const API_EVENT_TRIGGER_TYPE_PREFIX: string =
  "rpc/iot-cloud/event-trigger-type";
export const API_TICKET_PREFIX: string = "rpc/iot-cloud/ticket";
export const API_TICKET_CONVERSATION_PREFIX: string =
  "rpc/iot-cloud/ticket-conversation";
export const API_HOME_CONTROLLER_PREFIX: string =
  "rpc/iot-cloud/home-controller";
export const API_ICON_PREFIX: string = "rpc/iot-cloud/icon";
export const API_ICON_TYPE_PREFIX: string = "rpc/iot-cloud/icon-type";
export const API_LOGICAL_OPERATOR_PREFIX: string =
  "rpc/iot-cloud/logical-operator";
export const API_MEMBER_PREFIX: string = "rpc/iot-cloud/member";
export const API_PERMISSION_PREFIX: string = "rpc/iot-cloud/permission";
export const API_RULE_ACTION_TYPE_PREFIX: string =
  "rpc/iot-cloud/rule-action-type";

export const API_FIRMWARE_PREFIX: string = "rpc/iot-cloud/firmware";

export const API_ACTIVITY_LOG_PREFIX: string = "rpc/iot-cloud/activity-log";

export const API_RESOURCE_PREFIX: string = "rpc/iot-cloud/resource";


// device
export const API_DEVICE_PREFIX: string =
  "rpc/iot-cloud/device";