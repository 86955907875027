import React, { useState, useEffect } from "react";
import { Observable } from "rxjs";
import { EndUser } from "models/EndUser";
import { commonService } from "@react3l/react3l/services";
import { List } from "antd";
import { END_USER_MASTER_ROUTE } from "config/route-consts";
import { useHistory } from "react-router";
import userInactive from "./../../../assets/images/user/user-inactive.png";
import moment from "moment";

interface RecentEndUsersProps {
  getData: () => Observable<EndUser[]>;
}

export default function RecentEndUsers(props: RecentEndUsersProps) {
  const { getData } = props;

  const [model, setModel] = useState<EndUser[]>([]);

  const [subscription] = commonService.useSubscription();

  const history = useHistory();

  const handleGoEndUser = (_event: any) => {
    history.push(END_USER_MASTER_ROUTE);
  };

  useEffect(() => {
    subscription.add(getData().subscribe((data: EndUser[]) => {
      setModel(data);
    }));
  }, [getData, subscription]);
  return (
    <>
      <div className="pt-4 dashboard-master__end-user">
        <div className="px-4 py-2">
          <List
            itemLayout="horizontal"
            dataSource={model}
            bordered={false}
            renderItem={(item: EndUser) => (
              <div className="d-flex align-items-center mb-4">
                <img
                  src={item?.avatar ?? userInactive}
                  alt="avatar"
                />
                <div className="d-flex flex-column ml-3">
                  <h2 className="m-0">{item.displayName}</h2>
                  <p className="m-0">Ngày tạo: {moment(item.createdAt).format('DD/MM/YYYY')}</p>
                </div>
              </div>
            )}
          />
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-center my-3">
          <span onClick={handleGoEndUser}>Xem thêm</span>
        </div>
      </div>
    </>
  );
}
