/* begin general import */
import React from "react";
import { Model } from "@react3l/react3l/core/model";
import { Descriptions } from "antd";
import Modal from "components/Utility/Modal/Modal";
import { TFunction } from "i18next";
import moment from "moment";
/* end general import */
/* begin individual import */
import { Certification } from "models/Certification";
/* end individual import */
interface CertificationPreviewProps<T extends Model> {
  previewModel?: T;
  isOpenPreview?: boolean;
  isLoadingPreview?: boolean;
  handleClosePreview?: () => void;
  handleGoDetail?: (id: number) => () => void;
  translate?: TFunction;
}
function CertificationPreview(props: CertificationPreviewProps<Certification>) {
  const {
    previewModel,
    isOpenPreview,
    isLoadingPreview,
    handleClosePreview,
    handleGoDetail,
    translate,
  } = props;
  return (
    <>
      <Modal
        title={null}
        visible={isOpenPreview}
        handleCancel={handleClosePreview}
        width={1200}
        visibleFooter={false}
      >
        {isLoadingPreview ? (
          <div className="loading-block">
            <img src="/assets/images/spinner.svg" alt="Loading..." />
          </div>
        ) : (
            <div className="preview__containter">
              <div className="preview__left-side">
                <div className="preview__header">
                  <div className="preview__vertical-bar"></div>
                  <div className="preview__header-info">
                    <div className="preview__header-text">
                      <span className="preview__header-title">
                        {previewModel.name}
                      </span>
                      <span className="preview__header-date">
                        {translate("certifications.createdAt")}{" "}
                        {previewModel.createdAt
                          ? moment(previewModel.createdAt).format("DD/MM/YYYY")
                          : null}
                      </span>
                    </div>
                    <button
                      className="btn gradient-btn-icon ant-tooltip-open"
                      onClick={handleGoDetail(previewModel.id)}
                    >
                      <i className="tio-edit"></i>
                    </button>
                  </div>
                </div>
                <div className="preview__body">
                  <div className="preview__content">
                    <Descriptions title={previewModel.name} column={2}>
                      <Descriptions.Item label={translate("certifications.validFrom")}>
                        <span className="gradient-text">
                          {previewModel.validFrom
                            ? moment(previewModel.validFrom).format("DD/MM/YYYY")
                            : null}
                        </span>
                      </Descriptions.Item>
                      <Descriptions.Item label={translate("certifications.validUntil")}>
                        <span className="gradient-text">
                          {previewModel.validUntil
                            ? moment(previewModel.validUntil).format("DD/MM/YYYY")
                            : null}
                        </span>
                      </Descriptions.Item>
                      <Descriptions.Item label={translate("certifications.name")}>
                        <span className="gradient-text">{previewModel.name}</span>
                      </Descriptions.Item>
                      <Descriptions.Item label={"File"}>
                        <div className="file-name d-flex align-items-center mt-0">
                          <i className="tio-attachment_diagonal mr-3"></i>{" "}
                          <a
                            href={previewModel.url}
                            download={"Certification_" + previewModel.name}
                          >
                            {"Certification_" + previewModel.name}
                          </a>
                        </div>
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
                <div className="preview__footer"></div>
              </div>
            </div>
          )}
      </Modal>
    </>
  );
}
export default CertificationPreview;

