export class HereMapService {
  map: H.Map; // map instance
  marker: H.map.Marker;

  constructor(mapContainer: HTMLElement) {
    if (mapContainer) {
      const platform = new window.H.service.Platform({
        apikey: process.env.REACT_APP_HERE_APIKEY, // test key
      });

      const defaultLayers = platform.createDefaultLayers();
      this.map = new window.H.Map(
        mapContainer,
        defaultLayers.vector.normal.map,
        {
          zoom: 16,
          center: {
            lat: 43.642567,
            lng: -79.387054,
          },
          pixelRatio: window.devicePixelRatio || 1,
        }
      );

      this.marker = new H.map.Marker({
        lat: 43.642567,
        lng: -79.387054,
      });

      this.map.addObject(this.marker);

      this.map.getViewModel().setLookAtData({
        position: {
          lat: 43.642567,
          lng: -79.387054,
        },
      });
    }
  } // initiate mapService with map instance
}
