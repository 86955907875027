import { AxiosResponse } from "axios";
import { Repository } from "@react3l/react3l/core";
import { kebabCase, url } from "@react3l/react3l/helpers";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import nameof from "ts-nameof.macro";

import { API_TICKET_CONVERSATION_PREFIX } from "config/api-consts";
import {
  TicketConversation,
  TicketConversationFilter,
} from "models/TicketConversation";
import { Ticket, TicketFilter } from "models/Ticket";
import { EndUser, EndUserFilter } from "models/EndUser";
import { AppUser, AppUserFilter } from "models/AppUser";
import { TicketConversationFile } from "models/TicketConversationFile";

export class TicketConversationRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = url(BASE_API_URL, API_TICKET_CONVERSATION_PREFIX);
  }

  public count = (
    ticketFilter?: TicketConversationFilter
  ): Observable<number> => {
    return this.httpObservable
      .post<number>(kebabCase(nameof(this.count)), ticketFilter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public list = (
    ticketFilter?: TicketConversationFilter
  ): Observable<TicketConversation[]> => {
    return this.httpObservable
      .post<TicketConversation[]>(kebabCase(nameof(this.list)), ticketFilter)
      .pipe(
        map((response: AxiosResponse<TicketConversation[]>) => response.data)
      );
  };

  public get = (id: number | string): Observable<TicketConversation> => {
    return this.httpObservable
      .post<TicketConversation>(kebabCase(nameof(this.get)), { id })
      .pipe(
        map((response: AxiosResponse<TicketConversation>) => response.data)
      );
  };

  public getTicket = (
    id: number | string
  ): Observable<TicketConversation[]> => {
    return this.httpObservable
      .post<TicketConversation[]>(kebabCase(nameof(this.getTicket)), {
        ticketId: id,
      })
      .pipe(
        map((response: AxiosResponse<TicketConversation[]>) => response.data)
      );
  };

  public create = (
    ticket: TicketConversation
  ): Observable<TicketConversation> => {
    return this.httpObservable
      .post<TicketConversation>(kebabCase(nameof(this.create)), ticket)
      .pipe(
        map((response: AxiosResponse<TicketConversation>) => response.data)
      );
  };

  public multiUpload = (
    files: Blob[]
  ): Observable<TicketConversationFile[]> => {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    return this.httpObservable
      .post<any>(kebabCase(nameof(this.multiUpload)), formData)
      .pipe(
        map(
          (response: AxiosResponse<TicketConversationFile[]>) => response.data
        )
      );
  };

  public update = (
    ticket: TicketConversation
  ): Observable<TicketConversation> => {
    return this.httpObservable
      .post<TicketConversation>(kebabCase(nameof(this.update)), ticket)
      .pipe(
        map((response: AxiosResponse<TicketConversation>) => response.data)
      );
  };

  public delete = (
    ticket: TicketConversation
  ): Observable<TicketConversation> => {
    return this.httpObservable
      .post<TicketConversation>(kebabCase(nameof(this.delete)), ticket)
      .pipe(
        map((response: AxiosResponse<TicketConversation>) => response.data)
      );
  };

  public bulkDelete = (idList: number[] | string[]): Observable<void> => {
    return this.httpObservable
      .post(kebabCase(nameof(this.bulkDelete)), idList)
      .pipe(map((response: AxiosResponse<void>) => response.data));
  };

  public singleListTicket = (
    ticketFilter: TicketFilter
  ): Observable<Ticket[]> => {
    return this.httpObservable
      .post<Ticket[]>(kebabCase(nameof(this.singleListTicket)), ticketFilter)
      .pipe(map((response: AxiosResponse<Ticket[]>) => response.data));
  };

  public singleListEndUser = (
    endUserFilter: EndUserFilter
  ): Observable<EndUser[]> => {
    return this.httpObservable
      .post<EndUser[]>(kebabCase(nameof(this.singleListEndUser)), endUserFilter)
      .pipe(map((response: AxiosResponse<EndUser[]>) => response.data));
  };

  public singleListAppUser = (
    endUserFilter: AppUserFilter
  ): Observable<AppUser[]> => {
    return this.httpObservable
      .post<AppUser[]>(kebabCase(nameof(this.singleListAppUser)), endUserFilter)
      .pipe(map((response: AxiosResponse<AppUser[]>) => response.data));
  };

  public filterListAppUser = (): Observable<AppUser[]> => {
    return this.httpObservable
      .post<AppUser[]>(
        kebabCase(nameof(this.filterListAppUser)),
        new AppUserFilter()
      )
      .pipe(map((response: AxiosResponse<AppUser[]>) => response.data));
  };

  public filterListEndUser = (): Observable<EndUser[]> => {
    return this.httpObservable
      .post<EndUser[]>(
        kebabCase(nameof(this.filterListEndUser)),
        new EndUserFilter()
      )
      .pipe(map((response: AxiosResponse<EndUser[]>) => response.data));
  };

  public filterListTicket = (): Observable<Ticket[]> => {
    return this.httpObservable
      .post<Ticket[]>(
        kebabCase(nameof(this.filterListTicket)),
        new TicketFilter()
      )
      .pipe(map((response: AxiosResponse<Ticket[]>) => response.data));
  };
}

export const ticketConversationRepository = new TicketConversationRepository();
