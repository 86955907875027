/* begin general import */
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import nameof from "ts-nameof.macro";
import { Card, Col, Row, Tabs, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import Modal, { ModalProps } from "components/Utility/Modal/Modal";
import FormItem from "components/Utility/FormItem/FormItem";
import { FormDetailAction, formService } from "services/form-service";
import { ASSETS_IMAGE } from "config/consts";
/* end general import */
/* begin individual import */
import InputText from "components/Utility/Input/InputText/InputText";
import InputNumber from "components/Utility/Input/InputNumber/InputNumber";
import { Firmware } from "models/Firmware";
import { finalize } from "rxjs/operators";
import { useDropzone } from "react-dropzone";
import { firmwareRepository } from "repositories/firmware-repository";
import "./FirmwareDetailModal.scss";
import TextArea from "components/Utility/TextArea/TextArea";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#3F8CFF" }} spin />
);
/* end individual import */
const { TabPane } = Tabs;
interface FirmwareDetailModalProps extends ModalProps {
  model: Firmware;
  isDetail: boolean;
  onChangeSimpleField: (fieldName: string) => (fieldValue: any) => void;
  onChangeObjectField?: (
    fieldName: string
  ) => (fieldIdValue: number, fieldValue?: any) => void;
  onChangeTreeObjectField?: (
    fieldName: string,
    callback?: (id: number) => void
  ) => (list: any[]) => void;
  dispatchModel?: React.Dispatch<FormDetailAction<Firmware>>;
  loading?: boolean;
  handleUpdateNewModel?: (model: Firmware) => void;
}
function FirmwareDetailModal(props: FirmwareDetailModalProps) {
  const [translate] = useTranslation();
  const { model, onChangeSimpleField, loading, handleUpdateNewModel } = props;
  const [isLoadingFile, setIsLoadingFile] = React.useState<boolean>(false);

  const handleChangeFile = React.useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        setIsLoadingFile(true);
        const file = files[files.length - 1];
        let check = false;
        if (file.size > 1000000000) {
          notification.error({
            message: `Vượt quá dung lượng cho phép`,
            description: "File tải lên dung lượng phải dưới 1GB",
            placement: "bottomRight"
          });
          check = true;
        }
        if (check) return null;
        firmwareRepository
          .upload(file)
          .pipe(finalize(() => setIsLoadingFile(false)))
          .subscribe(
            (res: any) => {
              const newModel = { ...model };
              newModel.url = res.path;
              handleUpdateNewModel(newModel);
              notification.success({
                message: "Tải file lên thành công",
                placement: "bottomRight"
              });
            },
            err => { }
          );
      }
    },
    [handleUpdateNewModel, model]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleChangeFile
  });

  const handleDeleteFile = React.useCallback(() => {
    const newModel = { ...model };
    newModel.url = undefined;
    handleUpdateNewModel(newModel);
  }, [handleUpdateNewModel, model]);

  return (
    <Modal {...props} width={1200}>
      {loading ? (
        <div className="loading-block">
          <img src={ASSETS_IMAGE + "/spinner.svg"} alt="Loading..." />
        </div>
      ) : (
          <div className="page page__detail">
            <div className="page__modal-header w-100">
              <Row className="d-flex">
                <Col lg={24}>
                  {model?.id ? (
                    <div className="page__title mr-1">
                      {translate("firmwares.detail.title")}
                    </div>
                  ) : (
                      translate("general.actions.create")
                    )}
                </Col>
              </Row>
            </div>
            <div className="w-100 page__detail-tabs">
              <Row className="d-flex">
                <Col lg={16} className="mx-auto my-1">
                  <Card>
                    <Tabs defaultActiveKey="1">
                      <TabPane
                        tab={translate("general.detail.generalInformation")}
                        key="1"
                      >
                        <Row>
                          <Col lg={12} className="pr-3">
                            <FormItem
                              label={translate("firmwares.name")}
                              validateStatus={formService.getValidationStatus<
                                Firmware
                              >(model.errors, nameof(model.name))}
                              message={model.errors?.name}
                            >
                              <InputText
                                isMaterial={true}
                                value={model.name}
                                placeHolder={translate(
                                  "firmwares.placeholder.name"
                                )}
                                className={"tio-account_square_outlined"}
                                onChange={onChangeSimpleField(nameof(model.name))}
                              />
                            </FormItem>
                          </Col>
                          <Col lg={12} className="pr-3">
                            <FormItem
                              label={translate("firmwares.version")}
                              validateStatus={formService.getValidationStatus<
                                Firmware
                              >(model.errors, nameof(model.version))}
                              message={model.errors?.version}
                            >
                              <InputText
                                isMaterial={true}
                                value={model.version}
                                placeHolder={translate(
                                  "firmwares.placeholder.version"
                                )}
                                className={"tio-account_square_outlined"}
                                onChange={onChangeSimpleField(
                                  nameof(model.version)
                                )}
                              />
                            </FormItem>
                          </Col>
                          {/* <Col lg={12} className="pr-3">
                          <FormItem
                            label={translate("firmwares.url")}
                            validateStatus={formService.getValidationStatus<
                              Firmware
                            >(model.errors, nameof(model.url))}
                            message={model.errors?.url}
                          >
                            <InputText
                              isMaterial={true}
                              value={model.url}
                              placeHolder={translate(
                                "firmwares.placeholder.url"
                              )}
                              className={"tio-account_square_outlined"}
                              onChange={onChangeSimpleField(nameof(model.url))}
                            />
                          </FormItem>
                        </Col> */}
                          <Col lg={12} className="pr-3">
                            <FormItem
                              label={translate("firmwares.checksum")}
                              validateStatus={formService.getValidationStatus<
                                Firmware
                              >(model.errors, nameof(model.checksum))}
                              message={model.errors?.checksum}
                            >
                              <InputText
                                isMaterial={true}
                                value={model.checksum}
                                placeHolder={translate(
                                  "firmwares.placeholder.checksum"
                                )}
                                className={"tio-account_square_outlined"}
                                onChange={onChangeSimpleField(
                                  nameof(model.checksum)
                                )}
                              />
                            </FormItem>
                          </Col>
                          <Col lg={12} className="pr-3">
                            <FormItem
                              label={translate("firmwares.placeholder.homeControllerTypeId")}
                              validateStatus={formService.getValidationStatus<
                                Firmware
                              >(model.errors, nameof(model.homeControllerTypeId))}
                              message={model.errors?.homeControllerTypeId}
                            >
                              <InputNumber
                                isMaterial={true}
                                value={model.homeControllerTypeId}
                                className={"tio-account_square_outlined"}
                                onChange={
                                  onChangeSimpleField(nameof(model.homeControllerTypeId))
                                }
                              />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          {!model.url && (
                            <Col lg={12} className="pr-3">
                              <FormItem
                                label={"File"}
                                validateStatus={formService.getValidationStatus<
                                  Firmware
                                >(model.errors, nameof(model.url))}
                                message={model.errors?.url}
                              >
                                {true && (
                                  <div
                                    className="file-modal__content"
                                    {...getRootProps()}
                                  >
                                    {!isLoadingFile ? (
                                      model.url ? (
                                        <span>
                                          Kéo, thả hoặc{" "}
                                          <span className="content--link">
                                            Thay đổi file
                                        </span>
                                        </span>
                                      ) : (
                                          <span>
                                            Kéo, thả hoặc{" "}
                                            <span className="content--link">
                                              Tải lên
                                        </span>
                                          </span>
                                        )
                                    ) : (
                                        <Spin size="large" indicator={antIcon} />
                                      )}
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      {...getInputProps()}
                                      multiple={false}
                                    />
                                  </div>
                                )}
                              </FormItem>
                            </Col>
                          )}
                          {model.url && (
                            <Col lg={12} className="pr-3">
                              <FormItem
                                label={"File"}
                                validateStatus={formService.getValidationStatus<
                                  Firmware
                                >(model.errors, nameof(model.url))}
                                message={model.errors?.url}
                              >
                                <div className="file-name d-flex align-items-center mt-2">
                                  <i className="tio-attachment_diagonal mr-3"></i>{" "}
                                  <a
                                    href={model.url}
                                    download={"Firmware_" + model.name}
                                  >
                                    {"Firmware_" + model.name}
                                  </a>
                                  <i
                                    className="tio-clear ml-4 mr-1 cursor-pointer"
                                    onClick={() => handleDeleteFile()}
                                  ></i>
                                </div>
                              </FormItem>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col lg={24}>
                            <FormItem
                              label={"Mô tả"}
                              validateStatus={formService.getValidationStatus<
                                Firmware
                              >(model.errors, nameof(model.description))}
                              message={model.errors?.description}
                            >
                              <TextArea
                                isMaterial={true}
                                rows={5}
                                value={model.description}
                                placeHolder={translate(
                                  "firmwares.placeholder.description"
                                )}
                                onChange={onChangeSimpleField(
                                  nameof(model.description)
                                )}
                              />
                            </FormItem>
                          </Col>
                        </Row>
                      </TabPane>
                    </Tabs>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )}
    </Modal>
  );
}
export default FirmwareDetailModal;
