import React from "react";
import { Dormitory } from "models/Dormitory";
import { queryStringService } from "services/query-string-service";
import { Subscription, Observable } from "rxjs";
import { useHistory } from "react-router";
import { END_USER_DETAIL_ROUTE } from "config/route-consts";

export default function useDormitoryDetail(
  getDormitoryDetail: (id: number) => Observable<Dormitory>
) {
  const [model, setModel] = React.useState<Dormitory>(new Dormitory());

  const history = useHistory();

  const { id } = queryStringService.useGetQueryString();

  const handleGoEndUser = React.useCallback(
    (id: string | number) => (_event: any) => {
      if (id) {
        history.push(`${END_USER_DETAIL_ROUTE}?id=${id}`);
      }
    },
    [history]
  );

  React.useEffect(() => {
    const subscription: Subscription = new Subscription();
    if (id) {
      subscription.add(
        getDormitoryDetail(id).subscribe((res: Dormitory) => {
          setModel(res);
        })
      );
    }

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [getDormitoryDetail, id]);

  return { model, setModel, handleGoEndUser };
}
