import React, { useState, useEffect } from "react";
import { Observable } from "rxjs";
import { Ticket } from "models/Ticket";
import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { Moment } from "moment";
import { formatDateTime } from "helpers/date-time";
import { TicketStatus } from "models/TicketStatus";
import { commonService } from "@react3l/react3l/services";
import { TICKET_MASTER_ROUTE } from "config/route-consts";
import { useHistory } from "react-router";

interface RecentTicketsProps {
  getData: () => Observable<Ticket[]>;
}

const ticketColumns: ColumnProps<Ticket>[] = [
  {
    title: <div className="gradient-text text-upper">Mã Ticket</div>,
    key: "code",
    dataIndex: "code",
    width: 250,
    render(code: string) {
      return (
        <div className="ant-cell-master__container">
          <div>{code}</div>
        </div>
      );
    },
  },

  {
    title: <div className="gradient-text text-upper">Date & Time</div>,
    key: "createdAt",
    dataIndex: "createdAt",
    width: 300,
    render(time: Moment) {
      return <div>{formatDateTime(time)}</div>;
    },
  },

  {
    title: <div className="gradient-text text-upper">Trạng thái</div>,
    key: "ticketStatus",
    dataIndex: "ticketStatus",
    width: 200,
    render(ticketStatus: TicketStatus) {
      return (
        <div
          className={`ticket__status ticket__status--${ticketStatus.code.toLowerCase()}`}
        >
          {ticketStatus?.name}
        </div>
      );
    },
  },
];

export default function RecentTickets(props: RecentTicketsProps) {
  const { getData } = props;

  const [model, setModel] = useState<Ticket[]>([]);

  const [subscription] = commonService.useSubscription();

  const history = useHistory();

  const handleGoTicket = (_event: any) => {
    history.push(TICKET_MASTER_ROUTE);
  };

  useEffect(() => {
    subscription.add(getData().subscribe((data: Ticket[]) => setModel(data)));
  }, [getData, subscription]);

  return (
    <>
      <div className="dashboard-master__ticket">
        <Table
          rowKey={"id"}
          columns={ticketColumns}
          pagination={false}
          dataSource={model}
          rowSelection={null}
          scroll={{ y: 400, x: "max-content" }}
        ></Table>
        <hr />
        <div className="d-flex align-items-center justify-content-center my-3">
          <span onClick={handleGoTicket}>Xem thêm</span>
        </div>
      </div>
    </>
  );
}
