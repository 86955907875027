import React, { useState } from "react";
import { Row, Col, Card, Select } from "antd";
import { dashboardRepository } from "repositories/dashboard-repository";
import OverviewInfo from "./OverviewInfo";
import RecentEndUsers from "./RecentEndUsers";
import RecentTickets from "./RecentTickets";
import "./DashboardMaster.scss";
import { endUserRepository } from "repositories/end-user-repository";
import { homeControllerRepository } from "repositories/home-controller-repository";
import { deviceRepository } from "repositories/device-repository";
import appMessageService, { messageType } from "services/app-message-service";
import SurveyChart from "./SurveyChart";

const { Option } = Select;

export default function DashboardMaster() {
  const [filter, setFilter] = useState<number>(1);
  // const [chartData, setChartData] = useState<any[]>([]);
  // const [highlightedInfo, setHighlightedInfo] = useState<string | null>(null);

  // const fetchData = React.useCallback(async (getDataFunction: () => Promise<any>) => {
  //   try {
  //     const data = await getDataFunction();
  //     setChartData(data);
  //   } catch (error) {
  //   }
  // }, []);
  // const handleOverviewInfoClick = React.useCallback(async (getDataFunction: () => Promise<any>) => {
  //   await fetchData(getDataFunction);
  // }, [fetchData]);
  // React.useEffect(() => {
  //   handleOverviewInfoClick(() => endUserRepository.EndUserSatistic().toPromise());
  //   setHighlightedInfo("Khách hàng");
  // }, [handleOverviewInfoClick]);
  return (
    <div className="page page__master dashboard-master__container">
      <div className="page__header d-flex align-items-center">
        <h5 className="mr-3 mb-0">
          <strong>Tổng quan</strong>
        </h5>
        <Select
          defaultValue={1}
          style={{ width: 120, backgroundColor: "white", borderRadius: 4 }}
          onChange={(val) => setFilter(val)}
          bordered={false}
        >
          <Option value={1}>Tháng</Option>
        </Select>
      </div>
      <div className="page__body">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <OverviewInfo
            filterId={filter}
            title="Khách hàng"
            getData={dashboardRepository.overviewEndUser}
            icon="tio-account_circle"
            color="#2f80ed"
          // onClickOverview={async () => {
          //   setHighlightedInfo("Khách hàng");
          //   await handleOverviewInfoClick(() => endUserRepository.EndUserSatistic().toPromise());
          // }}
          // isHighlighted={highlightedInfo === "Khách hàng"}
          />
          <OverviewInfo
            filterId={filter}
            title="Home Controller"
            getData={dashboardRepository.overviewHomeController}
            icon="tio-speaker"
            color="#f2994a"
          // onClickOverview={async () => {
          //   setHighlightedInfo("Home Controller");
          //   await handleOverviewInfoClick(() => homeControllerRepository.HomeControllerSatistic().toPromise());
          // }}
          // isHighlighted={highlightedInfo === "Home Controller"}
          />
          <OverviewInfo
            filterId={filter}
            title="Thiết bị"
            getData={dashboardRepository.overviewDevice}
            icon="tio-plug_outlined"
            color="#219653"
          // isHighlighted={highlightedInfo === "Thiết bị"}
          // onClickOverview={async () => {
          //   setHighlightedInfo("Thiết bị");
          //   await handleOverviewInfoClick(() => deviceRepository.DeviceSatistic().toPromise());
          // }}
          />
          {/* <OverviewInfo
            filterId={filter}
            title="Khiếu nại"
            getData={dashboardRepository.overviewTicket}
            icon="tio-ticket"
            color="#2f80ed"
            isHighlighted={highlightedInfo === "Khiếu nại"}
            onClickOverview={async() => {
              appMessageService.messageFactory({
                type: messageType.WARNING,
                title: 'Chưa có phản hồi'
              });
            }}
          />
            */}
        </Row>
        {/* <Row>
          <SurveyChart chartData={chartData} />
        </Row> */}
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          className="mt-4 dashboard-master__recent"
        >
          <Col span={8}>
            <Card
              title={<strong>Khách hàng mới</strong>}
              bordered={false}
            >
              <RecentEndUsers getData={dashboardRepository.listEndUser} />
            </Card>
          </Col>
          <Col span={16}>
            <Card
              title={<strong>Khiếu nại mới</strong>}
              bordered={false}
            >
              <RecentTickets getData={dashboardRepository.listTicket} />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
