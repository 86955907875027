import { AxiosResponse } from "axios";
import { Repository } from "@react3l/react3l/core";
import { kebabCase, url } from "@react3l/react3l/helpers";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import nameof from "ts-nameof.macro";

import { API_HOME_CONTROLLER_PREFIX } from "config/api-consts";
import { HomeController, HomeControllerFilter } from "models/HomeController";
import { Status, StatusFilter } from "models/Status";
import { HomeControllerLog } from "models/HomeControllerLog";
import { HomeControllerCommand } from "models/HomeControllerCommand";
import { NotificationFormModel } from "models/NotificationForm";

export class HomeControllerRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = url(BASE_API_URL, API_HOME_CONTROLLER_PREFIX);
  }

  public count = (hcFilter?: HomeControllerFilter): Observable<number> => {
    return this.httpObservable
      .post<number>(kebabCase(nameof(this.count)), hcFilter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public list = (
    hcFilter?: HomeControllerFilter
  ): Observable<HomeController[]> => {
    return this.httpObservable
      .post<HomeController[]>(kebabCase(nameof(this.list)), hcFilter)
      .pipe(map((response: AxiosResponse<HomeController[]>) => response.data));
  };

  public listLogs = (
    hc?: HomeController
  ): Observable<HomeControllerLog[]> => {
    return this.httpObservable
      .post<HomeControllerLog[]>(kebabCase(nameof(this.listLogs)), hc)
      .pipe(map((response: AxiosResponse<HomeControllerLog[]>) => response.data));
  };

  public get = (id: number | string): Observable<HomeController> => {
    return this.httpObservable
      .post<HomeController>(kebabCase(nameof(this.get)), { id })
      .pipe(map((response: AxiosResponse<HomeController>) => response.data));
  };

  public singleListHomeControllerStatus = (): Observable<Status[]> => {
    return this.httpObservable
      .post<Status[]>(
        kebabCase(nameof(this.singleListHomeControllerStatus)),
        new StatusFilter()
      )
      .pipe(map((response: AxiosResponse<Status[]>) => response.data));
  };

  public filterListHomeControllerStatus = (): Observable<Status[]> => {
    return this.httpObservable
      .post<Status[]>(
        kebabCase(nameof(this.filterListHomeControllerStatus)),
        new StatusFilter()
      )
      .pipe(map((response: AxiosResponse<Status[]>) => response.data));
  };

  public singleListHomeControllerActiveStatus = (): Observable<Status[]> => {
    return this.httpObservable
      .post<Status[]>(
        kebabCase(nameof(this.singleListHomeControllerActiveStatus)),
        new StatusFilter()
      )
      .pipe(map((response: AxiosResponse<Status[]>) => response.data));
  };

  public filterListHomeControllerActiveStatus = (): Observable<Status[]> => {
    return this.httpObservable
      .post<Status[]>(
        kebabCase(nameof(this.filterListHomeControllerActiveStatus)),
        new StatusFilter()
      )
      .pipe(map((response: AxiosResponse<Status[]>) => response.data));
  };

  public import = (
    file: File,
    name: string = nameof(file)
  ): Observable<void> => {
    const formData: FormData = new FormData();
    formData.append(name, file as Blob);
    return this.httpObservable
      .post<void>(kebabCase(nameof(this.import)), formData)
      .pipe(map((response: AxiosResponse<void>) => response.data));
  };

  public exportTemplate = (): Observable<AxiosResponse<any>> => {
    return this.httpObservable.post(
      "export-template",
      {},
      {
        responseType: "arraybuffer",
      }
    );
  };

  public upload = (file: File): Observable<any> => {
    const formData: FormData = new FormData();
    formData.append("file", file as Blob);
    return this.httpObservable
      .post<any>(kebabCase(nameof(this.upload)), formData)
      .pipe(map((response: AxiosResponse<any>) => response.data));
  };

  public remoteCommand = (hcCommand: HomeControllerCommand): Observable<any> => {
    return this.httpObservable
      .post<HomeControllerCommand>(
        kebabCase(nameof(this.remoteCommand)),
        hcCommand
      )
      .pipe(map((response: AxiosResponse<any>) => response.data));
  };
  public sendNotification = (notification: NotificationFormModel): Observable<boolean> => {

    return this.httpObservable
    .post<boolean>(kebabCase(nameof(this.sendNotification)), notification)
    .pipe(map((response: AxiosResponse<boolean>) => response.data));
  }
  public HomeControllerSatistic = (): Observable<HomeController[]> => {
    return this.httpObservable
      .get(kebabCase(nameof(this.HomeControllerSatistic)))
      .pipe(map((response: AxiosResponse<HomeController[]>) => response.data));
  };
}

export const homeControllerRepository = new HomeControllerRepository();
