import { ModelFilter } from "@react3l/react3l/core";
import { DateFilter, IdFilter, StringFilter } from "@react3l/advanced-filters";

export class FirmwareFilter extends ModelFilter {
    public id?: IdFilter = new IdFilter();
    public name?: StringFilter = new StringFilter();
    public version?: StringFilter = new StringFilter();
    public checksum?: StringFilter = new StringFilter();
    public url?: StringFilter = new StringFilter();
    public homeControllerTypeId? : IdFilter = new IdFilter();
    public createdAt?: DateFilter = new DateFilter();
}
