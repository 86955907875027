import { Model } from "@react3l/react3l/core";
import { Moment } from 'moment';

export class Certification extends Model {
  public id?: number;
  public name?: string;
  public url?: string;
  public validFrom?: Moment;
  public validUntil?: Moment;
}
