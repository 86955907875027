import React, { Reducer, Dispatch } from "react";
import { TicketConversation } from "models/TicketConversation";
import { queryStringService } from "services/query-string-service";
import { Subscription, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import "./TicketConversation.scss";
import { formatDate } from "helpers/date-time";
import { TicketConversationFileMapping } from "models/TicketConversationFileMapping";
import { AuthContext } from "app/app-context";
import { AppUser } from "models/AppUser";

interface TCAction {
  type: string;
  payload?: TicketConversation[];
}

const SET_CONV: string = "SET_CONV";
const ADD_CONV: string = "ADD_CONV";

function tcReducer(state: TicketConversation[], action: TCAction) {
  switch (action.type) {
    case SET_CONV: {
      return [...action.payload];
    }
    case ADD_CONV: {
      return [...state, ...action.payload];
    }
  }
}

export function useTicketConversation(
  getList: (id: number | string) => Observable<TicketConversation[]>,
  onSave: (ticket: TicketConversation) => Observable<TicketConversation>
) {
  const [list, dispatch] = React.useReducer<
    Reducer<TicketConversation[], TCAction>
  >(tcReducer, []);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [input, setInput] = React.useState("");

  const [appUserInfo] =
    React.useContext<[AppUser, Dispatch<AppUser>]>(AuthContext);

  // get id from url
  const { id }: any = queryStringService.useGetQueryString();

  React.useEffect(() => {
    const subscription: Subscription = new Subscription();
    if (id) {
      setLoading(true);
      subscription.add(
        getList(id)
          .pipe(finalize(() => setLoading(false)))
          .subscribe((list: TicketConversation[]) => {
            dispatch({
              type: SET_CONV,
              payload: list,
            });
          })
      );
    }

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [getList, id]);

  const handleSave = React.useCallback(
    (fileMappings: TicketConversationFileMapping[]) => {
      const subscription: Subscription = new Subscription();
      setLoading(true);
      const newTicket: TicketConversation = {
        ticketId: id,
        content: input,
        appUserId: appUserInfo.id,
        appUser: appUserInfo,
        endUserId: null,
        endUser: null,
        ticketConversationFileMappings: [...fileMappings],
      };
      subscription.add(
        onSave(newTicket)
          .pipe(
            finalize(() => {
              setLoading(false);
              setInput("");
            })
          )
          .subscribe((data: TicketConversation) => {
            dispatch({
              type: ADD_CONV,
              payload: [data],
            });
          })
      );
    },
    [id, appUserInfo, input, onSave]
  );

  return { list, loading, input, setInput, handleSave };
}

interface TicketConversationProps {
  item: TicketConversation;
}
export function TicketConversationItem(props: TicketConversationProps) {
  const { item } = props;
  return (
    <>
      <div className="d-flex w-100 ticket-conversation">
        <div className="ticket-conversation__avatar">
          <img
            src="https://media.discordapp.net/attachments/663753852184428596/847406738138595348/7ab2cd69-80fe-4106-ba8d-218d78b131d4.png"
            alt=""
          />
        </div>
        <div className="ticket-conversation__body">
          <div className="ticket-conversation__header">
            <p>
              {item.endUser !== null
                ? item.endUser.displayName
                : item.appUser.displayName}
            </p>
            <p>{formatDate(item.createdAt)}</p>
          </div>
          <div className="ticket-conversation__content">{item.content}</div>
          <div className="ticket-conversation__files">
            {item.ticketConversationFileMappings.map((f) => (
              <img
                key={f.fileId}
                src={`data:image/png;base64,${f.file.content}`}
                alt=""
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

// https://media.discordapp.net/attachments/663753852184428596/847406738138595348/7ab2cd69-80fe-4106-ba8d-218d78b131d4.png
