import { Col, Row, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import classNames from "classnames";
import React from "react";
import homeImage from "./../../../assets/images/home.png";
import useRoomDetail from "./RoomDetailHook";
import { roomRepository } from "repositories/room-repository";
import { useHistory } from "react-router";
import AppFooter from "components/AppFooter/AppFooter";
import { DeviceType } from "models/DeviceType";
import { Scene } from "models/Scene";
import { Device } from "models/Device";

function RoomDetail() {
  const history = useHistory();

  const { model } = useRoomDetail(
    roomRepository.get
  );

  const handleGoBack = React.useCallback(() => history.goBack(), [history]);

  const deviceColumns: ColumnProps<Device>[] = [
    {
      title: <div className="gradient-text text-upper">Tên/MAC</div>,
      key: "name",
      dataIndex: "name",
      width: 200,
      render(name: string) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames("cell-master__first-row", {
                "first-row--ellipsis": true,
              })}
            >
              {name}
            </div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">Nhóm thiết bị</div>,
      key: "deviceType",
      dataIndex: "deviceType",
      width: 200,
      render(deviceType: DeviceType) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames("cell-master__first-row", {
                "first-row--ellipsis": true,
              })}
            >
              {deviceType?.name}
            </div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">ID Thiết Bị</div>,
      key: "id",
      dataIndex: "id",
      width: 400,
      render(id: string) {
        return (
          <div className="ant-cell-master__container">
            <div className="cell-master__first-row">{id}</div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">Version</div>,
      key: "version",
      dataIndex: "version",
      width: 150,
      render(version: string) {
        return (
          <div className="ant-cell-master__container">
            <div className="cell-master__first-row">{version}</div>
          </div>
        );
      },
    },
  ];

  const sceneColumns: ColumnProps<Scene>[] = [
    {
      title: <div className="gradient-text text-upper">Id</div>,
      key: "name",
      dataIndex: "name",
      width: 200,
      render(id: string) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames(
                "cell-master__first-row",
                "first-row--link",
                {
                  "first-row--ellipsis": true,
                }
              )}
            >
              {id}
            </div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">Tên</div>,
      key: "name",
      dataIndex: "name",
      width: 200,
      render(name: string) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames(
                "cell-master__first-row",
                "first-row--link",
                {
                  "first-row--ellipsis": true,
                }
              )}
            >
              {name}
            </div>
          </div>
        );
      },
    },
  ];
  const childrenAction = React.useMemo(
    () => (
      <>
        <button
          className="btn component__btn-primary ticket-detail__action--do mr-4"
          onClick={handleGoBack}
        >
          Quay lại
        </button>
      </>
    ),
    [handleGoBack]
  );

  return (
    <div className="page page__detail w-100 home__container">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={6}>
          <div className="left-side__container w-100 d-flex flex-column">
            <div className="left-side__image">
              <img src={homeImage} alt="IMG" />
            </div>
            <div className="d-flex flex-column align-items-center mt-1">
              <div className="user-info__label-field user-info__label-field--big">
                {model.name}
              </div>
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={18}>
          <div className="right-side__container w-100">
            <div className="right-side__home-controller">
              <div className="home-controller__title">Thiết bị</div>
              <div className="home-controller__table">
                <Table
                  rowKey={"id"}
                  columns={deviceColumns}
                  pagination={false}
                  dataSource={model.devices}
                  rowSelection={null}
                  scroll={{ x: "max-content" }}
                ></Table>
              </div>
            </div>
            <div className="right-side__home-controller">
              <div className="home-controller__title">Cảnh</div>
              <div className="home-controller__table">
                <Table
                  rowKey={"id"}
                  columns={sceneColumns}
                  pagination={false}
                  dataSource={model.scenes}
                  rowSelection={null}
                  scroll={{ x: "max-content" }}
                ></Table>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <AppFooter childrenAction={childrenAction}></AppFooter>
    </div>
  );
}

export default RoomDetail;
