import { ModelFilter } from "@react3l/react3l/core";
import { DateFilter, IdFilter, StringFilter } from "@react3l/advanced-filters";

export class ResourceFilter extends ModelFilter {
  public id?: IdFilter = new IdFilter();
  public name?: StringFilter = new StringFilter();
  public createdAt?: DateFilter = new DateFilter();
  public updatedAt?: DateFilter = new DateFilter();
  public languageId?: IdFilter = new IdFilter();
}
