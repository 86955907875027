import React from "react";
import { commonService } from "@react3l/react3l/services/common-service";
import { AppUser } from "models/AppUser";
import { Observable } from "rxjs";
import { formService } from "services/form-service";
import { finalize } from "rxjs/operators";
import appMessageService from "services/app-message-service";
import { AxiosError } from "axios";

export function useAppUserCreateModal(
  saveModel: (au: AppUser) => Observable<AppUser>,
  handleSearch?: () => void
) {
  // message service
  const { notifyUpdateItemSuccess } = appMessageService.useCRUDMessage();

  const [subscription] = commonService.useSubscription();
  const [isOpenCreateModal, setIsOpenCreateModal] =
    React.useState<boolean>(false);

  const [loadingCreateModel, setLoadingCreateModel] =
    React.useState<boolean>(false);

  const handleOpenCreateModal = React.useCallback(() => {
    setIsOpenCreateModal(true);
  }, []);

  const [
    model,
    handleChangeSimpleField,
    handleChangeObjectField,
    handleUpdateNewModel, // alternate for setModel
    ,
    ,
  ] = formService.useDetailForm<AppUser>(AppUser, undefined, undefined); // id is undefined as we not archive id from url

  const handleSaveCreateModel = React.useCallback(() => {
    setLoadingCreateModel(true);
    subscription.add(
      saveModel(model)
        .pipe(finalize(() => setLoadingCreateModel(false)))
        .subscribe(
          (_item: AppUser) => {
            handleUpdateNewModel(new AppUser()); // setModel
            setIsOpenCreateModal(false); // close Modal
            if (typeof handleSearch === "function") handleSearch(); // updateList if necessary
            notifyUpdateItemSuccess(); // global message service go here
          },
          (error: AxiosError<AppUser>) => {
            if (error.response && error.response.status === 400)
              handleUpdateNewModel(error.response?.data);
            // notifyUpdateItemError(); // global message service go here
          }
        )
    );
  }, [
    handleSearch,
    handleUpdateNewModel,
    model,
    notifyUpdateItemSuccess,
    saveModel,
    subscription,
  ]);

  const handleCloseCreateModal = React.useCallback(() => {
    setIsOpenCreateModal(false);
    if (model.id) handleUpdateNewModel({ ...model });
    else handleUpdateNewModel({ ...new AppUser() });
    if (typeof handleSearch === "function") handleSearch(); // updateList if necessary
  }, [model, handleUpdateNewModel, handleSearch]);

  return {
    model,
    isOpenCreateModal,
    loadingCreateModel,
    handleOpenCreateModal,
    handleCloseCreateModal,
    handleSaveCreateModel,
    handleChangeSimpleField,
    handleChangeObjectField,
  };
}
