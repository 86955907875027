import { AxiosResponse } from "axios";
import { Repository } from "@react3l/react3l/core";
import { kebabCase, url } from "@react3l/react3l/helpers";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import nameof from "ts-nameof.macro";

import { API_RESOURCE_PREFIX } from "config/api-consts";
import { Resource, ResourceFilter } from "../models/Resource";
import { ResourceTranslationLanguage } from "models/ResourceTranslationLanguage";

export class ResourceRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = url(BASE_API_URL, API_RESOURCE_PREFIX);
  }

  public count = (filter?: ResourceFilter): Observable<number> => {
    return this.httpObservable
      .post<number>(kebabCase(nameof(this.count)), filter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public list = (
    filter?: ResourceFilter
  ): Observable<Resource[]> => {
    return this.httpObservable
      .post<Resource[]>(kebabCase(nameof(this.list)), filter)
      .pipe(map((response: AxiosResponse<Resource[]>) => response.data));
  };

  public get = (id: number | string): Observable<Resource> => {
    return this.httpObservable
      .post<Resource>(kebabCase(nameof(this.get)), { id })
      .pipe(map((response: AxiosResponse<Resource>) => response.data));
  };

  public create = (item: Resource): Observable<Resource> => {
    return this.httpObservable
      .post<Resource>(kebabCase(nameof(this.create)), item)
      .pipe(map((response: AxiosResponse<Resource>) => response.data));
  };

  public update = (item: Resource): Observable<Resource> => {
    return this.httpObservable
      .post<Resource>(kebabCase(nameof(this.update)), item)
      .pipe(map((response: AxiosResponse<Resource>) => response.data));
  };

  public delete = (item: Resource): Observable<Resource> => {
    return this.httpObservable
      .post<Resource>(kebabCase(nameof(this.delete)), item)
      .pipe(map((response: AxiosResponse<Resource>) => response.data));
  };

  public save = (
    item: Resource,
    isDetail: boolean
  ): Observable<Resource> => {
    return isDetail ? this.update(item) : this.create(item);
  };

  public bulkDelete = (idList: number[] | string[]): Observable<void> => {
    return this.httpObservable
      .post(kebabCase(nameof(this.bulkDelete)), idList)
      .pipe(map((response: AxiosResponse<void>) => response.data));
  };

  public import = (
    file: File,
    languageId: number
  ): Observable<void> => {
    const formData: FormData = new FormData();
    formData.append("file", file as Blob);
    formData.append("languageId", languageId.toString());
    return this.httpObservable
      .post<void>(kebabCase(nameof(this.import)), formData)
      .pipe(map((response: AxiosResponse<void>) => response.data));
  };

  public singleListLanguage = (
  ): Observable<ResourceTranslationLanguage[]> => {
    return this.httpObservable
      .post<ResourceTranslationLanguage[]>(kebabCase(nameof(this.singleListLanguage)))
      .pipe(map((response: AxiosResponse<ResourceTranslationLanguage[]>) => response.data));
  };

}

export const resourceRepository = new ResourceRepository();

