import { Model } from "@react3l/react3l/core";
import { TicketStatus } from "models/TicketStatus";
import { EndUser } from "models/EndUser";
import { AppUser } from "models/AppUser";
import { Moment } from "moment";

export class Ticket extends Model {
  public id?: number;

  public code?: string;

  public title?: string;

  public location?: string;

  public appUser?: AppUser;

  public endUser?: EndUser;

  public ticketStatus?: TicketStatus;

  public createdAt?: Moment;
}
