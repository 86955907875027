import { Card, Col, Row, Empty } from "antd";
import React from "react";
import { TICKET_ROUTE } from "config/route-consts";
import { ticketConversationRepository } from "repositories/ticket-conversation-repository";
import { useTranslation } from "react-i18next";
import { useTicketDetail } from "./TicketDetailHook";
import {
  useTicketConversation,
  TicketConversationItem,
} from "./TicketConversationHook";
import AppFooter from "components/AppFooter/AppFooter";
import "../TicketView.scss";
import "./TicketDetail.scss";
import { formatDate } from "helpers/date-time";
import { routerService } from "services/route-service";
import TicketConversationInput from "./TicketConversationInput";
import authenticationService from "services/authentication-service";
import {
  API_TICKET_PREFIX,
  API_TICKET_CONVERSATION_PREFIX,
} from "config/api-consts";

function TicketDetail() {
  const [translate] = useTranslation();
  const { validAction } = authenticationService.useAction(
    "ticket",
    API_TICKET_PREFIX
  );
  const { validAction: validTicketConversationAction } =
    authenticationService.useAction(
      "ticketConversation",
      API_TICKET_CONVERSATION_PREFIX
    );

  const { model, handleSetStatus, handleSelfAssign } = useTicketDetail();

  const { list, loading, input, setInput, handleSave } = useTicketConversation(
    ticketConversationRepository.getTicket,
    ticketConversationRepository.create
  );

  const [, , , handleGoBase] = routerService.useMasterNavigation(TICKET_ROUTE);

  const childrenAction = React.useMemo(
    () => (
      <>
        {validAction("update") ? (
          <>
            {model.ticketStatusId === 1 && (
              <button
                className="btn component__btn-primary ticket-detail__action--do mr-4"
                onClick={handleSetStatus}
              >
                Xử lý
              </button>
            )}
            {model.ticketStatusId === 2 && (
              <button
                className="btn component__btn-primary mr-4"
                onClick={handleSetStatus}
              >
                Hoàn thành
              </button>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    ),
    [handleSetStatus, model.ticketStatusId, validAction]
  );

  return (
    <div className="page page__detail w-100 ticket-detail__container">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={6}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row user-info__wrapper" span={24}>
              <Card
                title={
                  <span className="card-title">
                    {translate("tickets.detail.title")}
                  </span>
                }
              >
                <div className="d-flex w-100 user-info__container flex-column">
                  <div
                    className="user-info__content d-flex flex-column flex-grow-1"
                    style={{ borderTop: "none", paddingTop: 0 }}
                  >
                    <div className="d-flex flex-column">
                      <div className="user-info__label-field user-info__label-field--light">
                        {translate("tickets.code")}
                      </div>
                      <div className="user-info__label-content mt-1">
                        {model.code}
                      </div>
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <div className="user-info__label-field user-info__label-field--light">
                        {translate("tickets.endUser")}
                      </div>
                      <div className="user-info__label-content mt-1">
                        {model.endUser?.displayName}
                      </div>
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <div className="user-info__label-field user-info__label-field--light">
                        {translate("tickets.email")}
                      </div>
                      <div className="user-info__label-content mt-1">
                        {model.endUser?.email}
                      </div>
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <div className="user-info__label-field user-info__label-field--light">
                        {translate("tickets.phoneNumber")}
                      </div>
                      <div className="user-info__label-content mt-1">
                        {model.endUser?.phoneNumber}
                      </div>
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <div className="user-info__label-field user-info__label-field--light">
                        {translate("tickets.location")}
                      </div>
                      <div className="user-info__label-content mt-1">
                        {model.location}
                      </div>
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <div className="user-info__label-field user-info__label-field--light">
                        {translate("tickets.ticketStatus")}
                      </div>
                      {model.ticketStatus && (
                        <div
                          className={`ticket__status ticket__status--${model.ticketStatus.code.toLowerCase()} mt-1`}
                        >
                          {model.ticketStatus.name}
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <div className="user-info__label-field user-info__label-field--light">
                        {translate("tickets.createdAt")}
                      </div>
                      <div className="user-info__label-content mt-1">
                        {formatDate(model.createdAt, "hh:mm A - DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column ticket-detail__assignee mt-4">
                    <h4>Người phụ trách</h4>
                    {model.appUser ? (
                      <div className="ticket-detail__assignee-info d-flex align-items-center">
                        <img
                          src="https://media.discordapp.net/attachments/663753852184428596/847406738138595348/7ab2cd69-80fe-4106-ba8d-218d78b131d4.png"
                          alt=""
                        />
                        <p>{model.appUser.displayName}</p>
                      </div>
                    ) : (
                      <>
                        {validAction("update") && model.ticketStatus?.id !== 3 && (
                          <>
                            <div className="d-flex align-items-center">
                              <button onClick={handleSelfAssign}>
                                <i className="tio-add"></i>
                              </button>
                            </div>
                            <small className="mt-2">
                              (Bấm vào + để gán cho bản thân)
                            </small>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col className="gutter-row" span={18}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Card
                title={
                  <span className="card-title">
                    {model.code} - {model.title}
                  </span>
                }
              >
                {!loading && list.length > 0 ? (
                  <>
                    {list.map((item) => (
                      <TicketConversationItem item={item} key={item.id} />
                    ))}
                  </>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                {validTicketConversationAction("create") &&
                  model.ticketStatus?.id !== 3 && (
                    <div className="ticket-detail__input">
                      <TicketConversationInput
                        input={input}
                        setInput={setInput}
                        onSubmit={handleSave}
                        uploadFile={ticketConversationRepository.multiUpload}
                      />
                    </div>
                  )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <AppFooter
        onCancel={handleGoBase}
        childrenAction={childrenAction}
      ></AppFooter>
    </div>
  );
}

export default TicketDetail;
