import React from "react";
import { Switch } from "react-router-dom";
import {
  END_USER_MASTER_ROUTE,
  END_USER_DETAIL_ROUTE
} from "config/route-consts";
import { ProtectedRoute, useCheckAuthorized } from "config/protected-route";

import "./EndUserView.scss";
import EndUserDetail from "./EndUserDetail/EndUserDetail";
import EndUserMaster from "./EndUserMaster/EndUserMaster";

function EndUserView() {
  const { auth } = useCheckAuthorized();
  return (
    <Switch>
      <ProtectedRoute
        path={END_USER_MASTER_ROUTE}
        key={END_USER_MASTER_ROUTE}
        component={EndUserMaster}
        auth={auth(END_USER_MASTER_ROUTE)}
      />
      <ProtectedRoute
        path={END_USER_DETAIL_ROUTE}
        key={END_USER_DETAIL_ROUTE}
        component={EndUserDetail}
        auth={auth(END_USER_DETAIL_ROUTE)}
      />
    </Switch>
  );
}

export { EndUserMaster, EndUserDetail };
export default EndUserView;
