import React from "react";
import { Switch } from "react-router-dom";
import {
  HOME_CONTROLLER_MASTER_ROUTE,
  HOME_CONTROLLER_DETAIL_ROUTE,
} from "config/route-consts";
import { ProtectedRoute, useCheckAuthorized } from "config/protected-route";

import "./HomeControllerView.scss";
import HomeControllerDetail from "./HomeControllerDetail/HomeControllerDetail";
import HomeControllerMaster from "./HomeControllerMaster/HomeControllerMaster";

function HomeControllerView() {
  const { auth } = useCheckAuthorized();
  return (
    <Switch>
      <ProtectedRoute
        path={HOME_CONTROLLER_MASTER_ROUTE}
        key={HOME_CONTROLLER_MASTER_ROUTE}
        component={HomeControllerMaster}
        auth={auth(HOME_CONTROLLER_MASTER_ROUTE)}
      />
      <ProtectedRoute
        path={HOME_CONTROLLER_DETAIL_ROUTE}
        key={HOME_CONTROLLER_DETAIL_ROUTE}
        component={HomeControllerDetail}
        auth={auth(HOME_CONTROLLER_DETAIL_ROUTE)}
      />
    </Switch>
  );
}

export { HomeControllerMaster, HomeControllerDetail };
export default HomeControllerView;
