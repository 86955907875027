import { Model } from "@react3l/react3l/core";
import { Device } from "models/Device";
import { Scene } from "models/Scene";

export class Room extends Model {
  public id?: string;
  public name?: string;
  public dormitoryId?: number;
  public devices?: Device[];
  public scenes?: Scene[];
}

