import { AxiosResponse } from "axios";
import { Repository } from "@react3l/react3l/core";
import { kebabCase, url } from "@react3l/react3l/helpers";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import nameof from "ts-nameof.macro";

import { API_TICKET_PREFIX } from "config/api-consts";
import { Ticket, TicketFilter } from "models/Ticket";
import { TicketStatus, TicketStatusFilter } from "models/TicketStatus";
import { EndUser, EndUserFilter } from "models/EndUser";
import { AppUser, AppUserFilter } from "models/AppUser";

export class TicketRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = url(BASE_API_URL, API_TICKET_PREFIX);
  }

  public count = (ticketFilter?: TicketFilter): Observable<number> => {
    return this.httpObservable
      .post<number>(kebabCase(nameof(this.count)), ticketFilter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public list = (ticketFilter?: TicketFilter): Observable<Ticket[]> => {
    return this.httpObservable
      .post<Ticket[]>(kebabCase(nameof(this.list)), ticketFilter)
      .pipe(map((response: AxiosResponse<Ticket[]>) => response.data));
  };

  public get = (id: number | string): Observable<Ticket> => {
    return this.httpObservable
      .post<Ticket>(kebabCase(nameof(this.get)), { id })
      .pipe(map((response: AxiosResponse<Ticket>) => response.data));
  };

  public create = (ticket: Ticket): Observable<Ticket> => {
    return this.httpObservable
      .post<Ticket>(kebabCase(nameof(this.create)), ticket)
      .pipe(map((response: AxiosResponse<Ticket>) => response.data));
  };

  public update = (ticket: Ticket): Observable<Ticket> => {
    return this.httpObservable
      .post<Ticket>(kebabCase(nameof(this.update)), ticket)
      .pipe(map((response: AxiosResponse<Ticket>) => response.data));
  };

  public delete = (ticket: Ticket): Observable<Ticket> => {
    return this.httpObservable
      .post<Ticket>(kebabCase(nameof(this.delete)), ticket)
      .pipe(map((response: AxiosResponse<Ticket>) => response.data));
  };

  public bulkDelete = (idList: number[] | string[]): Observable<void> => {
    return this.httpObservable
      .post(kebabCase(nameof(this.bulkDelete)), idList)
      .pipe(map((response: AxiosResponse<void>) => response.data));
  };

  public singleListTicketStatus = (
    ticketFilter: TicketStatusFilter
  ): Observable<TicketStatus[]> => {
    return this.httpObservable
      .post<TicketStatus[]>(
        kebabCase(nameof(this.singleListTicketStatus)),
        ticketFilter
      )
      .pipe(map((response: AxiosResponse<TicketStatus[]>) => response.data));
  };

  public singleListEndUser = (
    endUserFilter: EndUserFilter
  ): Observable<EndUser[]> => {
    return this.httpObservable
      .post<EndUser[]>(kebabCase(nameof(this.singleListEndUser)), endUserFilter)
      .pipe(map((response: AxiosResponse<EndUser[]>) => response.data));
  };

  public singleListAppUser = (
    appUserFilter: AppUserFilter
  ): Observable<AppUser[]> => {
    return this.httpObservable
      .post<AppUser[]>(kebabCase(nameof(this.singleListAppUser)), appUserFilter)
      .pipe(map((response: AxiosResponse<AppUser[]>) => response.data));
  };

  public filterListAppUser = (): Observable<AppUser[]> => {
    return this.httpObservable
      .post<AppUser[]>(
        kebabCase(nameof(this.filterListAppUser)),
        new AppUserFilter()
      )
      .pipe(map((response: AxiosResponse<AppUser[]>) => response.data));
  };

  public filterListEndUser = (): Observable<EndUser[]> => {
    return this.httpObservable
      .post<EndUser[]>(
        kebabCase(nameof(this.filterListEndUser)),
        new EndUserFilter()
      )
      .pipe(map((response: AxiosResponse<EndUser[]>) => response.data));
  };

  public filterListTicketStatus = (): Observable<TicketStatus[]> => {
    return this.httpObservable
      .post<TicketStatus[]>(
        kebabCase(nameof(this.filterListTicketStatus)),
        new TicketStatusFilter()
      )
      .pipe(map((response: AxiosResponse<TicketStatus[]>) => response.data));
  };
}

export const ticketRepository = new TicketRepository();
