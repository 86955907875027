import React from "react";
import { useTranslation } from "react-i18next";
import { appUserRepository } from "repositories/app-user-repository";
import { AppUser } from "models/AppUser";

export function useAppUserDetailHook(
  model: AppUser,
  updateModel: (data: AppUser) => void
) {
  const [translate] = useTranslation();
  const appUserId = React.useMemo(() => {
    return model.id ? model.id : null;
  }, [model.id]);

  const [isEditable, setEditable] = React.useState(false);

  const handleEdit = React.useCallback(() => {
    setEditable(true);
  }, []);

  const handleFinish = React.useCallback(() => {
    setEditable(false);
  }, []);

  const handleDeactiveAccount = React.useCallback(() => {
    appUserRepository.lock([appUserId]).subscribe(
      (res) => {
        if (res) {
          updateModel(res[0]);
        }
      },
      (_err) => {}
    );
  }, [appUserId, updateModel]);

  const handleActiveAccount = React.useCallback(() => {
    appUserRepository.unlock([appUserId]).subscribe(
      (res) => {
        updateModel(res[0]);
      },
      (_err) => {}
    );
  }, [appUserId, updateModel]);

  return {
    translate,
    isEditable,
    handleEdit,
    handleFinish,
    handleDeactiveAccount,
    handleActiveAccount,
  };
}
