import { Model } from "@react3l/react3l/core";
import { Moment } from "moment";

export class Firmware extends Model {
  public id?: number;
  public name?: string;
  public version?: string;
  public url?: string;
  public checksum?: string;
  public description?: string;
  public hcCount?: number;
  public createdAt?: Moment;
  public updatedAt?: Moment;
  public publishAt?: Moment;
  public homeControllerTypeId?: number;
  public homeControllers?: []
}
