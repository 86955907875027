import React, { useState, useCallback } from "react";
import { Observable } from "rxjs";
import { Ticket, TicketFilter } from "models/Ticket";
import { routerService } from "services/route-service";
import { queryStringService } from "services/query-string-service";
import { advanceFilterService } from "services/advance-filter-service";
import { PaginationProps, Row, Col } from "antd";
import nameof from "ts-nameof.macro";
import tableService from "services/table-service";
import listService from "services/list-service";
import { useTranslation } from "react-i18next";
import appMessageService from "services/app-message-service";
import { commonService } from "@react3l/react3l/services";
import FormItem from "components/Utility/FormItem/FormItem";
import { formService } from "services/form-service";
import InputText from "components/Utility/Input/InputText/InputText";
import Modal, { ModalProps } from "components/Utility/Modal/Modal";
import Select from "components/Utility/Select/Select";
import { EndUserFilter } from "models/EndUser";
import { ticketRepository } from "repositories/ticket-repository";

export function useTicketMaster(
  routePrefix: string,
  getList: (filter: TicketFilter) => Observable<Ticket[]>,
  getTotal: (filter: TicketFilter) => Observable<number>,
  deleteItem?: (t: Ticket) => Observable<Ticket>
) {
  const [, handleGoDetail] = routerService.useMasterNavigation(
    routePrefix // should replace to pricelist detail route base on rbac
  );

  // toggle search state
  const [toggle, setToggle] = useState<boolean>(false);
  // toggle search method, expose this
  const handleToggleSearch = useCallback(() => {
    const toggleTmp = !toggle;
    setToggle(toggleTmp);
  }, [toggle, setToggle]);

  const [filter, dispatch] =
    queryStringService.useQueryString<TicketFilter>(TicketFilter);

  const {
    loadList,
    setLoadList,
    handleSearch,
    handleChangeFilter,
    handleUpdateNewFilter,
    handleResetFilter,
  } = advanceFilterService.useChangeAdvanceFilter<TicketFilter>(
    filter,
    dispatch,
    TicketFilter
  );

  const { list, total, loadingList } = listService.useList(
    filter,
    handleUpdateNewFilter,
    loadList,
    setLoadList,
    handleSearch,
    getList,
    getTotal,
    deleteItem
  );

  const pagination: PaginationProps = tableService.usePagination<TicketFilter>(
    filter,
    total
  );

  const { handleTableChange, handlePagination, handleServerDelete } =
    tableService.useTable<Ticket, TicketFilter>(
      filter,
      handleUpdateNewFilter,
      handleSearch,
      null,
      null,
      null
    );

  return {
    list,
    total,
    loadingList,
    filter,
    toggle,
    handleUpdateNewFilter,
    handleChangeFilter,
    handleResetFilter,
    handleGoDetail,
    handleToggleSearch,
    handleTableChange,
    handlePagination,
    handleSearch,
    handleServerDelete,
    pagination, // optional using
  };
}

export function useTicketMasterModal(
  saveModel: (newModel: Ticket) => Observable<Ticket>,
  handleSearch: () => void
) {
  const [
    model,
    handleChangeSimpleField,
    handleChangeObjectField,
    handleUpdateNewModel, // alternate for setModel
    ,
    ,
  ] = formService.useDetailForm<Ticket>(Ticket, undefined, undefined); // id is undefined as we not archive id from url

  const { notifyUpdateItemSuccess, notifyUpdateItemError } =
    appMessageService.useCRUDMessage();

  // create state
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const [subscription] = commonService.useSubscription();

  const handleOpenModal = useCallback(() => setIsOpenModal(true), []);

  const handleCloseModal = useCallback(() => setIsOpenModal(false), []);

  const handleSaveModel = React.useCallback(
    (model) => {
      subscription.add(saveModel);
      saveModel(model).subscribe(
        (_item: Ticket) => {
          handleUpdateNewModel(new Ticket());
          setIsOpenModal(false);
          if (typeof handleSearch === "function") handleSearch(); // updateList if necessary
          notifyUpdateItemSuccess();
        },
        (_error: any) => {
          notifyUpdateItemError();
        }
      );
    },
    [
      handleSearch,
      notifyUpdateItemError,
      notifyUpdateItemSuccess,
      handleUpdateNewModel,
      saveModel,
      subscription,
    ]
  );

  return {
    isOpenModal,
    handleOpenModal,
    handleCloseModal,
    handleSaveModel,
    newTicketModel: model,
    handleChangeSimpleField,
    handleChangeObjectField,
  };
}

interface TicketMasterModalProps extends ModalProps {
  model: Ticket;
  handleChangeSimpleField: any;
  handleChangeObjectField: any;
  handleSave?: (t: Ticket) => void;
  handleCancel?: () => void;
}

export function TicketMasterModal(props: TicketMasterModalProps) {
  const [translate] = useTranslation();
  const {
    model,
    handleChangeObjectField,
    handleChangeSimpleField,
    handleSave,
    handleCancel,
  } = props;

  return (
    <Modal {...props} width={360}>
      <div className="page page__detail">
        <div className="page__modal-header w-100">
          <Row className="d-flex">
            <Col lg={24}>
              <div className="page__title mr-1 mb-4">
                {translate("tickets.new.title")}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={24} className="pr-3 mb-2">
              <FormItem
                label={translate("tickets.creator")}
                validateStatus={formService.getValidationStatus<Ticket>(
                  model.errors,
                  nameof(model.endUser)
                )}
                message={model.errors?.endUser}
              >
                <Select
                  isMaterial={true}
                  classFilter={EndUserFilter}
                  placeHolder={"Chọn khách hàng"}
                  getList={ticketRepository.singleListEndUser}
                  searchProperty={nameof(model.endUser.displayName)}
                  render={(model) => model?.displayName}
                  onChange={handleChangeObjectField(nameof(model.endUser))}
                  model={model.endUser}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={24} className="pr-3 mb-2">
              <FormItem
                label={translate("tickets.title")}
                validateStatus={formService.getValidationStatus<Ticket>(
                  model.errors,
                  nameof(model.title)
                )}
                message={model.errors?.title}
              >
                <InputText
                  isMaterial={true}
                  value={model.title}
                  onChange={handleChangeSimpleField(nameof(model.title))}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <button
              className="btn btn-sm component__btn-primary mr-2"
              onClick={() => handleSave(model)}
            >
              <span>
                <i className="tio-save" /> {translate("general.actions.save")}
              </span>
            </button>
            <button
              className="btn btn-sm component__btn-cancel"
              onClick={handleCancel}
            >
              <i className="tio-clear" /> {translate("general.actions.cancel")}
            </button>
          </Row>
        </div>
      </div>
    </Modal>
  );
}
