import { ModelFilter } from "@react3l/react3l/core";
import { DateFilter, IdFilter, StringFilter } from "@react3l/advanced-filters";

export class CertificationFilter extends ModelFilter {
  public id?: IdFilter = new IdFilter();
  public name?: StringFilter = new StringFilter();
  public url?: StringFilter = new StringFilter();
  public validFrom?: DateFilter = new DateFilter();
  public validUntil?: DateFilter = new DateFilter();
}
