import { AxiosResponse } from "axios";
import { Repository } from "@react3l/react3l/core";
import { kebabCase, url } from "@react3l/react3l/helpers";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import nameof from "ts-nameof.macro";

import { API_ROOM_PREFIX } from "config/api-consts";
import { Room, RoomFilter } from 'models/Room';

export class RoomRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = url(BASE_API_URL, API_ROOM_PREFIX);
  }

  public count = (roomFilter?: RoomFilter): Observable<number> => {
    return this.httpObservable
      .post<number>(kebabCase(nameof(this.count)), roomFilter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public list = (
    roomFilter?: RoomFilter
  ): Observable<Room[]> => {
    return this.httpObservable
      .post<Room[]>(kebabCase(nameof(this.list)), roomFilter)
      .pipe(map((response: AxiosResponse<Room[]>) => response.data));
  };

  public get = (id?: number): Observable<Room> => {
    return this.httpObservable
      .post<Room>(kebabCase(nameof(this.get)), { id })
      .pipe(map((response: AxiosResponse<Room>) => response.data));
  };


}

export const roomRepository = new RoomRepository();
