import { commonService } from "@react3l/react3l/services";
import { Button, DatePicker, Modal, ModalProps, Select } from "antd";
import { LogActivityTypeEnum } from "config/consts";
import { formatDate } from "helpers/date-time";
import { DeviceActivityLog } from "models/DeviceActivityLog";
import { GroupActivityLog } from "models/GroupActivityLog";
import { HclEventLog } from "models/HclEventLog";
import { RuleActivationEventLog } from "models/RuleActivationEventLog";
import { SceneActivityLog } from "models/SceneActivityLog";
import moment from "moment";
import { Moment } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { activityLogRepository } from "repositories/activity-log-repository";
import { queryStringService } from "services/query-string-service";

const { Option } = Select;

export function useActivityLogModal() {
  const [visibleActivityLogModal, setVisibleActivityLogModal] =
    React.useState(false);

  const openActivityLogModal = React.useCallback(() => {
    setVisibleActivityLogModal(true);
  }, []);

  const cancelActivityLogModal = React.useCallback(() => {
    setVisibleActivityLogModal(false);
  }, []);

  return {
    visibleActivityLogModal,
    openActivityLogModal,
    cancelActivityLogModal,
  };
}

interface ActivityLogModalProps extends ModalProps {
  visibleActivityLogModal?: boolean;
  cancelActivityLogModal?: (e: any) => void;
}

export function ActivityLogModal(props: ActivityLogModalProps) {
  const {
    visibleActivityLogModal,
    cancelActivityLogModal,
  } = props;

  const { id } = queryStringService.useGetQueryString();

  const [subscription] = commonService.useSubscription();

  const [activityType, setActivityType] = useState<number>(null);
  const [date, setDate] = React.useState<Moment>(moment());
  const [activityList, setActivityList] = useState<any[]>([]);

  const filter = useMemo(() => {
    return {
      skip: 0,
      take: 10,
      dormitoryId: { equal: id },
      createdAt: {
        greater: moment(date).startOf("day"),
        less: moment(date).endOf("day"),
      }
    };
  }, [date, id]);

  useEffect(() => {
    switch (activityType) {
      case LogActivityTypeEnum.DEVICE:
        subscription.add(
          activityLogRepository
            .listDeviceLog(filter)
            .subscribe((list: DeviceActivityLog[]) => {
              setActivityList([...list]);
            })
        );
        break;
      case LogActivityTypeEnum.GROUP:
        subscription.add(
          activityLogRepository
            .listGroupLog(filter)
            .subscribe((list: GroupActivityLog[]) => {
              setActivityList([...list]);
            })
        );
        break;
      case LogActivityTypeEnum.SCENE:
        subscription.add(
          activityLogRepository
            .listSceneLog(filter)
            .subscribe((list: SceneActivityLog[]) => {
              setActivityList([...list]);
            })
        );
        break;
      case LogActivityTypeEnum.HCL:
        subscription.add(
          activityLogRepository
            .listHclLog(filter)
            .subscribe((list: HclEventLog[]) => {
              setActivityList([...list]);
            })
        );
        break;
      case LogActivityTypeEnum.RULE:
        subscription.add(
          activityLogRepository
            .listRuleLog(filter)
            .subscribe((list: RuleActivationEventLog[]) => {
              setActivityList([...list]);
            })
        );
        break;
      default:
        break;
    }
  }, [activityType, filter, subscription]);

  const nodeifyLog = (item: any) => {
    switch (activityType) {
      case LogActivityTypeEnum.DEVICE:
        return (<span>Thiết bị <b>{item.device?.name}</b> đã chuyển trạng thái thành <b> {item.onlineStatusId === 1 ? "Online" : "Offline"}</b></span>);
      case LogActivityTypeEnum.GROUP:
        return (<span>Nhóm <b>{item.group?.name}</b> đã chuyển trạng thái thành <b> {item.onlineStatusId === 1 ? "Online" : "Offline"}</b></span>);
      case LogActivityTypeEnum.SCENE:
        return (<span>Đã chuyển sang cảnh <b>{item.scene?.name}</b></span>);
      case LogActivityTypeEnum.HCL:
        return (<span>Đã làm gì đó ai biết đâu</span>);
      case LogActivityTypeEnum.RULE:
        return (<span>Đã kích hoạt rule <b>{item.rule?.name}</b></span>);
      default:
        return <></>;
    }
  };

  const parseLog = (list: any[]) => (
    <>
      <div className="activation__item d-flex justify-content-between">
        <div className="item__content d-flex flex-column justify-content-between w-100">
          <div className="d-flex align-items-center py-2">
            <span className="content__info">
              {list.map((item: any) => (
                <div key={item.id} className="d-flex my-2">
                  <span className="mr-2">
                    {formatDate(item.createdAt, "h:mm A")}
                  </span>
                  {nodeifyLog(item)}
                </div>
              ))}
            </span>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Modal
        title={"Lịch sử điều khiển"}
        visible={visibleActivityLogModal}
        width={840}
        closable={false}
        footer={[
          <Button key="back" onClick={cancelActivityLogModal}>
            Đóng
          </Button>
        ]}
      >
        <div className="activity-modal__container d-flex flex-column">
          <div className="activity-modal__type-filter d-flex">
            <Select defaultValue={null} onChange={(val: number) => setActivityType(val)} placeholder={"Chọn loại lịch sử"}>
              <Option value={LogActivityTypeEnum.DEVICE}>Điều khiển thiết bị</Option>
              <Option value={LogActivityTypeEnum.GROUP}>Điều khiển nhóm</Option>
              <Option value={LogActivityTypeEnum.SCENE}>Điều khiển cảnh</Option>
              <Option value={LogActivityTypeEnum.HCL}>Sự kiện HCL</Option>
              <Option value={LogActivityTypeEnum.RULE}>Sự kiện kích hoạt Rule</Option>
            </Select>
            <DatePicker
              value={date}
              allowClear={false}
              onChange={(value: Moment) => {
                setDate(value);
              }}
            />
          </div>
          {activityType != null && (
            <div className="activity-modal__list">
              {activityList && parseLog(activityList)}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

