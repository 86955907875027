import { Model } from "@react3l/react3l/core";
import { DeviceType } from "models/DeviceType";

export class Device extends Model {
  public id?: string;
  public name?: string;
  public deviceTypeId?: number;
  public deviceType?: DeviceType;
  public firmwareVersion?: string;
  public parentId?: string
}
