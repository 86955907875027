import React from "react";
import { Observable } from "rxjs";
import { AppUser } from "models/AppUser";
import { routerService } from "services/route-service";
import appMessageService from "services/app-message-service";
import { commonService } from "@react3l/react3l/services";
import { finalize } from "rxjs/operators";
import { APP_USER_ROUTE } from "config/route-consts";

export function useAppUserMasterRoleModal(
  getDetail: (id: number) => Observable<AppUser>,
  saveModel: (newModel: AppUser) => Observable<AppUser>,
  handleSearch?: () => void
) {
  const [, , ,] = routerService.useMasterNavigation(APP_USER_ROUTE);

  const {
    notifyUpdateItemSuccess,
    notifyUpdateItemError,
  } = appMessageService.useCRUDMessage();

  const [subscription] = commonService.useSubscription();

  const [isOpenRoleModal, setIsOpenRoleModal] = React.useState<boolean>(false);
  const [loadingModel, setLoadingModel] = React.useState<boolean>(false);

  const [model, setModel] = React.useState<AppUser>(new AppUser());

  const handleOpenRoleModal = React.useCallback(
    (id: number) => {
      setIsOpenRoleModal(true);
      if (id) {
        setLoadingModel(true);
        subscription.add(
          getDetail(id)
            .pipe(finalize(() => setLoadingModel(false)))
            .subscribe((item: AppUser) => {
              setModel(item);
            })
        );
      }
    },
    [getDetail, subscription]
  ); // handleOpen detailModal from list

  const handleCloseRoleModal = React.useCallback(() => {
    setIsOpenRoleModal(false);
  }, []);

  const handleSaveModel = React.useCallback(() => {
    setLoadingModel(true);
    subscription.add(saveModel);
    saveModel(model)
      .pipe(finalize(() => setLoadingModel(false)))
      .subscribe(
        (item: AppUser) => {
          setModel(item);
          setIsOpenRoleModal(false);
          if (typeof handleSearch === "function") handleSearch(); // updateList if necessary
          notifyUpdateItemSuccess();
        },
        (_error: any) => {
          notifyUpdateItemError();
        }
      );
  }, [
    handleSearch,
    model,
    notifyUpdateItemError,
    notifyUpdateItemSuccess,
    saveModel,
    subscription,
  ]);

  return {
    model,
    isOpenRoleModal,
    loadingModel,
    handleOpenRoleModal,
    handleCloseRoleModal,
    setModel,
    handleSaveModel,
  };
}
