import { Col, ModalProps, Row, Empty, Tooltip } from "antd";
import Modal from "components/Utility/Modal/Modal";
import { Activity } from "models/Activity";
import React from "react";
import { formatDate } from "helpers/date-time";
import {
  getActivityLog,
  ActivityLog,
  parseTemplate,
} from "helpers/activity-log-helper";
import { Observable } from "rxjs";
import { commonService } from "@react3l/react3l/services";
import { finalize } from "rxjs/operators";
import FormItem from "components/Utility/FormItem/FormItem";
import DatePicker from "components/Utility/Calendar/DatePicker/DatePicker";
import { Moment } from "moment";
import { queryStringService } from "services/query-string-service";
import { DateFilter } from "@react3l/advanced-filters";
import moment from "moment";

export function useActivityModal(
  getList: (endUserId: number, createdAt?: DateFilter) => Observable<Activity[]>
) {
  const [visibleActivityModal, setVisibleActivityModal] = React.useState(false);

  const { id } = queryStringService.useGetQueryString();

  const [subscription] = commonService.useSubscription();

  const [list, setList] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const openActivityModal = React.useCallback(() => {
    setVisibleActivityModal(true);
    if (id) {
      setLoading(true);
      subscription.add(
        getList(id)
          .pipe(finalize(() => setLoading(false)))
          .subscribe((item: Activity[]) => {
            setList(item);
          })
      );
    }
  }, [id, getList, subscription]);

  const handleApplyFilter = React.useCallback(
    (newDate: Moment) => {
      setLoading(true);
      let filter = newDate ? {
        greater: moment(newDate).startOf("day"),
        less: moment(newDate).endOf("day"),
      } : null;
      subscription.add(
        getList(id, filter)
          .pipe(finalize(() => setLoading(false)))
          .subscribe((item: Activity[]) => {
            setList(item);
          })
      );
    },
    [getList, id, subscription]
  );

  const cancelActivityModal = React.useCallback(() => {
    setVisibleActivityModal(false);
  }, []);

  return {
    loading,
    list,
    visibleActivityModal,
    openActivityModal,
    cancelActivityModal,
    handleApplyFilter,
  };
}

export function getActivitiesDict(
  list: Activity[],
  dateLimit: number = -1,
  actLimit: number = -1
) {
  let dateCnt: number = 0;
  let actCnt: number = 0;
  if (!list || list.length === 0) return null;
  const res = {};
  list.forEach((item) => {
    if (dateCnt === dateLimit) return;
    const date = formatDate(item.createdAt, "D MMM YY");
    if (!res[date]) {
      res[date] = [];
      dateCnt++;
      actCnt = 0;
    }
    if (actCnt === actLimit) return;
    const { message, params }: ActivityLog = item.action;
    const actTemp = getActivityLog(message, params);
    res[date].push({
      username: actTemp.params.username,
      action: parseTemplate(actTemp.message, actTemp.params),
      time: item.createdAt,
    });
    actCnt++;
  });
  return res;
}

export function ActivityList(props: any) {
  const { list } = props;
  return (
    <>
      <div className="activation__container d-flex flex-column">
        {!list || list.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            <>
              {Object.keys(list).map((date) => (
                <div key={date}>
                  <div className="activation__item d-flex justify-content-between">
                    <div className="item__date mr-2">
                      <div>{date}</div>
                    </div>
                    <div className="item__content d-flex flex-column justify-content-between w-100">
                      {list[date].map((act: any, index: number) => (
                        <div key={index}>
                          <div className="d-flex align-items-center p-2">
                            <Tooltip
                              title={`${act.username}${act.action}`}
                              mouseEnterDelay={0.5}
                            >
                              <span className="content__user-name">
                                {act.username}
                              </span>
                              <span className="content__info mx-1">
                                {act.action}
                              </span>
                              <span className="content__time mx-2">
                                {formatDate(act.time, "h:mm A")}
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
      </div>
    </>
  );
}

interface ActivationModalProps extends ModalProps {
  model?: Activity[];
  loading?: boolean;
  handleFilter?: () => void;
  visibleActivationModal?: boolean;
  cancelActivationModal?: (e: any) => void;
  saveActivationModal?: () => void;
  onFilter?: (date: Moment) => void;
}

export function ActivityModal(props: ActivationModalProps) {
  const { model, visibleActivationModal, cancelActivationModal, onFilter } =
    props;

  const [date, setDate] = React.useState<Moment>(null);

  return (
    <>
      <Modal
        title={null}
        onCancel={cancelActivationModal}
        visible={visibleActivationModal}
        width={840}
        visibleFooter={false}
        closable={false}
      >
        <div className="activation-modal__container d-flex flex-column">
          <div className="activation-modal__header w-100 d-flex justify-content-between align-items-center">
            <span>Hành động</span>
            <div
              className="d-flex justify-content-center align-items-center"
              onClick={cancelActivationModal}
            >
              <i className="tio-clear"></i>
            </div>
          </div>
          <div className="activation-modal__body w-100">
            <div className="body__filter-area">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={8}>
                  <FormItem label={"Ngày"}>
                    <DatePicker
                      isMaterial={true}
                      value={date}
                      placeholder={"Lọc theo ngày"}
                      onChange={(value: any) => {
                        setDate(value);
                        onFilter(value);
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
            </div>
            <div className="body__content-area">
              <ActivityList list={getActivitiesDict(model)} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
