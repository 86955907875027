import { Model } from "@react3l/react3l/core";
import { DormitoryMember } from "models/DormitoryMember";
import { HomeController } from "models/HomeController";
import { EndUser } from "models/EndUser";
import { Room } from "models/Room";

export class Dormitory extends Model {
  public id?: string;
  public name?: string;
  public latitude?: number;
  public longitude?: number;
  public address?: string;
  public endUser?: EndUser;
  public endUserId?: number;
  public members?: DormitoryMember[];
  public homeControllers?: HomeController[];
  public rooms?: Room[];
}
