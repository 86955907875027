import { StringFilter } from "@react3l/advanced-filters";
import { IdFilter } from "@react3l/advanced-filters";
import { ModelFilter } from "@react3l/react3l/core";

export class TicketFilter extends ModelFilter {
  public id?: IdFilter = new IdFilter();
  public code?: StringFilter = new StringFilter();
  public title?: StringFilter = new StringFilter();
  public appUserId?: IdFilter = new IdFilter();
  public endUserId?: IdFilter = new IdFilter();
  public ticketStatusId?: IdFilter = new IdFilter();
  public location?: StringFilter = new StringFilter();
}
