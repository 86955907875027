import { Model } from "@react3l/react3l/core";
import { Moment } from "moment";
import { Status } from "models/Status";
import { AppUserRoleMapping } from "models/AppUserRoleMapping";
import { Sex } from "models/Sex";
import { Activity } from "models/Activity";

export class AppUser extends Model {
  public id?: number;

  public username?: string;

  public password?: string;

  public displayName?: string;

  public position?: string;

  public address?: string;

  public email?: string;

  public phoneNumber?: string;

  public statusId?: number;

  public birthday?: Moment;

  public otpCode?: string;

  public otpExpired?: Moment;

  public avatar?: string;

  public sex?: Sex;

  public latitude?: number;

  public longitude?: number;

  public used?: boolean;

  public status?: Status;

  public appUserRoleMappings?: AppUserRoleMapping[];

  public appUserActivityLogs?: Activity[];
}
