import React from "react";
import { Room } from "models/Room";
import { queryStringService } from "services/query-string-service";
import { Subscription, Observable } from "rxjs";

export default function useRoomDetail(
  getRoomDetail: (id: number) => Observable<Room>
) {
  const [model, setModel] = React.useState<Room>(new Room());

  const { id } = queryStringService.useGetQueryString();

  React.useEffect(() => {
    const subscription: Subscription = new Subscription();
    if (id) {
      subscription.add(
        getRoomDetail(id).subscribe((res: Room) => {
          setModel(res);
        })
      );
    }

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [getRoomDetail, id]);

  return { model, setModel, };
}
