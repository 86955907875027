import { ModalProps, Empty } from "antd";
import Modal from "components/Utility/Modal/Modal";
import { HomeControllerLog } from "models/HomeControllerLog";
import React from "react";
import { formatDate } from "helpers/date-time";
import { Observable } from "rxjs";
import { commonService } from "@react3l/react3l/services";
import { finalize } from "rxjs/operators";
import { HomeController } from "models/HomeController";
import {
  DownloadOutlined
} from '@ant-design/icons';
import './HomeControllerLogModal.scss';

export function useHomeControllerLogModal(
  model: HomeController,
  getList: (hc: HomeController) => Observable<HomeControllerLog[]>
) {
  const [visibleHomeControllerLogModal, setVisibleHomeControllerLogModal] = React.useState(false);

  const [subscription] = commonService.useSubscription();

  const [list, setList] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const openHomeControllerLogModal = React.useCallback(() => {
    setVisibleHomeControllerLogModal(true);
    if (model) {
      setLoading(true);
      subscription.add(
        getList(model)
          .pipe(finalize(() => setLoading(false)))
          .subscribe((item: HomeControllerLog[]) => {
            setList(item);
          })
      );
    }
  }, [model, subscription, getList]);

  const cancelHomeControllerLogModal = React.useCallback(() => {
    setVisibleHomeControllerLogModal(false);
  }, []);

  return {
    loading,
    list,
    visibleHomeControllerLogModal,
    openHomeControllerLogModal,
    cancelHomeControllerLogModal,
  };
}

export function HomeControllerLogList(props: any) {
  const { list } = props;
  return (
    <>
      <div className="home-controller-logs__container d-flex flex-column">
        {!list || list.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            <>
              {list.map((log: HomeControllerLog) => (
                <div key={log.id}>
                  <div className="d-flex align-items-center justify-content-between p-2 home-controller-logs__item">
                    <div className="d-flex">
                      <span className="content__time mx-2">
                        {formatDate(log.createdAt, "DD/MM/YYYY")}
                      </span>
                      <span className="content__user-name">
                        {log.name}
                      </span>
                    </div>
                    <a href={log.url} download={`${log.homeController?.name}_${formatDate(log.createdAt)}`}>
                      <DownloadOutlined />
                    </a>
                  </div>
                </div>
              ))}

            </>
          )}
      </div>
    </>
  );
}

interface HomeControllerLogModalProps extends ModalProps {
  list?: HomeControllerLog[];
  loading?: boolean;
  visibleHomeControllerLogModal?: boolean;
  cancelHomeControllerLogModal?: (e: any) => void;
}

export function HomeControllerLogModal(props: HomeControllerLogModalProps) {
  const { list, visibleHomeControllerLogModal, cancelHomeControllerLogModal } =
    props;

  return (
    <>
      <Modal
        title={null}
        onCancel={cancelHomeControllerLogModal}
        visible={visibleHomeControllerLogModal}
        width={840}
        visibleFooter={false}
        closable={false}
      >
        <div className="home-controller-logs-modal__container d-flex flex-column">
          <div className="home-controller-logs-modal__header w-100 d-flex justify-content-between align-items-center">
            <span>HC Log</span>
            <div
              className="d-flex justify-content-center align-items-center"
              onClick={cancelHomeControllerLogModal}
            >
              <i className="tio-clear"></i>
            </div>
          </div>
          <div className="home-controller-logs-modal__body w-100">
            <div className="body__content-area">
              <HomeControllerLogList list={list} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

