import { Model } from '@react3l/react3l/core';

export class AppUserRole extends Model
{
    public id?: number;

    public code?: string;

    public name?: string;

    public statusId?: number;

}

