import React from "react";
import { commonService } from "@react3l/react3l/services";
import { firmwareRepository } from "repositories/firmware-repository";
import {
  Button,
  RadioChangeEvent,
  Space,
  Input
} from "antd";
import { HomeController } from "models/HomeController";
import classNames from "classnames";
import { ColumnProps } from "antd/lib/table/Column";
import { SearchOutlined } from '@ant-design/icons';
import { ColumnType, FilterConfirmProps } from "antd/lib/table/interface";


function useFirmwareMappingTable() {
    const [hcList, setHcList] = React.useState<HomeController[]>();
    const [hcTypeList, setHcTypeList] = React.useState<HomeController[]>([]);
    const [loadedData, setLoadedData] = React.useState<boolean>(false);
  
    const [subscription] = commonService.useSubscription();
  
    const [selectType, setSelectType] = React.useState<number>(1);
    const [selectedRows, setSelectedRows] = React.useState(null);
    const [hcType, setHcType] = React.useState<any>();
    const [, setSearchText] = React.useState('');
    const [, setSearchedColumn] = React.useState('');
    const searchInput = React.useRef(null);
  
    const rowSelection = {
      selectedRows,
      onChange: (keys: any) => setSelectedRows(keys)
    };
  
    const handleChangeSelectType = React.useCallback(
      (e: RadioChangeEvent) => setSelectType(e.target.value),
      []
    );
    React.useEffect(() => {
      subscription.add(
        firmwareRepository.listHomeController()
          .subscribe(res => {
            res.forEach((d: HomeController, index, list) => {
              list[index].dormitoryName = d?.dormitory?.name;
            });
            setHcList(res);
          })
      );
      return;
    }, [subscription]);
    const handleLoadData = React.useCallback(() => {
    }, []);
  
    const handleReset = (clearFilters: () => void) => {
      clearFilters();
      setSearchText('');
    };
  
    const getColumnSearchProps = React.useCallback(
      (dataIndex: any): ColumnType<HomeController> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Confirm
            </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Reset
            </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: boolean) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex] && record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()),
      }), []
    );
  
    const handleSearch = (
      confirm: (param?: FilterConfirmProps) => void,
      dataIndex: any,
    ) => {
      confirm();
      setSearchedColumn(dataIndex);
    };
  
    const hcColumns: ColumnProps<HomeController>[] = React.useMemo(
      () => [
        {
          title: <div className="text-center gradient-text">HC/MAC</div>,
          key: "name",
          dataIndex: "name",
          width: 200,
          render(name: string) {
            return (
              <div className="ant-cell-master__container">
                <div className={classNames("cell-master__first-row")}>{name}</div>
              </div>
            );
          },
          ...getColumnSearchProps('name'),
        },
        {
          title: <div className="text-center gradient-text">NHÀ</div>,
          key: "dormitoryName",
          dataIndex: "dormitoryName",
          width: 300,
          render(dormitoryName: string) {
            return (
              <div className="ant-cell-master__container">
                <div className={classNames("cell-master__first-row")}>
                  {dormitoryName}
                </div>
              </div>
            );
          },
          ...getColumnSearchProps('dormitoryName'),
        },
        {
          title: <div className="text-center gradient-text">IP CỦA HC</div>,
          key: "ipAddress",
          dataIndex: "ipAddress",
          width: 200,
          render(ipAddress: string) {
            return (
              <div className="ant-cell-master__container">
                <div className={classNames("cell-master__first-row")}>
                  {ipAddress}
                </div>
              </div>
            );
          }
        },
  
        {
          title: <div className="text-center gradient-text">VERSION FW</div>,
          key: "version",
          dataIndex: "version",
          width: 200,
          align: "center",
          render(version: string) {
            return (
              <div className="ant-cell-master__container">
                <div className={classNames("cell-master__first-row")}>
                  {version}
                </div>
              </div>
            );
          }
        }
      ],
      [getColumnSearchProps]
    );
  
    return {
      loadedData,
      hcTypeList,
      setHcTypeList,
      handleLoadData,
      hcList,
      hcType,
      rowSelection,
      setHcType,
      hcColumns,
      selectType,
      handleChangeSelectType
    };
  }

  export default useFirmwareMappingTable;