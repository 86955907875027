/* begin general import */
import React, { useMemo } from "react";
import { Col, Row, Tooltip, Menu as MenuAntd, Dropdown, Card, Button } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { renderMasterIndex } from "helpers/table";
import { useTranslation } from "react-i18next";
import masterService from "services/pages/master-service";
import { getAntOrderType } from "services/table-service";
import nameof from "ts-nameof.macro";
import { DownOutlined } from "@ant-design/icons";
import { CSSTransition } from "react-transition-group";
import InputSearch from "components/Utility/InputSearch/InputSearch";
import Pagination from "components/Utility/Pagination/Pagination";
import classNames from "classnames";
/* end general import */

/* begin filter import */
import AdvanceStringFilter from "components/Utility/AdvanceFilter/AdvanceStringFilter/AdvanceStringFilter";
import { StringFilter } from "@react3l/advanced-filters";
/* end filter import */

/* begin individual import */
import { resourceRepository } from "repositories/resource-repository";
import { Resource, ResourceFilter } from "models/Resource";
import ResourceDetailModal from "../ResourceDetail/ResourceDetailModal";
import detailService from "services/pages/detail-service";
import './ResourceMaster.scss';
import { commonService } from "@react3l/react3l/services";
import { ResourceTranslationLanguage } from "models/ResourceTranslationLanguage";
import { ResourceImportModal, useResourceImport } from './ResourceImport';
/* end individual import */

function ResourceMaster() {
  const [translate] = useTranslation();

  const [subscription] = commonService.useSubscription();

  const [languages, setLanguages] = React.useState<ResourceTranslationLanguage[]>([]);

  React.useEffect(() => {
    subscription.add(resourceRepository.singleListLanguage().subscribe(data => {
      setLanguages(data);
    }));
  }, [subscription]);

  const {
    list,
    total,
    loadingList,
    filter,
    toggle,
    handleChangeFilter,
    handleResetFilter,
    handleToggleSearch,
    handleTableChange,
    handlePagination,
    handleServerDelete,
    handleServerBulkDelete,
    handleSearch,
    rowSelection,
    canBulkDelete,
    pagination
  } = masterService.useMaster<Resource, ResourceFilter>
      (
        ResourceFilter,
        '',
        resourceRepository.list,
        resourceRepository.count,
        resourceRepository.delete,
        resourceRepository.bulkDelete,
      );

  const {
    model,
    isOpenDetailModal,
    handleOpenDetailModal,
    handleCloseDetailModal,
    handleSaveModel,
    loadingModel,
    handleChangeSimpleField,
    handleChangeObjectField,
    dispatch,
  } = detailService.useDetailModal(
    Resource,
    resourceRepository.get,
    resourceRepository.save,
    handleSearch,
  );

  const { isOpenImport, handleOpenImport, handleCloseImport } = useResourceImport();

  const handleGoCreate = React.useCallback(() => {
    handleOpenDetailModal(null);
  }, [handleOpenDetailModal]);

  const handleGoDetail = React.useCallback((id: number) => () => {
    handleOpenDetailModal(id);
  }, [handleOpenDetailModal]);

  const [dropdown, setDropdown] = React.useState<boolean>(false);

  const handleDropdown = React.useCallback(() => {
    setDropdown(!dropdown);
  }, [dropdown]);

  const menuFilter = React.useMemo(() => (
    <MenuAntd>
      <MenuAntd.Item key="2">
        <Tooltip title={translate("resources.action.import")}>
          <>
            <button
              className="btn border-less"
              onClick={() => {
                handleOpenImport();
              }}
            >
              {translate("resources.action.import")}
            </button>
          </>
        </Tooltip>
      </MenuAntd.Item>
    </MenuAntd>
  ), [translate, handleOpenImport]);

  const menuAction = React.useCallback((id: number, resource: Resource) => (
    <MenuAntd>
      <MenuAntd.Item key="2">
        <Tooltip title={translate("general.actions.edit")}>
          <div className="ant-action-menu" onClick={handleGoDetail(id)}>
            {translate("general.actions.edit")}
          </div>
        </Tooltip>
      </MenuAntd.Item>
      <MenuAntd.Item key="3">
        <Tooltip title={translate("general.actions.delete")}>
          <div
            className="ant-action-menu"
            onClick={() => handleServerDelete(resource)}
          >
            {translate("general.actions.delete")}
          </div>
        </Tooltip>
      </MenuAntd.Item>
    </MenuAntd>
  ), [handleGoDetail, handleServerDelete, translate]);

  const languageColumns: ColumnProps<Resource>[] = useMemo(
    () => languages.map(
      (lang: ResourceTranslationLanguage) => {
        return {
          title: (<div className='text-center gradient-text'>{translate(`resources.${lang.code.toLowerCase()}`)}</div>),
          key: `resources.${lang.code.toLowerCase()}`,
          sorter: false,
          width: 300,
          render(resource: Resource) {
            return <div>{resource.resourceTranslationMappings.find(item => item.resourceTranslationLanguageId === lang.id)?.value}</div>;
          },
        };
      }), [languages, translate]);

  const columns: ColumnProps<Resource>[] = useMemo(
    () => [
      {
        title: (<div className='gradient-text'>{translate("general.columns.index")}</div>),
        key: "index",
        width: 100,
        render: renderMasterIndex<Resource>(pagination),
      },
      {
        title: (<div className='text-center gradient-text'>{translate('resources.name')}</div>),
        key: nameof(list[0].name),
        dataIndex: nameof(list[0].name),
        width: 300,
        sorter: true,
        sortOrder: getAntOrderType<Resource, ResourceFilter>
          (
            filter,
            nameof(list[0].name),
          ),
      },
      ...languageColumns,
      {
        title: (<div className='text-center gradient-text'>{translate("general.actions.label")}</div>),
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 100,
        align: "center",
        render(id: number, resource: Resource) {
          return (
            <div className='d-flex justify-content-center button-action-table'>
              <Dropdown overlay={menuAction(id, resource)} trigger={["click"]} placement="bottomCenter" arrow>
                <span className="action__dots">...</span>
              </Dropdown>
            </div>
          );
        },
      },
    ], [translate, pagination, list, filter, menuAction, languageColumns]);

  return (
    <>
      <div className='page page__master resource__container'>
        <div className="page__header d-flex align-items-center justify-content-between">
          <div className="page__title">
            {translate('resources.master.title')}
          </div>
        </div>
        <div className="page__search">
          <Card bordered={false}>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-grow-1">
                <div className="pr-4 w70">
                  <InputSearch />
                </div>

                <button
                  className={classNames(
                    "btn component__btn-toggle mr-3 grow-animate-1",
                    toggle === true ? "component__btn-toggle-active" : ""
                  )}
                  onClick={handleToggleSearch}
                >
                  <i className="tio-tune_horizontal"></i>
                  <span className="component_btn-text">
                    {translate("general.button.advance")}
                  </span>
                </button>

                <button
                  className="btn component__btn-toggle grow-animate-1 reset-filter"
                  onClick={handleResetFilter}
                >
                  <i className="tio-restore reset-icon"></i>
                  <span className="component_btn-text reset-label">
                    {translate("general.button.reset")}
                  </span>
                </button>
              </div>

              <div className="d-flex justify-content-around ml-4">
                <button
                  className="btn component__btn-toggle grow-animate-1"
                  onClick={handleGoCreate}
                >
                  <i className="tio-add"></i>
                  <span className="component_btn-text">
                    {translate("general.actions.create")}
                  </span>
                </button>
                <div className="table__action">
                  <Dropdown overlay={menuFilter} trigger={["click"]}>
                    <Button onClick={handleDropdown}>
                      <span className="component_btn-text">
                        {translate("general.actions.action")}
                      </span>
                      <DownOutlined className={dropdown ? "dropdown" : null} />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </div>
            <CSSTransition
              in={toggle}
              timeout={100}
              classNames={"show"}
              unmountOnExit >
              <Row className='mt-4'>
                <Col lg={4} className='pr-4'>
                  <label className='label'>
                    {translate('resources.name')}
                  </label>
                  <AdvanceStringFilter
                    isMaterial={true}
                    value={filter[nameof(list[0].name)]["contain"]}
                    onEnter={handleChangeFilter(
                      nameof(list[0].name),
                      'contain' as any,
                      StringFilter,
                    )}
                    placeHolder={translate('resources.placeholder.name')} />
                </Col>
              </Row>
            </CSSTransition>
          </Card>
        </div>
        <div className="page__master-table custom-scrollbar">
          <Card bordered={false}>
            <Table
              rowKey={nameof(list[0].id)}
              columns={columns}
              pagination={false}
              dataSource={list}
              loading={loadingList}
              onChange={handleTableChange}
              rowSelection={rowSelection}
              scroll={{ y: 400, x: "max-content" }}
              title={() => (
                <>
                  <div className="d-flex justify-content-end">
                    <div className="flex-shrink-1 d-flex align-items-center">
                      <Tooltip
                        title={translate("general.button.bulkDelete")}
                        key="bulkDelete"
                      >
                        <button
                          className="btn border-less component__btn-delete grow-animate-2"
                          style={{ border: "none", backgroundColor: "unset" }}
                          onClick={handleServerBulkDelete}
                          disabled={!canBulkDelete}
                        >
                          <i className="tio-delete" />
                        </button>
                      </Tooltip>

                      <Pagination
                        skip={filter.skip}
                        take={filter.take}
                        total={total}
                        onChange={handlePagination}
                        style={{ margin: "10px" }}
                      />
                    </div>
                  </div>
                </>
              )}
            />
          </Card>
        </div>
      </div>
      <ResourceDetailModal
        model={model}
        languages={languages}
        visible={isOpenDetailModal}
        handleSave={handleSaveModel}
        handleCancel={handleCloseDetailModal}
        onChangeSimpleField={handleChangeSimpleField}
        onChangeObjectField={handleChangeObjectField}

        dispatchModel={dispatch}
        loading={loadingModel}
        visibleFooter={true}
      />
      <ResourceImportModal
        isOpenImport={isOpenImport}
        handleCloseImport={handleCloseImport}
        reloadList={handleSearch}
        translate={translate}
      />
    </>
  );
}

export default ResourceMaster;
