import React from "react";
import { commonService } from "@react3l/react3l/services";
import { firmwareRepository } from "repositories/firmware-repository";
import { Firmware } from "models/Firmware";
import Modal from "components/Utility/Modal/Modal";
import {
  Descriptions,
  Button,
  DatePicker,
  Table,
  Radio,
  Menu,
  Dropdown,
  notification
} from "antd";
import { useTranslation } from "react-i18next";
import { Moment } from "moment";
import './FirmwareUpdate.scss';
import appMessageService, { messageType } from "services/app-message-service";
import moment from "moment";
import useFirmwareMappingTable from "services/firmware-services/useFirmwareMappingTable";

interface FirmwareUpdateProps {
  isOpenPopup?: boolean;
  handleClosePopup?: () => void;
  data?: Firmware[];
}

export function useFirmwareUpdate() {
  const [showUpdatePopup, setShowUpdatePopup] = React.useState<boolean>(false);

  const handleOpenUpdatePopup = React.useCallback(
    () => setShowUpdatePopup(true),
    []
  );

  const handleCloseUpdatePopup = React.useCallback(
    () => setShowUpdatePopup(false),
    []
  );

  return {
    showUpdatePopup,
    handleOpenUpdatePopup,
    handleCloseUpdatePopup
  };
}

export default function FirmwareUpdate(props: FirmwareUpdateProps) {
  const { isOpenPopup, handleClosePopup ,data} = props;

  const [infoModel, setInfoModel] = React.useState<Firmware>(null);
  const [publishAt, setPublishAt] = React.useState<Moment>(moment(new Date()));

  const {
    loadedData,
    handleLoadData,
    hcList,
    hcTypeList,
    setHcTypeList,
    rowSelection,
    setHcType,
    hcColumns,
    hcType,
    handleChangeSelectType,
    selectType
  } = useFirmwareMappingTable();

  const [translate] = useTranslation();
  const [subscription] = commonService.useSubscription();
  const handleCloseModal = React.useCallback(() => {
    setHcType(null);
    setHcTypeList(null);
    setInfoModel(null);
    setPublishAt(moment(new Date()));
    handleClosePopup();
  }, [handleClosePopup, setHcType, setHcTypeList]);
  const handleUpdate = React.useCallback(() => {
    if (!infoModel || (selectType == 2 && !rowSelection.selectedRows)){
      appMessageService.messageFactory({
        type: messageType.WARNING,
        title: 'Thông tin yêu cầu không hợp lệ!'
      });
      return;
    }
    subscription.add(
      firmwareRepository
        .publish(publishAt, rowSelection.selectedRows?? hcTypeList, infoModel?.version, hcType)
        .subscribe(
          res => {
            notification.success({
              message: "Yêu cầu thành công",
              description: `Đã yêu cầu cập nhật!`,
              placement: "bottomRight"
            });
            handleCloseModal();
          },
          () =>
            notification.error({
              message: "Yêu cầu thất bại",
              placement: "bottomRight"
            })
        )
    );
  }, [infoModel, selectType, rowSelection.selectedRows, subscription, publishAt, hcTypeList, hcType, handleCloseModal]);

  const handleMenuClick = ({ key }) => {
    const item: Firmware = data?.find(x => x.id == key);
    setInfoModel(item);
  };
  const menuVersion = (
    <Menu onClick={handleMenuClick}>
      {
        data?.filter(x=>x.homeControllerTypeId == hcType).map((item) =>(
          <Menu.Item key={item?.id}>{item?.version}</Menu.Item>
        ))
      }
    </Menu>
  );
  const handleSelectType = React.useCallback(({key}) => {
    setHcType(key);
    setInfoModel(null);
    setHcTypeList(hcList.filter(x => x?.homeControllerTypeId == key));
  }, [hcList, setHcType, setHcTypeList]);

  const homeControllerTypeIds = (
    <Menu onClick={handleSelectType}>
      {
        Array.from({length: 3}).map((_,index) => (
          <Menu.Item key={index+1}>{`HC 0${index+1}`}</Menu.Item>
        ))
      }
    </Menu>
  );

  return (
    <>
      <Modal
        title={null}
        visible={isOpenPopup}
        width={1200}
        bodyStyle={{ height: 800 }}
        visibleFooter={false}
        handleCancel={handleClosePopup}
      >
        <div className="preview__containter">
          <div className="preview__left-side">
            <div className="preview__header">
              <div className="preview__vertical-bar"></div>
              <div className="preview__header-info">
                <div className="preview__header-text">
                  <span className="preview__header-title">
                    {translate("firmwares.actions.update")}
                  </span>
                </div>
              </div>
            </div>
            <div className="preview__body">
              <div className="preview__content">
                <Descriptions
                  title={"Xác nhận yêu cầu cập nhật firmware!"}
                  column={4}
                >
                </Descriptions>
                <Descriptions layout="horizontal">
                <Descriptions.Item label={"Đặt lịch yêu cầu"}>
                    <DatePicker
                      showNow={false}
                      value={publishAt}
                      disabledDate={(current: Moment) =>
                        current && current < moment().startOf("day")
                      }
                      showTime={{
                        format: "HH:mm",
                        hideDisabledOptions: true,
                        defaultValue: moment("00:00", "HH:mm")
                      }}
                      format="YYYY-MM-DD HH:mm"
                      onChange={val => setPublishAt(val)}
                      />
                  </Descriptions.Item>
                  <Descriptions.Item>
                  <Dropdown overlay={homeControllerTypeIds}>
                        <Button>
                          {!hcType? 'Lựa chọn loại HomeController': `HC 0${hcType}`}
                        </Button>
                      </Dropdown>
                  </Descriptions.Item>
                  <Descriptions.Item>
                {hcType && (
                  <Dropdown overlay={menuVersion}>
                      <Button>
                        {infoModel?.version?? 'Vui lòng chọn Version'}
                      </Button>
                    </Dropdown>
                  )}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>

                </Descriptions>
                <Descriptions layout="vertical">
                  <Descriptions.Item label={"Gửi yêu cầu cho"} span={2}>
                    <Radio.Group
                      onChange={handleChangeSelectType}
                      value={selectType}
                    >
                      <Radio value={1}>Tất cả HC</Radio>
                      <Radio value={2}>Chọn HC thủ công</Radio>
                    </Radio.Group>
                  </Descriptions.Item>
                </Descriptions>
                {selectType === 2 && (
                  <div className="d-flex mb-3">
                    <Table
                      columns={hcColumns}
                      dataSource={hcTypeList}
                      rowKey={"id"}
                      rowSelection={rowSelection}
                    />
                  </div>
                )}
                <div className="d-flex">
                  <Button type="primary" onClick={handleUpdate}>
                    Xác nhận
                  </Button>
                  <Button danger className="ml-2" onClick={handleCloseModal}>
                    Hủy
                  </Button>
                </div>
              </div>
            </div>
            <div className="preview__footer"></div>
          </div>
        </div>
      </Modal>
    </>
  );
}
