import { IdFilter, StringFilter } from "@react3l/advanced-filters";
import { ModelFilter } from "@react3l/react3l/core";

export class HomeControllerFilter extends ModelFilter {
  public name?: StringFilter = new StringFilter();
  public ipAddress?: StringFilter = new StringFilter();
  public macAddress?: StringFilter = new StringFilter();
  public version?: StringFilter = new StringFilter();
  public email?: StringFilter = new StringFilter();
  public homeControllerStatusId?: IdFilter = new IdFilter();
  public homeControllerActiveStatusId?: IdFilter = new IdFilter();
}
