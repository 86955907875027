import { HereMapService } from "./HereMapService";
import React from "react";

export interface HereMapProps {
  lat: number; // latitude
  lng: number; // longitude
}

const containerStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
  position: "relative",
};

export default function HereMap(props: HereMapProps) {
  const { lat, lng } = props;

  const mapContainer = React.useRef<HTMLDivElement>(null);
  const mapService = React.useRef<HereMapService>(null);

  React.useEffect(() => {
    if (mapContainer.current) {
      mapService.current = new HereMapService(mapContainer.current);
    }
  }, []);

  React.useEffect(() => {
    if (lat && lng) {
      mapService.current.map.setCenter({ lat, lng });
      mapService.current.marker.setGeometry({ lat, lng });
    }
  }, [lat, lng]);

  return (
    <div>
      <div style={{ display: "block", width: "100%", height: "300px" }}>
        <div ref={mapContainer} style={containerStyle}></div>
      </div>
    </div>
  );
}
