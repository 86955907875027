/* begin general import */
import React, { useMemo } from "react";
import { Col, Row, Menu, Dropdown, Card } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import masterService from "services/pages/master-service";
import { getAntOrderType } from "services/table-service";
import nameof from "ts-nameof.macro";
import { CSSTransition } from "react-transition-group";
import InputSearch from "components/Utility/InputSearch/InputSearch";
import Pagination from "components/Utility/Pagination/Pagination";
import classNames from "classnames";
/* end general import */

/* begin filter import */
import AdvanceStringFilter from "components/Utility/AdvanceFilter/AdvanceStringFilter/AdvanceStringFilter";
import { StringFilter } from "@react3l/advanced-filters";
import AdvanceIdFilter from "components/Utility/AdvanceFilter/AdvanceIdFilter/AdvanceIdFilter";
import { IdFilter } from "@react3l/advanced-filters";
/* end filter import */

/* begin individual import */
import { appUserRepository } from "repositories/app-user-repository";
import { AppUser, AppUserFilter } from "models/AppUser";
import { AppUserRoleMapping } from "models/AppUserRoleMapping";
import { Status, StatusFilter } from "models/Status";
import { APP_USER_ROUTE } from "config/route-consts";
import AppUserMasterRoleModal from "./AppUserMasterRoleModal/AppUserMasterRoleModal";
import { useAppUserMasterRoleModal } from "./AppUserMasterRoleModal/AppUserMasterRoleModalHook";
import AppUserCreateModal from "../AppUserDetail/AppUserCreateModal/AppUserCreateModal";
import { useAppUserCreateModal } from "../AppUserDetail/AppUserCreateModal/AppUserCreateModalHook";
import "./AppUserMaster.scss";
import authenticationService from "services/authentication-service";
import { API_APP_USER_PREFIX } from "config/api-consts";
/* end individual import */

function AppUserMaster() {
  const [translate] = useTranslation();
  const { validAction } = authenticationService.useAction(
    "appUser",
    API_APP_USER_PREFIX
  );

  const {
    list,
    total,
    loadingList,
    filter,
    toggle,
    handleChangeFilter,
    handleUpdateNewFilter,
    handleGoDetail,
    handleToggleSearch,
    handleTableChange,
    handlePagination,
    handleServerDelete,
    handleSearch,
    rowSelection,
  } = masterService.useMaster<AppUser, AppUserFilter>(
    AppUserFilter,
    APP_USER_ROUTE,
    appUserRepository.list,
    appUserRepository.count,
    appUserRepository.delete,
    appUserRepository.bulkDelete
  );

  const {
    model,
    setModel,
    isOpenRoleModal,
    handleOpenRoleModal,
    handleCloseRoleModal,
    handleSaveModel,
    loadingModel,
  } = useAppUserMasterRoleModal(
    appUserRepository.get,
    appUserRepository.save,
    handleSearch
  );

  const {
    model: createModel,
    isOpenCreateModal,
    handleOpenCreateModal,
    handleCloseCreateModal,
    handleSaveCreateModel,
    loadingCreateModel,
    handleChangeSimpleField,
    handleChangeObjectField,
  } = useAppUserCreateModal(appUserRepository.save, handleSearch);

  const _handleChangeFilter = React.useCallback(
    (value) => {
      const newFilter = { ...filter };
      newFilter["search"] = value;
      handleUpdateNewFilter(newFilter);
    },
    [filter, handleUpdateNewFilter]
  );

  const menuAction = React.useCallback(
    (id: number, appUser: AppUser) => (
      <Menu>
        {validAction("update") ? (
          <>
            <Menu.Item key="1">
              <div className="ant-action-menu" onClick={handleGoDetail(id)}>
                {translate("general.actions.edit")}
              </div>
            </Menu.Item>
            <Menu.Item key="2">
              <div
                className="ant-action-menu"
                onClick={() => handleServerDelete(appUser)}
              >
                {translate("general.actions.delete")}
              </div>
            </Menu.Item>
          </>
        ) : (
            <Menu.Item key="1">
              <div className="ant-action-menu" onClick={handleGoDetail(id)}>
                {translate("general.actions.view")}
              </div>
            </Menu.Item>
          )}
      </Menu>
    ),
    [handleGoDetail, handleServerDelete, translate, validAction]
  );

  const columns: ColumnProps<AppUser>[] = useMemo(
    () => [
      {
        title: (
          <div className="text-center gradient-text">
            {translate("appUsers.username")}
          </div>
        ),
        key: nameof(list[0].username),
        dataIndex: nameof(list[0].username),
        sorter: true,
        sortOrder: getAntOrderType<AppUser, AppUserFilter>(
          filter,
          nameof(list[0].username)
        ),
        // width: 70,
        render(name: string, user: AppUser) {
          return (
            <div className="ant-cell-master__container">
              <div className="cell-master__first-row">{name}</div>
              <div className="cell-master__second-row app-user__email">{user.email}</div>
            </div>
          );
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("appUsers.role")}
          </div>
        ),
        key: nameof(list[0].appUserRoleMappings),
        dataIndex: nameof(list[0].appUserRoleMappings),
        sorter: true,
        sortOrder: getAntOrderType<AppUser, AppUserFilter>(
          filter,
          nameof(list[0].appUserRoleMappings)
        ),
        // width: 70,
        render(roles: AppUserRoleMapping[], appUser: AppUser) {
          return (
            <div className="ant-cell-master__container">
              <div className="app-user__role">
                {roles.map((role) => (
                  <span
                    key={role.role.id}
                    className={classNames(
                      `app-user--${role.role.code.toLowerCase()}`
                    )}
                  >
                    {role.role.name}
                  </span>
                ))}
                {validAction("update") && (
                  <span
                    key={0}
                    className="app-user--add"
                    onClick={() => handleOpenRoleModal(appUser.id)}
                  >
                    <i className="tio-add"></i>
                  </span>
                )}
              </div>
            </div>
          );
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("appUsers.status")}
          </div>
        ),
        key: nameof(list[0].status),
        dataIndex: nameof(list[0].status),
        sorter: true,
        sortOrder: getAntOrderType<AppUser, AppUserFilter>(
          filter,
          nameof(list[0].status)
        ),
        width: 200,
        render(status: Status) {
          return (
            <div
              className={`app-user__status ${
                status.id === 1 ? "active" : "inactive"
                }`}
            >
              {status?.name}
            </div>
          );
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("general.actions.action")}
          </div>
        ),
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 150,
        align: "center",
        render(id: number, appUser: AppUser) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, appUser)}
                trigger={["click"]}
                placement="bottomCenter"
                arrow
              >
                <span className="action__dots">...</span>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [translate, list, filter, handleOpenRoleModal, menuAction, validAction]
  );

  const filterChildren = useMemo(
    () => (
      <Row className="mt-4">
        <Col lg={4} className="pr-4">
          <label className="label">{translate("appUsers.username")}</label>
          <AdvanceStringFilter
            isMaterial={true}
            value={filter[nameof(list[0].username)]["contain"]}
            onEnter={handleChangeFilter(
              nameof(list[0].username),
              "contain" as any,
              StringFilter
            )}
            placeHolder={translate("appUsers.placeholder.username")}
          />
        </Col>

        <Col lg={4} className="pr-4">
          <label className="label">{translate("appUsers.email")}</label>
          <AdvanceStringFilter
            isMaterial={true}
            value={filter[nameof(list[0].email)]["contain"]}
            onEnter={handleChangeFilter(
              nameof(list[0].email),
              "contain" as any,
              StringFilter
            )}
            placeHolder={translate("appUsers.placeholder.email")}
          />
        </Col>

        <Col lg={4} className="pr-4">
          <label className="label">{translate("appUsers.status")}</label>
          <AdvanceIdFilter
            isMaterial={true}
            value={filter[nameof(list[0].statusId)]["equal"]}
            onChange={handleChangeFilter(
              nameof(list[0].statusId),
              "equal" as any,
              IdFilter
            )}
            classFilter={StatusFilter}
            getList={appUserRepository.singleListStatus}
            placeHolder={translate("appUsers.placeholder.status")}
          />
        </Col>
      </Row>
    ),
    [filter, handleChangeFilter, list, translate]
  );

  return (
    <>
      <div className="page page__master app-user__container">
        <div className="page__header d-flex align-items-center justify-content-between">
          <div className="page__title">
            {translate("appUsers.master.title")}
          </div>
        </div>
        <div className="page__search">
          <Card bordered={false}>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-grow-1">
                <div className="pr-4 w70">
                  <InputSearch
                    value={filter["search"]}
                    onChange={_handleChangeFilter}
                    placeHolder="Tìm kiếm"
                  />
                </div>

                <button
                  className={classNames(
                    "btn component__btn-toggle mr-3 grow-animate-1",
                    toggle === true ? "component__btn-toggle-active" : ""
                  )}
                  onClick={handleToggleSearch}
                >
                  <i className="tio-tune_horizontal"></i>
                  <span className="component_btn-text">
                    {translate("general.button.advance")}
                  </span>
                </button>
              </div>

              {validAction("create") && (
                <div className="d-flex justify-content-around ml-4">
                  <button
                    className="btn component__btn-toggle grow-animate-1"
                    onClick={handleOpenCreateModal}
                  >
                    <i className="tio-add"></i>
                    <span className="component_btn-text">
                      {translate("general.actions.create")}
                    </span>
                  </button>
                </div>
              )}
            </div>
            <CSSTransition
              in={toggle}
              timeout={100}
              classNames={"show"}
              unmountOnExit
            >
              {filterChildren}
            </CSSTransition>
          </Card>
        </div>
        <div className="page__master-table custom-scrollbar pb-4">
          <Card bordered={false}>
            <Table
              rowKey={nameof(list[0].id)}
              columns={columns}
              pagination={false}
              dataSource={list}
              loading={loadingList}
              onChange={handleTableChange}
              rowSelection={rowSelection}
              scroll={{ x: "max-content" }}
              title={() => (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="action__result">{total} Kết quả</span>
                    <div className="flex-shrink-1 d-flex align-items-center">
                      <Pagination
                        skip={filter.skip}
                        take={filter.take}
                        total={total}
                        onChange={handlePagination}
                        style={{ margin: "10px" }}
                      />
                    </div>
                  </div>
                </>
              )}
            />
          </Card>
        </div>
      </div>
      <AppUserMasterRoleModal
        model={model}
        setModel={setModel}
        visible={isOpenRoleModal}
        getRoles={appUserRepository.singleListRole}
        handleSave={handleSaveModel}
        handleCancel={handleCloseRoleModal}
        loading={loadingModel}
        visibleFooter={false}
      />
      <AppUserCreateModal
        model={createModel}
        visible={isOpenCreateModal}
        getRoles={appUserRepository.singleListRole}
        handleSave={handleSaveCreateModel}
        handleCancel={handleCloseCreateModal}
        onChangeSimpleField={handleChangeSimpleField}
        onChangeObjectField={handleChangeObjectField}
        loading={loadingCreateModel}
        visibleFooter={false}
      />
    </>
  );
}

export default AppUserMaster;
