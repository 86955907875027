import React, { PropsWithChildren } from "react";
import "./NotificationForm.scss";
import Modal from "components/Utility/Modal/Modal";
import FormItem from "components/Utility/FormItem/FormItem";
import InputText from "components/Utility/Input/InputText/InputText";
const NotificationForm = (props: PropsWithChildren<NotificationFormProps>) => {
  const {
    isOpen,
    Title,
    Content,
    Confirm,
    Cancel,
    onChangeTitle,
    onChangeContent,
  } = props;
  const handleConfirm = React.useCallback(() => {
    Confirm();
  }, [Confirm]);
  const handleCancel = React.useCallback(() => {
    Cancel();
  }, [Cancel]);
  return (
    <>
      <Modal
        title={null}
        onCancel={handleCancel}
        visible={isOpen}
        width={585}
        visibleFooter={false}
        closable={false}
      >
        <div className="forgot-password-modal__contaier d-flex flex-column">
          <div className="forgot-password-modal__header">Thông báo</div>
          <div className="forgot-password-content w-100 mt-4">
            <FormItem label={"Tiêu đề"}>
              <InputText
                isMaterial={true}
                value={Title}
                placeHolder={"Nhập tiêu đề"}
                onChange={onChangeTitle}
              />
            </FormItem>
          </div>
          <div className="forgot-password-content w-100 mt-2">
            <FormItem label={"Nội dung"}>
              <InputText
                isMaterial={true}
                value={Content}
                placeHolder={"Nhập nội dung"}
                onChange={onChangeContent}
              />
            </FormItem>
          </div>
          <div className="forgot-password-footer d-flex mt-3">
            <button className="btn-save mr-2" onClick={handleConfirm}>
              <span>
                <i className="tio-send_outlined"></i> Gửi
              </span>
            </button>
            <button className="btn-cancel" onClick={handleCancel}>
              <span>
                <i className="tio-clear"></i> Đóng
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

interface NotificationFormProps {
  isOpen: boolean;
  Title?: string;
  Content?: string;
  Confirm: () => void;
  Cancel: () => void;
  onChangeTitle?: (value: string) => void;
  onChangeContent?: (value: string) => void;
}

export default NotificationForm;
