import React from 'react';
import { Switch } from 'react-router-dom';
import { CERTIFICATION_MASTER_ROUTE, } from 'config/route-consts';
import { ProtectedRoute, useCheckAuthorized } from 'config/protected-route';

import './CertificationView.scss';
import CertificationMaster from './CertificationMaster/CertificationMaster';

function CertificationView() {
  const { auth } = useCheckAuthorized();
  return (
    <Switch>
      <ProtectedRoute path={CERTIFICATION_MASTER_ROUTE} key={CERTIFICATION_MASTER_ROUTE} component={CertificationMaster} auth={auth(CERTIFICATION_MASTER_ROUTE)} />
    </Switch>
  );
}

export { CertificationMaster, };
export default CertificationView;
