import { useTranslation } from "react-i18next";
import React from "react";
import { ColumnProps } from "antd/lib/table";
import classNames from "classnames";
import { EndUser } from "models/EndUser";
import { HomeController } from "models/HomeController";
import { Dormitory } from "models/Dormitory";
import { EndUserRole } from "models/EndUserRole";
import { useHistory } from "react-router";
import {
  HOME_CONTROLLER_DETAIL_ROUTE,
  TICKET_DETAIL_ROUTE,
  DORMITORY_DETAIL_ROUTE,
} from "config/route-consts";
import { endUserRepository } from "repositories/end-user-repository";
import { Status } from "models/Status";
import { Ticket } from "models/Ticket";
import { formatDateTime } from "helpers/date-time";
import "./EndUserDetail.scss";
import { Observable } from "rxjs";
import { useSubscription } from "@react3l/react3l/services/common-service/use-subscription";

export function useEndUserDetailHook(
  model: EndUser,
  updateModel: (data: EndUser) => void,
  saveModel: (eu: EndUser) => Observable<EndUser>,
) {
  const [translate] = useTranslation();
  const endUserId = React.useMemo(() => {
    return model.id ? model.id : null;
  }, [model.id]);

  const history = useHistory();
  const [subscription] = useSubscription();

  const [isEditable, setEditable] = React.useState(false);

  const handleEdit = React.useCallback(() => {
    setEditable(true);
  }, []);

  const handleFinish = React.useCallback(() => {
    setEditable(false);
  }, []);

  const handleSaveModel = React.useCallback(() => {
    subscription.add(saveModel);
    saveModel(model)
      .subscribe(
        (item: EndUser) => {
          updateModel(item);
        },
      );
  }, [saveModel, model, subscription, updateModel]);


  const handleGoDormitory = React.useCallback(
    (id: string | number) => (_event: any) => {
      if (id) {
        history.push(`${DORMITORY_DETAIL_ROUTE}?id=${id}`);
      }
    },
    [history]
  );

  const handleGoHomeController = React.useCallback(
    (id: string | number) => (_event: any) => {
      if (id) {
        history.push(`${HOME_CONTROLLER_DETAIL_ROUTE}?id=${id}`);
      }
    },
    [history]
  );

  const handleDeactiveAccount = React.useCallback(() => {
    endUserRepository.lock([endUserId]).subscribe(
      (res) => {
        if (res) {
          updateModel(res[0]);
        }
      },
      (_err) => { }
    );
  }, [endUserId, updateModel]);

  const handleActiveAccount = React.useCallback(() => {
    endUserRepository.unlock([endUserId]).subscribe(
      (res) => {
        updateModel(res[0]);
      },
      (_err) => { }
    );
  }, [endUserId, updateModel]);

  const homeControllerColumns: ColumnProps<
    HomeController
  >[] = React.useMemo(() => {
    return [
      {
        title: <div className="gradient-text text-upper">HC/MAC</div>,
        key: "id",
        dataIndex: "id",
        width: 180,
        render(id: string, hc: HomeController) {
          return (
            <div className="ant-cell-master__container">
              <div
                className={classNames(
                  "cell-master__first-row",
                  "first-row--link",
                  {
                    "first-row--ellipsis": true,
                  },
                  "text-link"
                )}
                onClick={handleGoHomeController(id)}
              >
                {hc.name}
              </div>
              <div className="cell-master__second-row">{hc.macAddress}</div>
            </div>
          );
        },
      },
      {
        title: <div className="gradient-text text-upper">IP của HC</div>,
        key: "ipAddress",
        dataIndex: "ipAddress",
        width: 180,
        render(ipAddress: string) {
          return (
            <div className="ant-cell-master__container">
              <div
                className={classNames("cell-master__first-row", {
                  "first-row--ellipsis": true,
                })}
              >
                {ipAddress}
              </div>
            </div>
          );
        },
      },
      {
        title: <div className="gradient-text text-upper">Nhà</div>,
        key: "dormitory",
        dataIndex: "dormitory",
        width: 180,
        render(dormitory: Dormitory) {
          return (
            <div className="ant-cell-master__container">
              <div className="cell-master__first-row">{dormitory?.name}</div>
            </div>
          );
        },
      },
      {
        title: <div className="gradient-text text-upper">Trạng thái</div>,
        key: "homeControllerActiveStatus",
        dataIndex: "homeControllerActiveStatus",
        width: 200,
        render(hcStat: Status) {
          return (
            <>
              {hcStat && (
                <div
                  className={`home-controller__status home-controller__status--${hcStat.id} mt-1`}
                >
                  {hcStat?.name}
                </div>
              )}
            </>
          );
        },
      },
    ];
  }, [handleGoHomeController]);

  const dormitoryColumns: ColumnProps<Dormitory>[] = React.useMemo(() => {
    return [
      {
        title: <div className="gradient-text text-upper">Tên nhà</div>,
        key: "name",
        dataIndex: "name",
        width: 150,
        render(name: string, dorm: Dormitory) {
          return (
            <div className="ant-cell-master__container">
              <div
                className={classNames(
                  "cell-master__first-row",
                  "first-row--link",
                  {
                    "first-row--ellipsis": true,
                  }
                )}
              >
                <span
                  onClick={handleGoDormitory(dorm.id)}
                  className="text-link"
                >
                  {name}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        title: <div className="gradient-text text-upper">Địa chỉ</div>,
        key: "address",
        dataIndex: "address",
        width: 250,
        render(address: string) {
          return (
            <div className="ant-cell-master__container">
              <div
                className={classNames("cell-master__first-row", {
                  "first-row--ellipsis": true,
                })}
              >
                {address}
              </div>
            </div>
          );
        },
      },
      {
        title: <div className="gradient-text text-upper">Vai trò</div>,
        key: "endUserRole",
        dataIndex: "endUserRole",
        width: 130,
        render(endUserRole: EndUserRole) {
          return (
            <div className="ant-cell-master__container">
              <div className="cell-master__first-row">{endUserRole?.name}</div>
            </div>
          );
        },
      },
      {
        title: <div className="gradient-text text-upper">Thành viên nhà</div>,
        key: "members",
        dataIndex: "members",
        width: 130,
        render(members: EndUser[]) {
          return (
            <div className="list-circle__container d-flex">
              {members &&
                members.length > 0 &&
                members.slice(0, 4).map((_, index) => {
                  return (
                    <div
                      className="circle-item"
                      style={{ left: `${index * 27}px` }}
                      key={index}
                    >
                      <img
                        src="https://media.discordapp.net/attachments/663753852184428596/847406738138595348/7ab2cd69-80fe-4106-ba8d-218d78b131d4.png"
                        alt={"img"}
                      />
                    </div>
                  );
                })}
              {members && members.length > 4 && (
                <div
                  className="circle-item d-flex justify-content-center align-items-center"
                  style={{ left: `${4 * 27}px` }}
                >
                  <span>+{members.length - 4}</span>
                </div>
              )}
            </div>
          );
        },
      },
    ];
  }, [handleGoDormitory]);

  return {
    translate,
    isEditable,
    handleEdit,
    handleFinish,
    homeControllerColumns,
    dormitoryColumns,
    handleDeactiveAccount,
    handleActiveAccount,
    handleSaveModel,
  };
}

interface OpenTicketListProps {
  tickets: Ticket[];
}

export function OpenTicketList(props: OpenTicketListProps) {
  const { tickets } = props;
  const history = useHistory();

  const handleGoTicket = React.useCallback(
    (id: string | number) => () => {
      if (id) {
        history.push(`${TICKET_DETAIL_ROUTE}?id=${id}`);
      }
    },
    [history]
  );

  return (
    <>
      {tickets.map((item) => (
        <div className="d-flex flex-column w-100 p-3 open-ticket" key={item.id}>
          <h2>{item.title}</h2>
          <div>
            <i className="tio-calendar_month mr-2"></i>
            {formatDateTime(item.createdAt)}
          </div>
          <div className="align-self-end">
            <span onClick={handleGoTicket(item.id)}>Chi tiết</span>
          </div>
        </div>
      ))}
    </>
  );
}
