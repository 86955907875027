import React from "react";
import { Switch } from "react-router-dom";
import { FIRMWARE_MASTER_ROUTE } from "config/route-consts";
import { ProtectedRoute, useCheckAuthorized } from "config/protected-route";
import "./FirmwareView.scss";
import FirmwareMaster from "./FirmwareMaster/FirmwareMaster";
function FirmwareView() {
  const { auth } = useCheckAuthorized();
  return (
    <Switch>
      <ProtectedRoute
        path={FIRMWARE_MASTER_ROUTE}
        key={FIRMWARE_MASTER_ROUTE}
        component={FirmwareMaster}
        auth={auth(FIRMWARE_MASTER_ROUTE)}
      />
    </Switch>
  );
}
export { FirmwareMaster };
export default FirmwareView;
